import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { Preloader, Sidebar, Navbar } from "../common";
import Swal from "sweetalert2";

import {
  loginZabbix,
  fetchHostGroups,
  fetchHostsForHostGroup,
  fetchGraph,
  fetchItems,
  fetchHistory,
} from "../../actions/zabbixActions";
import Graph from "./Graph";
import Filter from "./Filter";
import Table from "./Table";
// import Navbar from "../layout/Navbar";
import { instanceOf } from "prop-types";

class Zabbix extends Component {
  state = {
    server: "",
    preloaderStyle: "d-none",
    hostGroup: [0],
    host: [0],
    graph: [0],
    token: "",
    base_url: "",
    hostGroups: [],
    showGraph: false,
    showExport: false,
    start_date: "",
    end_date: "",
    start_time: "",
    end_time: "",
    servers: [
      {
        label: "Core 196.250.208.235",
        value: `https://core-zabbix.mawingunetworks.com`,
      },
      {
        label: "SME/BKT  196.250.208.204",
        value: `https://hsb-bkt-zabbix.mawingunetworks.com`,
      },
      {
        label: "Enterprise 196.250.208.207",
        value: `https://e-zabbix.mawingunetworks.com/`,
      },
    ],
    intervals: [
      { label: "Monthly", value: "month" },
      { label: "Weekly", value: "week" },
      { label: "Daily", value: "day" },
      { label: "Hourly", value: "hour" },
      { label: "Minutes", value: "minute" },
      { label: "Seconds", value: "second" },
    ],
    interval: "",
    selectedInterval: "",
    Hosts: [],
    Graphs: [],
    Items: [],
    ItemIds: [],
    history: [],
    ClockValues: [],
    yAxisValues: [],
    graphLabel: "",
    colors: [
      ["#26a69a", "rgba(38, 166, 154, .1)"],
      ["#ffd600", "rgba(255, 214, 0, .1)"],
      ["#29b6f6", "rgba(41, 182, 246, .1)"],
      ["#66bb6a", "rgba(102, 187, 106, .1)"],
      ["#9ccc65", "rgba(156, 204, 101, .1)"],
      ["#d4e157", "rgba(212, 225, 87, .1)"],
      ["#f57f17", "rgba(245, 127, 23, .1)"],
      ["#ff6384", "rgba(255, 99, 132, .1)"],
      ["#ec407a", "rgba(236, 64, 122, .1)"],
      ["#ab47bc", "rgba(171, 71, 188, .1)"],
      ["#7e57c2", "rgba(126, 87, 194, .1)"],
      ["#5c6bc0", "rgba(92, 107, 192, .1)"],
      ["#42a5f5", "rgba(66, 165, 245, .1)"],
      ["#29b6f6", "rgba(41, 182, 246, .1)"],
      ["#26c6da", "rgba(38, 198, 218, .1)"],
      ["#ffab00", "rgba(255, 171, 0, .1)"],
      ["#e65100", "rgba(230, 81, 0, .1)"],
      ["#bf360c", "rgba(191, 54, 12, .1)"],
      ["#3e2723", "rgba(62, 39, 35, .1)"],
      ["#8d6e63", "rgba(141, 110, 99, .1)"],
      ["#607d8b", "rgba(96, 125, 139, .1)"],
      ["#cfd8dc", "rgba(207, 216, 220, .1)"],
      ["#c51162", "rgba(197, 17, 98, .1)"],
      ["#e1bee7", "rgba(225, 190, 231, .1)"],
      ["#6200ea", "rgba(98, 0, 234, .1)"],
      ["#00bfa5", "rgba(0, 191, 165, .1)"],
      ["#004d40", "rgba(0, 77, 64, .1)"],
      ["#006064", "rgba(0, 96, 100, .1)"],
      ["#01579b", "rgba(1, 87, 155, .1)"],
    ],
    GraphOptions: null,
  };
  handlePreloaderStyle = (newStyle) => {
    this.setState({ preloaderStyle: newStyle });
  };
  onChange = (e) => {
    e.preventDefault();
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  static getDerivedStateFromProps(props, state) {
    if (props.loginResponse) {
      if (props.loginResponse.result !== state.token) {
        props.fetchHostGroups(props.loginResponse.result, state.base_url);
        return {
          token: props.loginResponse.result,
        };
      }
    }
    if (props.hostGroups) {
      if (props.hostGroups.result !== state.hostGroups) {
        return {
          hostGroups: props.hostGroups.result,
          preloaderStyle: "d-none",
        };
      }
    }
    if (props.hosts) {
      if (props.hosts.result !== state.Hosts) {
        return {
          Hosts: props.hosts.result,
          preloaderStyle: "d-none",
        };
      }
    }
    if (props.graphs) {
      if (props.graphs.result !== state.Graphs) {
        return {
          Graphs: props.graphs.result,
          preloaderStyle: "d-none",
        };
      }
    }
    if (props.items) {
      if (props.items.result !== state.Items) {
        let ids = props.items.result.map((itemId) => {
          return parseInt(itemId.itemid);
        });
        // props.fetchHistory(ids, state.token);
        return {
          Items: props.items.result,
          ItemIds: ids,
        };
      }
    }
    if (props.history) {
      if (props.history.result !== state.history) {
        // console.log(props.history.result);
        function onlyUnique(value, index, self) {
          return self.indexOf(value) === index;
        }

        let clockValues =
          props.history.result instanceof Array
            ? props.history.result.map((clockValue) => {
                let time = moment.unix(clockValue.clock).format();

                return time;
              })
            : null;

        let unique = clockValues
          .sort((a, b) => {
            return new Date(a) - new Date(b);
          })
          .filter(onlyUnique);

        console.log(unique);

        // try
        let tmp = {};

        props.history.result instanceof Array &&
          props.history.result.map((item) => {
            let objectWeek = moment.unix(item.clock).format();
            let weekNumber = moment(objectWeek).isoWeek();
            let weeklyDates = moment
              .unix(item.clock)
              .format()
              .split("-")
              .slice(0, 2)
              .join("-")
              .concat(" (" + weekNumber + ")th week");
            let obj =
              state.selectedInterval === "hour"
                ? (tmp[moment.unix(item.clock).format("DD/MM/YYYY h")] = tmp[
                    moment.unix(item.clock).format("DD/MM/YYYY h")
                  ] || { count: 0, total: 0 })
                : state.selectedInterval === "minute"
                ? (tmp[moment.unix(item.clock).format("DD/MM/YYYY h:mm")] = tmp[
                    moment.unix(item.clock).format("DD/MM/YYYY h:mm")
                  ] || { count: 0, total: 0 })
                : state.selectedInterval === "day"
                ? (tmp[moment.unix(item.clock).format("DD/MM/YYYY")] = tmp[
                    moment.unix(item.clock).format("DD/MM/YYYY")
                  ] || { count: 0, total: 0 })
                : state.selectedInterval === "week"
                ? (tmp[weeklyDates] = tmp[weeklyDates] || {
                    count: 0,
                    total: 0,
                  })
                : (tmp[moment.unix(item.clock).format("DD/MM/YYYY")] = tmp[
                    moment.unix(item.clock).format("DD/MM/YYYY")
                  ] || { count: 0, total: 0 });
            // console.log(item.value / 1000000);
            obj.count++;
            obj.total += item.value / 1000000;
          });

        var res = Object.entries(tmp).map(function (entry) {
          return {
            clock: entry[0],
            value: parseFloat((entry[1].total / entry[1].count).toFixed(2)),
          };
        });

        let sortedDates = res.sort((a, b) => {
          return new Date(a.clock) - new Date(b.clock);
        });

        // console.log(res);
        unique = sortedDates;
        console.log(sortedDates);

        // end of try

        let counter = -1;
        let axisCounter = 0;

        let graphOptions = {
          scales: {
            yAxes:
              state.Items instanceof Array
                ? state.Items.filter((item, index, array) => {
                    if (index === 0 || array[index - 1].units !== item.units) {
                      return item;
                    }
                  }).map((item, index, array) => {
                    return {
                      id: item.itemid,
                      type: "linear",
                      display: true,
                      position:
                        index === 0
                          ? "left"
                          : array[index - 1].units === item.units
                          ? "left"
                          : "right",
                      scaleLabel: {
                        display: true,
                        labelString:
                          item.units === "bps"
                            ? "Mbps"
                            : item.units === "s"
                            ? "ms"
                            : item.units,
                      },
                      ticks: {
                        beginAtZero: true,
                        min: 0,
                      },
                    };
                  })
                : null,
            // xAxes: [
            //   {
            //     type: "time",
            //     time: {
            //       unit: state.selectedInterval,
            //     },
            //   },
            // ],
          },
          responsive: true,
          maintainAspectRatio: true,
        };

        // let tmp = {};

        let values =
          state.Items instanceof Array
            ? state.Items.map((item) => {
                const itemValues = props.history.result.filter(
                  (val) => val.itemid === item.itemid
                );
                let last = 0;
                counter++;
                return {
                  label: item.name,
                  // yAxisID: item.itemid,
                  fill: true,
                  lineTension: 0.1,
                  backgroundColor: state.colors[counter][1],
                  borderColor: state.colors[counter][0],
                  borderCapStyle: "butt",
                  borderDash: [],
                  borderDashOffset: 0.0,
                  borderJoinStyle: "miter",
                  pointBorderColor: state.colors[counter][0],
                  pointBackgroundColor: "#fff",
                  pointBorderWidth: 1,
                  pointHoverRadius: 10,
                  pointHoverBackgroundColor: state.colors[counter][0],
                  pointHoverBorderColor: "rgba(220,220,220,1)",
                  pointHoverBorderWidth: 2,
                  pointRadius: 1,
                  pointHitRadius: 5,
                  data: unique.map((date) => {
                    let curValue = 0;

                    for (let r = 0; r < itemValues.length; r++) {
                      let value = itemValues[r];
                      // console.log(value);
                      let objectWeek = moment.unix(value.clock).format();
                      let weekNumber = moment(objectWeek).isoWeek();
                      let weeklyDates = moment
                        .unix(value.clock)
                        .format()
                        .split("-")
                        .slice(0, 2)
                        .join("-")
                        .concat(" (" + weekNumber + ")th week");

                      if (
                        date.clock ===
                          moment.unix(value.clock).format("DD/MM/YYYY") ||
                        date.clock ===
                          moment.unix(value.clock).format("DD/MM/YYYY h") ||
                        date.clock ===
                          moment.unix(value.clock).format("DD/MM/YYYY h:mm") ||
                        date.clock === moment.unix(value.clock).format() ||
                        date.clock === weeklyDates
                      ) {
                        curValue =
                          item.units === "bps"
                            ? (value.value / 1000000).toFixed(2)
                            : item.units === "s"
                            ? (value.value * 1000).toFixed(2)
                            : value.value;
                        break;
                      }
                    }
                    // console.log(date);

                    if (curValue === 0) {
                      curValue = last;
                    }

                    last = curValue;

                    return curValue;
                  }),
                };
              })
            : null;

        return {
          history: props.history.result,
          ClockValues: unique,
          yAxisValues: values,
          preloaderStyle: "d-none",
          GraphOptions: graphOptions,
        };
      }
    }
  }

  onSelectOptionServer = (value, e) => {
    this.props.loginZabbix(value);
    this.handlePreloaderStyle("d-block");
    this.setState({
      server: value,
      base_url: value,
      hostGroup: [0],
      host: [0],
      graph: [0],
    });
  };

  onSelectOptionHostGroup = (value) => {
    const { token, base_url } = this.state;
    this.handlePreloaderStyle("d-block");
    this.setState({
      hostGroup: value,
      host: [0],
      graph: [0],
    });
    this.props.fetchHostsForHostGroup(value.value, token, base_url);
  };

  onSelectOptionHosts = (value) => {
    const { token, base_url } = this.state;
    this.handlePreloaderStyle("d-block");
    this.setState({
      host: value,
      Graphs: [],
      graph: [0],
    });
    this.props.fetchGraph(value.value, token, base_url);
  };

  onSelectOptionGraphs = (value) => {
    const { token, base_url } = this.state;
    console.log(value, token);
    this.setState({
      graph: value,
      graphLabel: value.label,
    });
    this.props.fetchItems(value.value, token, base_url);
  };

  onSelectOptionInterval = (value) => {
    // console.log(value);
    this.setState({
      interval: value,
      selectedInterval: value.value,
    });
  };

  showSelectedGraph = () => {
    const { ItemIds, start_date, end_date, token, base_url } = this.state;

    if (start_date === "" && end_date === "") {
      this.setState({
        showGraph: true,
        showExport: true,
      });
    } else {
      this.setState({
        showGraph: true,
        showExport: true,
      });
      this.props.fetchHistory(
        ItemIds,
        moment(start_date).format("X"),
        moment(end_date).format("X"),
        token,
        base_url
      );
    }
  };

  handleSearchGraph = () => {
    const {
      ItemIds,
      start_date,
      end_date,
      token,
      base_url,
      graphLabel,
      server,
      host,
      hostGroup,
      graph,
    } = this.state;
    if (
      server === "" ||
      hostGroup === "" ||
      host === "" ||
      start_date === "" ||
      end_date === "" ||
      graph === ""
    ) {
      JSON.stringify(Swal.fire("Ensure all fields are filled", "", "error"));
    } else {
      this.handlePreloaderStyle("d-block");

      this.setState({
        showGraph: true,
        showExport: true,
      });
      this.props.fetchHistory(
        ItemIds,
        new Date(start_date).getTime() / 1000,
        new Date(end_date).getTime() / 1000,
        token,
        base_url,
        graphLabel
      );
    }
  };

  render() {
    const {
      showGraph,
      servers,
      graph,
      hostGroups,
      Hosts,
      Graphs,
      ClockValues,
      yAxisValues,
      history,
      end_date,
      start_date,
      showExport,
      preloaderStyle,
      Items,
      GraphOptions,
      hostGroup,
      host,
      graphLabel,
      intervals,
      interval,
    } = this.state;



    const showHostGroups =
      hostGroups instanceof Array
        ? hostGroups.map((hg) => {
            return { label: hg.name, value: hg.groupid };
          })
        : null;

    const showHosts =
      Hosts instanceof Array
        ? Hosts.map((hg) => {
            return { label: hg.host, value: hg.hostid };
          })
        : null;

    const showGraphOption =
      Graphs instanceof Array
        ? Graphs.map((hg) => {
            return { label: hg.name, value: hg.graphid };
          })
        : null;

    let xAxisDates =
      ClockValues instanceof Array
        ? ClockValues.map((date) => {
            return date.clock;
          })
        : null;

    const data = {
      labels: xAxisDates,
      datasets: yAxisValues,
    };

    console.log(data);

    const options = GraphOptions;

    return (
      <React.Fragment>
        <Preloader preloaderStyle={preloaderStyle} />
        <Sidebar />
        <div className="content">
          <div className="mt-4">
            <Navbar title='CST/NOC Reports'/>
          </div>
          {/* Filter */} 
          {/* <div className=""></div> */}
          <Filter
            servers={servers}
            showHostGroups={showHostGroups}
            onSelectOptionServer={this.onSelectOptionServer}
            onSelectOptionHostGroup={this.onSelectOptionHostGroup}
            showHosts={showHosts}
            onSelectOptionHosts={this.onSelectOptionHosts}
            showGraphOption={showGraphOption}
            onSelectOptionGraphs={this.onSelectOptionGraphs}
            start_date={start_date}
            end_date={end_date}
            onChange={this.onChange}
            handleSearchGraph={this.handleSearchGraph}
            showExport={showExport}
            hostGroup={hostGroup}
            host={host}
            graph={graph}
            graphName={graphLabel}
            intervals={intervals}
            interval={interval}
            onSelectOptionInterval={this.onSelectOptionInterval}
          />
          {/* Graph */}
          <Graph
            showGraph={showGraph}
            data={data}
            options={options}
            graphName={graphLabel}
          />
          {/* Table */}
          <Table
            history={history}
            Items={Items}
            showGraph={showGraph}
            graphName={graphLabel}
          />
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  loginResponse: state.zabbix.loginResponse,
  hostGroups: state.zabbix.hostGroups,
  hosts: state.zabbix.hostsHostGroup,
  graphs: state.zabbix.graphs,
  items: state.zabbix.items,
  history: state.zabbix.history,
});

export default connect(mapStateToProps, {
  loginZabbix,
  fetchHostGroups,
  fetchHostsForHostGroup,
  fetchGraph,
  fetchItems,
  fetchHistory,
})(Zabbix);
