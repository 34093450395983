import {
  GET_ERRORS,
  FETCH_ASSIGNED_TASKS,
  FETCH_TASKS_SUMMARY,
  FETCH_ADMIN_TASK_SUMMARY,
  FETCH_ADMIN_TASK_REVENUE,
  FETCH_ADMIN_TIME_SUMMARY,
  REASSIGN_TASK,
  FETCH_TELESALES,
  ADD_PERSONNEL,
  EDIT_PERSONNEL,
  GET_PERSONNEL,
} from "./types";

import {
  axios,
  createError
} from "../utilities";

export const fetchAssignedTasks = (page, limit, phone, status, date) => (
  dispatch
) => {
  let url = `tasks/assigned?page=${page}&limit=${limit}`;
  if (phone !== "") {
    url += `&phone=${phone}`;
  }
  if (status !== "") {
    url += `&status=${status}`;
  }
  if (date !== "") {
    url += `&date=${date}`;
  }
  axios
    .get(url)
    .then((tasks) => {
      console.log(tasks.data);
      dispatch({
        type: FETCH_ASSIGNED_TASKS,
        payload: tasks.data,
      });
    })
    .catch((err) => {
      // console.log(err.response);
      dispatch(createError(err, GET_ERRORS));
    });
};

export const fetchTaskSummary = (date) => (dispatch) => {
  let url = `tasks/summary`;
  if (date !== "") {
    url += `&date=${date}`;
  }
  axios
    .get(url)
    .then((tasks) => {
      console.log(tasks.data);
      dispatch({
        type: FETCH_TASKS_SUMMARY,
        payload: tasks.data,
      });
    })
    .catch((err) => {
      // console.log(err.response);
      dispatch(createError(err, GET_ERRORS));
    });
};

export const selectTask = (task) => (dispatch) => {
  axios
    .patch(`tasks/${task.task_id}/select`)
    .then((tasks) => {
      dispatch({
        type: FETCH_ASSIGNED_TASKS,
        payload: tasks.data,
      });
    })
    .catch((err) => {
      dispatch(createError(err, GET_ERRORS));
    });
};

export const completeTask = (task) => (dispatch) => {
  const task_id = task.task_id;
  delete task.task_id;
  // console.log(task);
  axios
    .patch(`tasks/${task_id}/complete`, task)
    .then((tasks) => {
      dispatch({
        type: FETCH_ASSIGNED_TASKS,
        payload: tasks.data,
      });
    })
    .catch((err) => {
      dispatch(createError(err, GET_ERRORS));
    });
};

export const deferTask = (task) => (dispatch) => {
  const task_id = task.task_id;
  delete task.task_id;

  axios
    .patch(`tasks/${task_id}/defer`, task)
    .then((tasks) => {
      // console.log(tasks.data);
      dispatch({
        type: FETCH_ASSIGNED_TASKS,
        payload: tasks.data,
      });
    })
    .catch((err) => {
      dispatch(createError(err, GET_ERRORS));
    });
};

//fetching admin summary data
export const fetchAdminTaskSummary = (date) => (dispatch) => {
  let url = `tasks/summary/personnel`;

  if (date !== "") {
    url += `?date=${date}`;
  }

  axios
    .get(url)
    .then((tasks) => {
      // console.log(tasks.data);
      dispatch({
        type: FETCH_ADMIN_TASK_SUMMARY,
        payload: tasks.data,
      });
      dispatch(fetchTelesales());
    })

    .catch((err) => {
      dispatch(createError(err, GET_ERRORS));
    });
};

//fetching task revenue data
export const fetchTaskRevenue = (range) => (dispatch) => {
  let url = `/customer-credit/daily/tasks/completed`;
  if (range.sdate) {
    url += `?sdate=${range.sdate}&edate=${range.edate}`;
  }
  axios
    .get(url)
    .then((tasks) => {
      console.log(tasks.data);
      dispatch({
        type: FETCH_ADMIN_TASK_REVENUE,
        payload: tasks.data,
      });
    })
    .catch((err) => {
      dispatch(createError(err, GET_ERRORS));
    });
};

//fetching time summary
export const fetchTimeSummary = (date) => (dispatch) => {
  let url = `/tasks/time/personnel`;
  if (date !== "") {
    url += `?date=${date}`;
  }
  axios
    .get(url)
    .then((tasks) => {
      // console.log(tasks.data);
      dispatch({
        type: FETCH_ADMIN_TIME_SUMMARY,
        payload: tasks.data,
      });
    })
    .catch((err) => {
      dispatch(createError(err, GET_ERRORS));
    });
};

// Reassign tasks
export const reassignTask = (taskData) => (dispatch) => {
  // console.log("you can reassign");
  let url = `tasks/reassign`;
  axios
    .patch(url, taskData)
    .then((taskToReassign) => {
      // console.log(taskToReassign.data);
      dispatch({
        type: REASSIGN_TASK,
        payload: taskToReassign.data,
      });
      dispatch(fetchAdminTaskSummary());
    })

    .catch((err) => {
      dispatch(createError(err, GET_ERRORS));
    });
};

//fetch personel/telesales
export const fetchTelesales = () => (dispatch) => {
  let url = `/personnel/telesales`;
  axios
    .get(url)
    .then((telesales) => {
      // console.log(telesales.data);
      dispatch({
        type: FETCH_TELESALES,
        payload: telesales.data,
      });
    })
    .catch((err) => {
      dispatch(createError(err, GET_ERRORS));
      dispatch(reassignTask());
    });
};

//get one telesale
export const getPersonnel = (personnel_id) => (dispatch) => {
  console.log("its getting to this point");
  let url = `/personnel/telesales/${personnel_id}`;
  axios
    .get(url)
    .then((onePersonnel) => {
      console.log(onePersonnel.data);
      dispatch({
        type: GET_PERSONNEL,
        payload: onePersonnel.data,
      });
    })
    .catch((err) => {
      dispatch(createError(err, GET_ERRORS));
      dispatch(reassignTask());
    });
};

//add personnel
export const addPersonnel = (personnelData) => (dispatch) => {
  const url = `/personnel`;
  axios
    .post(url, personnelData)
    .then((personel) => {
      console.log(personel.data);
      dispatch({
        type: ADD_PERSONNEL,
        payload: personel.data,
      });
    })
    .catch((err) => {
      dispatch(createError(err, GET_ERRORS));
    });
};

// edit personnel
export const editPersonnel = (personnel) => (dispatch) => {
  let url = `/personnel/${personnel.personnel_id}`;
  axios
    .patch(url, personnel)
    .then((res) => {
      dispatch({
        type: EDIT_PERSONNEL,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch(createError(err, GET_ERRORS));
    });
};