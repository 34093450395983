import React, { Component } from "react";
import { Sidebar, Navbar, Preloader, Error } from "../common";
import { Display, Create } from "../personnel";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { CSVLink } from "react-csv";

import {
  fetchPersonnel,
  createPersonnel,
  updatePersonnel,
} from "../../actions/personnelActions";

class Personnel_New extends Component {
  state = {
    preloaderStyle: "d-none",
    showSearch: true,
    personnel: [],
    personnelUpdated: {},
    personnelCreated: {},
    errors: [],
    personnelCount: 1,
    errorMessage: "",
  };

  handlePreloaderStyle = (newStyle) => {
    this.setState({ preloaderStyle: newStyle });
  };

  fetchPersonnel = () => {
    this.handlePreloaderStyle("d-block");
    this.props.fetchPersonnel();
  };

  componentDidMount() {
    if (!this.props.auth.isAuthenticated) {
      this.props.history.push("/admin");
    }
    this.fetchPersonnel();
  }

  componentWillReceiveProps(nextProps) {
    this.handlePreloaderStyle("d-none");

    if (nextProps.personnel) {
      const newPersonnel = nextProps.personnel;
      this.setState({
        personnel: newPersonnel,
      });
    }

    if (nextProps.personnelCreated) {
      const personnelCreated = nextProps.personnelCreated;
      if (personnelCreated.message === "Success") {
        nextProps.personnelCreated.message = "";
        this.fetchPersonnel();
      }
    }

    if (nextProps.personnelUpdated) {
      const personnelUpdated = nextProps.personnelUpdated;
      if (personnelUpdated.message === "Success") {
        nextProps.personnelUpdated.message = "";
        this.fetchPersonnel();
      }
    }
    if (nextProps.errors) {
      //  console.log(nextProps.errors);
      this.setState({ errors: nextProps.errors });
    }
  }
  render() {
    const { personnel, preloaderStyle, personnelCount, errorMessage, errors } =
      this.state;
    return (
      <React.Fragment>
        <Preloader preloaderStyle={preloaderStyle} />
        <Sidebar />
        <div className="content">
          <div className="mt-4">
            <Navbar title="Personnel" toggleSearch={this.toggleSearch} />
          </div>
          <div className="container-fluid mt-4">
            <div className="row">
              <div className="col-md-12">
                <Error message={errorMessage} />
                <div className="card">
                  <div className="card-body">
                    <div className="d-flex justify-content-between flex-row">
                      <h5 className="card-title">Personnel</h5>
                      <div className="">
                        <Create
                          personnel_id={personnel.personnel_id}
                          personnel_onames={personnel.personnel_onames}
                          personnel_fname={personnel.personnel_fname}
                          personnel_phone={personnel.personnel_phone}
                          personnel_status={personnel.personnel_status}
                          personnel_type_id={personnel.personnel_type_id}
                          createPersonnel={this.props.createPersonnel}
                          errors={errors}
                          handlePreloaderStyle={this.handlePreloaderStyle}
                        />
                        <div className="btn btn-sm btn-success ml-5">
                          <i class="fas fa-download"></i>
                          <CSVLink
                            data={personnel}
                            filename={"personnel.csv"}
                            className="text-white ml-3"
                            target="_blank"
                          >
                            Export
                          </CSVLink>
                        </div>
                      </div>
                    </div>

                    <Display
                      personnel={personnel}
                      personnelCount={personnelCount}
                      updatePersonnel={this.props.updatePersonnel}
                      errors={errors}
                      handlePreloaderStyle={this.handlePreloaderStyle}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

Personnel_New.propTypes = {
  fetchPersonnel: PropTypes.func.isRequired,
  createPersonnel: PropTypes.func.isRequired,
  updatePersonnel: PropTypes.func.isRequired,
  personnel: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  personnel: state.personnel.personnel,
  personnelUpdated: state.personnel.personnelUpdated,
  personnelCreated: state.personnel.personnelCreated,
  errors: state.errors,
  auth: state.auth,
});

export default connect(mapStateToProps, {
  fetchPersonnel,
  createPersonnel,
  updatePersonnel,
})(Personnel_New);
