import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

class Defer extends Component {
  constructor() {
    super();
    this.state = {
      customer: "",
      task_id: "",
      task_status_id: "",
      comments: "",
      onSubmit: {}
    };
    this.deferTask = this.deferTask.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  componentDidMount() {
    this.setState({
      customerName: this.props.customerName,
      task_id: this.props.task_id,
      task_status_id: this.props.task_status_id,
      onSubmit: this.props.onSubmit
    });
  }

  deferTask(e) {
    e.preventDefault();
    this.props.handlePreloaderStyle("d-block");
    const task = {
      task_id: this.state.task_id,
      task_status_id: this.state.task_status_id,
      comments: this.state.comments
    };

    this.props.onSubmit(task, this.props.history);
  }

  render() {
    const { task_id, customerName } = this.state;
    return (
      <React.Fragment>
        <button
          type="button"
          className="btn btn-danger btn-sm"
          data-toggle="modal"
          data-target={"#deferModal" + task_id}
        >
          Defer
        </button>
        <div
          className="modal fade"
          id={"deferModal" + task_id}
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Defer {customerName}
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <form noValidate onSubmit={this.deferTask}>
                <div className="modal-body">
                  <div className="form-group">
                    <label htmlFor="comments">Comments</label>
                    <textarea
                      className="form-control"
                      name="comments"
                      id={"comments"}
                      rows="3"
                      onChange={this.onChange}
                    />
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                  <button
                    type="button"
                    onClick={this.deferTask}
                    className="btn btn-danger"
                    data-dismiss="modal"
                  >
                    Defer Customer
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

Defer.propTypes = {
  customerName: PropTypes.string.isRequired,
  task_id: PropTypes.number.isRequired,
  task_status_id: PropTypes.number.isRequired,
  onSubmit: PropTypes.func.isRequired,
  handlePreloaderStyle: PropTypes.func.isRequired
};

export default connect(
  null,
  {}
)(withRouter(Defer));
