import {
    LOGIN_ZABBIX,
    GET_ERRORS,
    FETCH_HOSTGROUPS,
    FETCH_HOSTS,
    FETCH_GRAPHS,
    FETCH_ITEMS,
    FETCH_HISTORY,
    FETCH_HOSTS_HISTORY,
    FETCH_APPLICATIONS,
    FETCH_ITEM_APPLICATION,
    FETCH_HOSTS_HOSTGROUP
} from '../actions/types';

const initialState = {
    loginResponse: {},
    error: {},
    hostGroups: {},
    hosts: {},
    graphs: {},
    items: {},
    history: {},
    hostHistory: {},
    applications: {},
    itemApplication: {},
    hostsHostGroup: {}
}

export default function (state = initialState, action) {
    switch (action.type) {
        case LOGIN_ZABBIX:
            return {
                loginResponse: action.payload
            }
            case GET_ERRORS:
                return {
                    error: action.payload
                }
                case FETCH_HOSTGROUPS:
                    return {
                        hostGroups: action.payload
                    }
                    case FETCH_HOSTS:
                        return {
                            hosts: action.payload
                        }
                        case FETCH_GRAPHS:
                            return {
                                graphs: action.payload
                            }
                            case FETCH_ITEMS:
                                return {
                                    items: action.payload
                                }
                                case FETCH_HISTORY:
                                    return {
                                        history: action.payload
                                    }
                                    case FETCH_HOSTS_HISTORY:
                                        return {
                                            hostHistory: action.payload
                                        }
                                        case FETCH_APPLICATIONS:
                                            return {
                                                applications: action.payload
                                            }
                                            case FETCH_ITEM_APPLICATION:
                                                return {
                                                    itemApplication: action.payload
                                                }
                                                case FETCH_HOSTS_HOSTGROUP:
                                                    return {
                                                        hostsHostGroup: action.payload
                                                    }
                                                    default:
                                                        return state;
    }
}