import {
  FETCH_CUSTOMERS,
  GET_ERRORS,
  ADD_NEW_CUSTOMER,
  FETCH_ISP_AGENTS,
  ADD_AGENT_FLOAT,
  ADD_NEW_AGENT,
  FETCH_LOCATIONS,
  FETCH_REGIONS,
  EDIT_AGENT,
  DELETE_AGENT,
  CHANGE_AGENT_ACCESS,
  FETCH_AGENTS_PAYMENTS,
  FETCH_AGENTS_PAYMENTS_EXPORT,
} from "./types";
import Swal from "sweetalert2";

import { axios, createError } from "../utilities";

const headers = {
  Accept: "application/json",
  "Content-Type": "application/json",
};

export const fetchCustomers = (page, limit) => (dispatch) => {
  let url = `commissions?page=${page}&limit=${limit}`;
  axios
    .get(url)
    .then((commissions) => {
      console.log(commissions.data);
      dispatch({
        type: FETCH_CUSTOMERS,
        payload: commissions.data,
      });
    })
    .catch((err) => {
      dispatch(createError(err, GET_ERRORS));
    });
};

export const fetchCustomersAdmin = (page, limit, phone, agent_phone) => (
  dispatch
) => {
  let url = `commissions/admin?page=${page}&limit=${limit}`;
  if (phone !== "") {
    url += `&phone=${phone}`;
  }
  if (agent_phone !== "") {
    url += `&agent_phone=${agent_phone}`;
  }
  axios
    .get(url)
    .then((commissions) => {
      // console.log(commissions.data);
      dispatch({
        type: FETCH_CUSTOMERS,
        payload: commissions.data,
      });
    })
    .catch((err) => {
      dispatch(createError(err, GET_ERRORS));
    });
};

//add new customer
export const addCustomer = (customerData) => (dispatch) => {
  let url = `commissions/add`;
  axios
    .post(url, customerData)
    .then((customer) => {
      // console.log(customer.data);
      dispatch({
        type: ADD_NEW_CUSTOMER,
        payload: customer.data,
      });
    })
    .catch((err) => {
      dispatch(createError(err, GET_ERRORS));
    });
};

// Fetch all agents
export const fetchAgents = (page, limit, phone, status, accesscode) => (
  dispatch
) => {
  let base_url = `https://admin.mawingunetworks.com`;

  let url = `agents?page=${page}&limit=${limit}`;
  if (phone !== "") {
    url += `&phone=${phone}`;
  }
  if (status !== "") {
    url += `&status=${status}`;
  }
  if (accesscode !== "") {
    url += `&accesscode=${accesscode}`;
  }

  axios({
    transformRequest: [
      (data, headers) => {
        delete headers.common.Authorization;
        return data;
      },
    ],
    method: "get",
    baseURL: base_url,
    url,
    headers,
  })
    .then((agents) => {
      console.log(agents.data);
      dispatch({
        type: FETCH_ISP_AGENTS,
        payload: agents.data,
      });
    })
    .catch((err) => {
      dispatch(createError(err, GET_ERRORS));
    });
};

// Add float to agent
export const addFloat = (agentDetails) => (dispatch) => {
  let base_url = `https://admin.mawingunetworks.com`;
  // let base_url = `http://localhost:5001`;

  let url = `agentcredits/addManualFloat`;

  axios({
    transformRequest: [
      (data, headers) => {
        delete headers.common.Authorization;
        return data;
      },
    ],
    method: "post",
    baseURL: base_url,
    url,
    data: JSON.stringify(agentDetails),
    headers,
  })
    .then((response) => {
      Swal.fire("Success", response.data.message, "success");

      dispatch({
        type: ADD_AGENT_FLOAT,
        payload: response.data,
      });
    })
    .catch((err) => {
      Swal.fire("Error", err.response.data.error, "error");
      dispatch(createError(err, GET_ERRORS));
    });
};

// add a new agent
export const addAgent = (agentDetails) => (dispatch) => {
  let base_url = `https://admin.mawingunetworks.com`;
  // let base_url = `http://localhost:5001`;

  let url = `agents`;

  axios({
    transformRequest: [
      (data, headers) => {
        delete headers.common.Authorization;
        return data;
      },
    ],
    method: "post",
    baseURL: base_url,
    url,
    data: JSON.stringify(agentDetails),
    headers,
  })
    .then((response) => {
      Swal.fire("Success", response.data.message, "success");

      dispatch({
        type: ADD_NEW_AGENT,
        payload: response.data,
      });
    })
    .catch((err) => {
      Swal.fire("Error", err.response.data.error, "error");
      dispatch(createError(err, GET_ERRORS));
    });
};

// get regions
export const fetchRegions = () => (dispatch) => {
  let base_url = `https://radius-backend.mawingunetworks.com`;

  let url = `nas/region`;

  axios({
    transformRequest: [
      (data, headers) => {
        delete headers.common.Authorization;
        return data;
      },
    ],
    method: "get",
    baseURL: base_url,
    url,
    headers,
  })
    .then((response) => {
      // console.log(response.data);
      dispatch({
        type: FETCH_REGIONS,
        payload: response.data,
      });
    })
    .catch((err) => {
      dispatch(createError(err, GET_ERRORS));
    });
};

// get locations
export const fetchLocations = () => (dispatch) => {
  let base_url = `https://radius-backend.mawingunetworks.com`;

  let url = `nas/location`;

  axios({
    transformRequest: [
      (data, headers) => {
        delete headers.common.Authorization;
        return data;
      },
    ],
    method: "get",
    baseURL: base_url,
    url,
    headers,
  })
    .then((response) => {
      dispatch({
        type: FETCH_LOCATIONS,
        payload: response.data,
      });
    })
    .catch((err) => {
      dispatch(createError(err, GET_ERRORS));
    });
};

// edit agent
export const editAgent = (id, agentDetails) => (dispatch) => {
  console.log(agentDetails);
  let base_url = `https://admin.mawingunetworks.com`;
  // let base_url = `http://localhost:5001`;

  let url = `agents/${id}`;

  axios({
    transformRequest: [
      (data, headers) => {
        delete headers.common.Authorization;
        return data;
      },
    ],
    method: "patch",
    baseURL: base_url,
    url,
    data: JSON.stringify(agentDetails),
    headers,
  })
    .then((response) => {
      Swal.fire("Success", response.data.message, "success");

      dispatch({
        type: EDIT_AGENT,
        payload: response.data,
      });
    })
    .catch((err) => {
      Swal.fire("Error", err.response.data.error, "error");
      dispatch(createError(err, GET_ERRORS));
    });
};

// delete agent
export const deleteAgent = (id) => (dispatch) => {
  let base_url = `https://admin.mawingunetworks.com`;

  let url = `agents?id=${id}`;

  axios({
    transformRequest: [
      (data, headers) => {
        delete headers.common.Authorization;
        return data;
      },
    ],
    method: "delete",
    baseURL: base_url,
    url,
    headers,
  })
    .then((response) => {
      Swal.fire("Success", response.data.message, "success");

      dispatch({
        type: DELETE_AGENT,
        payload: response.data,
      });
    })
    .catch((err) => {
      Swal.fire("Error", err.response.data.error, "error");
      dispatch(createError(err, GET_ERRORS));
    });
};
// change agent freeUserName
export const changeFreeInternetUserName = (FreeInternetUserName) => (
  dispatch
) => {
  console.log(FreeInternetUserName);
  let base_url = `https://admin.mawingunetworks.com`;

  let url = `agents/changeAgentaccess`;

  axios({
    transformRequest: [
      (data, headers) => {
        delete headers.common.Authorization;
        return data;
      },
    ],
    method: "post",
    baseURL: base_url,
    url,
    data: JSON.stringify(FreeInternetUserName),
    headers,
  })
    .then((response) => {
      Swal.fire("Success", response.data.message, "success");

      dispatch({
        type: CHANGE_AGENT_ACCESS,
        payload: response.data,
      });
    })
    .catch((err) => {
      Swal.fire("Error", err.response.data.error, "error");
      dispatch(createError(err, GET_ERRORS));
    });
};

// agents payments
export const fetchAgentsPayments = (page, limit, phone, mpesaReceipt) => (
  dispatch
) => {
  let url = `agentcredits/allfloats?page=${page}&limit=${limit}`;

  if (phone !== "") {
    url += `&phone=${phone}`;
  }
  if (mpesaReceipt !== "") {
    url += `&mpesaReceipt=${mpesaReceipt}`;
  }

  axios({
    method: "get",
    baseURL: `https://admin.mawingunetworks.com`,
    url,
  })
    .then((response) => {
      dispatch({
        type: FETCH_AGENTS_PAYMENTS,
        payload: response.data,
      });
    })
    .catch((err) => {
      dispatch(
        createError(
          {
            error: "Ooops something went wrong",
          },
          GET_ERRORS
        )
      );
    });
};

// agents payments export
export const fetchAgentsPaymentsExport = (date) => (dispatch) => {
  let url = `agentcredits/exportCreated`;

  if (date !== "") {
    url += `?date=${date}`;
  }

  axios({
    method: "get",
    baseURL: `https://admin.mawingunetworks.com`,
    url,
  })
    .then((response) => {
      console.log(response.data);
      dispatch({
        type: FETCH_AGENTS_PAYMENTS_EXPORT,
        payload: response.data,
      });
    })
    .catch((err) => {
      dispatch(
        createError(
          {
            error: "Ooops something went wrong",
          },
          GET_ERRORS
        )
      );
    });
};
