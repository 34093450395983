import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

class Select extends Component {
  constructor() {
    super();
    this.state = {
      buttonClass: "",
      buttonTitle: "",
      customer: "",
      customer_id: "",
      customer_status_id: "",
      onSubmit: {},
    };
  }

  componentDidMount() {
    this.setState({
      buttonClass: this.props.buttonClass,
      buttonTitle: this.props.buttonTitle,
      customer: this.props.customerName,
      customer_id: this.props.customer_id,
      customer_status_id: this.props.customer_status_id,
      onSubmit: this.props.onSubmit,
    });
  }

  selectCustomer = (e) => {
    e.preventDefault();
    this.props.handlePreloaderStyle("d-block");

    this.props.onSubmit(this.state.customer_id, this.props.history);
  };

  render() {
    const { buttonClass, buttonTitle } = this.state;
    return (
      <form noValidate onSubmit={this.selectCustomer}>
        <button type="submit" className={"btn btn-sm " + buttonClass}>
          {buttonTitle}
        </button>
      </form>
    );
  }
}

Select.propTypes = {
  customerName: PropTypes.string.isRequired,
  // customer_id: PropTypes.number.isRequired,
  customer_status_id: PropTypes.number.isRequired,
  handlePreloaderStyle: PropTypes.func.isRequired,
};

export default connect(null, {})(withRouter(Select));
