import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { loginPersonnel, requestPin } from "../../actions/authActions";
import { Preloader, LoginTextFieldGroup } from "../common";

class Login extends Component {
  constructor() {
    super();
    this.state = {
      phone: "",
      password: "",
      errors: {},
      preloaderStyle: "d-none",
      signInDetails: {},
      loginUrl: process.env.REACT_APP_BACKEND + "/auth/signin",
      errors: "",
      pin: "",
      showPin: false,
    };
  }

  handlePreloaderStyle = (newStyle) => {
    this.setState({ preloaderStyle: newStyle });
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  componentDidMount() {
    if (this.props.auth.isAuthenticated) {
      this.redirectUser(this.props.auth.personnel.personnel_type_id);
    }

    let search = this.props.location.search;
    let params = new URLSearchParams(search);
    let access_token = params.get("access_token");
    let expires_in = params.get("expires_in");

    if (access_token) {
      // this.props.loginPersonnel(
      //   { access_token, expires_in },
      //   this.props.history
      // );
      // this.props.loginPersonnel({ access_token, expires_in });
    }
  }

  componentWillUpdate() {
    if (this.props.auth.isAuthenticated) {
      this.redirectUser(this.props.auth.personnel.personnel_type_id);
    }

    let search = this.props.location.search;
    let params = new URLSearchParams(search);
    let access_token = params.get("access_token");
    let expires_in = params.get("expires_in");

    if (access_token) {
      // this.props.loginPersonnel(
      //   { access_token, expires_in },
      //   this.props.history
      // );
      this.props.loginPersonnel({ access_token, expires_in });
    }
  }

  componentWillUnmount() {
    this.setState({
      showPin: false,
      errors: {},
    });
  }

  componentWillReceiveProps(nextProps) {
    this.handlePreloaderStyle("d-none");
    if (nextProps.auth.isAuthenticated) {
      this.redirectUser();
      // this.redirectUser(nextProps.auth.personnel.personnel_type_id);
    }

    if (nextProps.pinDetails) {
      console.log(nextProps.pinDetails);
      if (nextProps.pinDetails.message == "Success") {
        this.setState({
          showPin: true,
          errors: {},
        });
      }
    }

    if (nextProps.errors) {
      // console.log(nextProps.errors);
      this.setState({ errors: nextProps.errors });
      if (
        nextProps.errors.password ==
        "Please request an admin permission to reset your password"
      ) {
        this.setState({
          showPin: false,
        });
      }
    }
  }

  // redirectUser = () => {
  //   this.props.history.push("/singleSms");
  // };

  redirectUser = (personnelTypeId) => {
    if (personnelTypeId === 1) {
      this.props.history.push("/customers");
    } else if (personnelTypeId === 2) {
      this.props.history.push("/tasks");
    } else if (personnelTypeId === 3) {
      this.props.history.push("/singleSms");
    } else if (personnelTypeId === 5) {
      this.props.history.push("/noc_report");
    }
  };

  // componentDidUpdate() {
  //   if (Object.keys(this.state.signInDetails).length !== 0) {
  //     this.props.loginPersonnel(this.state.signInDetails, this.props.history);
  //   }
  // }

  onSubmit = (e) => {
    e.preventDefault();
    const { showPin } = this.state;

    this.handlePreloaderStyle("d-block");

    const personnel = {
      phone: this.state.phone,
      password: this.state.password,
    };

    let pinData = {
      phone: this.state.phone,
      pin: this.state.pin,
    };

    if (showPin) {
      this.props.loginPersonnel(pinData, this.props.history);
    } else {
      this.props.requestPin(personnel);
    }
  };
  render() {
    const { preloaderStyle, loginUrl, errors, pin, showPin } = this.state;
    // console.log(errors);

    return (
      <React.Fragment>
        <Preloader preloaderStyle={preloaderStyle} />
        <div className="mt-5 d-flex justify-content-center">
          <div className="mt-5 p-3 login-container shadow">
            <div className="login-header">
              <img
                src={require("../../assets/img/mawingu.png")}
                alt="Mawingu Logo"
              />
              <h1 className="my-3">Admin Login</h1>
            </div>
            <div className="login-body">
              <form noValidate onSubmit={this.onSubmit}>
                <LoginTextFieldGroup
                  icon="fa fa-phone"
                  type="phone"
                  name="phone"
                  value={this.state.phone}
                  onChange={this.onChange}
                  id="phone"
                  error={errors.phone}
                  placeholder="Enter your phone"
                  style={{ padding: "10px", fontSize: "17px" }}
                />
                <LoginTextFieldGroup
                  icon="fa fa-lock"
                  type="password"
                  name="password"
                  value={this.state.password}
                  onChange={this.onChange}
                  id="password"
                  error={!showPin && errors.password}
                  placeholder="Enter your password"
                  style={{ padding: "10px", fontSize: "17px" }}
                />
                {showPin && (
                  <LoginTextFieldGroup
                    icon="fa fa-lock"
                    type="password"
                    name="pin"
                    value={pin}
                    onChange={this.onChange}
                    id="pin"
                    error={errors.pin}
                    placeholder="Enter your pin"
                    style={{ padding: "10px", fontSize: "17px" }}
                  />
                )}
                <button type="submit" className="btn btn-primary btn-sm">
                  Login
                </button>
                <div className="text-center mt-3">
                  <Link to="/resetPassword" style={{ color: "white" }}>
                    Reset Password
                  </Link>
                  {/* <a href={loginUrl} style={{ color: "white" }}>
                    Click here to login
                  </a> */}
                </div>
              </form>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

Login.propTypes = {
  loginPersonnel: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    errors: state.errors,
    pinDetails: state.auth.requestPin,
  };
};

export default connect(mapStateToProps, { loginPersonnel, requestPin })(
  withRouter(Login)
);
