import React, { Component } from "react";
import PropTypes from "prop-types";

import InputFields from "../common/InputFields";
import DateRangePickerComponent from "../common/DateRangePickerComponent";

class SearchCommissions extends Component {
  render() {
    const {
      startDate,
      endDate,
      focusedInput,
      startDateId,
      endDateId,
      customer_phone,
      agent_phone,
      payment_type
    } = this.props;

    return (
      <React.Fragment>
        <div className="card">
          <div className="card-body">
            <h5 className="card-title">Search</h5>
            <form
              noValidate
              onSubmit={e =>
                this.props.handleSearch(
                  e,
                  startDate,
                  endDate,
                  customer_phone,
                  agent_phone,
                  payment_type
                )
              }
            >
              <div className="form-group">
                <label className="control-label mb-10 text-left">Date</label>
                <DateRangePickerComponent
                  startDate={startDate}
                  endDate={endDate}
                  startDateId={startDateId}
                  endDateId={endDateId}
                  focusedInput={focusedInput}
                  handleDateChange={this.props.handleDateChange}
                  handleFocusChange={this.props.handleFocusChange}
                  isOutsideRange={this.props.isOutsideRange}
                />
              </div>
              <InputFields
                type="number"
                name="customer_phone"
                onChange={this.props.onChangeNumber}
                value={customer_phone}
                label="Customer Phone"
              />
              <InputFields
                type="number"
                name="agent_phone"
                onChange={this.props.onChangeNumber}
                value={agent_phone}
                label="Agent Phone"
              />
              <div className="form-group">
                <label htmlFor="Task Status" className="control-label mb-10">
                  Commission Type
                </label>
                <select
                  className="form-control"
                  onChange={this.props.onChange}
                  name="payment_type"
                  value={payment_type}
                >
                  <option value="">--Select Payment Type--</option>
                  <option value="1">First Day Topup</option>
                  <option value="2">Legacy Commission</option>
                </select>
              </div>
              <div className="mt-3">
                <button
                  onClick={this.props.handleCloseSearch}
                  className="btn btn-default btn-sm"
                >
                  <i className="fa fa-times" /> Close Search
                </button>
                <button type="submit" className="btn btn-warning btn-sm">
                  <i className="fa fa-search" />
                  Search
                </button>
              </div>
            </form>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

SearchCommissions.propTypes = {
  handleSearch: PropTypes.func.isRequired,
  handleCloseSearch: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onChangeNumber: PropTypes.func.isRequired,
  startDateId: PropTypes.string.isRequired,
  endDateId: PropTypes.string.isRequired,
  focusedInput: PropTypes.string,
  isOutsideRange: PropTypes.func.isRequired,
  handleDateChange: PropTypes.func.isRequired,
  handleFocusChange: PropTypes.func.isRequired
};

export default SearchCommissions;
