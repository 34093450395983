import React, { Component } from "react";
import PropTypes from "prop-types";
import TextAreaFields from "../common/TextAreaFields";
import InputFile from "../common/InputFile";

class Create extends Component {
  constructor() {
    super();
    this.state = {
      message: "",
      recepients: null,
      loaded: null
    };
  }

  onChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  onFileChange = event => {
    this.setState({
      recepients: event.target.files[0],
      loaded: 0
    });
  };

  submitNewCampaign = e => {
    e.preventDefault();

    const { message, recepients } = this.state;

    const campaign = {
      message,
      recepients
    };
    this.props.handlePreloaderStyle("d-block");
    this.props.createCampaign(campaign);
  };

  render() {
    const { message, recepients } = this.state;
    const { errors } = this.props;
    // console.log(errors);
    return (
      <React.Fragment>
        <button
          type="button"
          className="btn btn-primary btn-sm"
          data-toggle="modal"
          data-target="#addNewCampaign"
        >
          Add Campaign
        </button>
        <div
          className="modal fade"
          id="addNewCampaign"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Add Campaign
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <form noValidate>
                <div className="modal-body">
                  <div className="container-fluid">
                    <TextAreaFields
                      name="message"
                      onChange={this.onChange}
                      label="Message"
                      placeholder="message"
                      value={message}
                      error={errors.message}
                    />
                    <InputFile
                      type="text"
                      name="recepients"
                      value={recepients}
                      onChange={this.onFileChange}
                      label="Recepients"
                      placeholder="Recepients"
                      error={errors.recepients}
                    />
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                  <button
                    type="button"
                    onClick={this.submitNewCampaign}
                    className="btn btn-success"
                    data-dismiss="modal"
                  >
                    Add Campaign
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

Create.propTypes = {
  createCampaign: PropTypes.func.isRequired,
  handlePreloaderStyle: PropTypes.func.isRequired
};

export default Create;
