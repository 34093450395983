import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { Preloader, Sidebar, Navbar } from "../common";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import Swal from "sweetalert2";

import {
  loginZabbix,
  fetchHostGroups,
  fetchHosts,
  fetchGraph,
  fetchItems,
  fetchHistory,
  fetchHostsHistory,
  fetchApplications,
  fetchItemsOfApplication,
  fetchGroupHosts,
} from "../../actions/zabbixActions";
import Filter from "./Filter";
import Table from "./Table";
// import Navbar from "../layout/Navbar";

class MainLayout extends Component {
  state = {
    server: "",
    preloaderStyle: "d-none",
    hostGroup: "",
    host: "",
    graph: "",
    token: "",
    base_url: "",
    hostGroups: [],
    showGraph: false,
    showExport: false,
    start_date: "",
    end_date: "",
    servers: [
      {
        label: "Core 196.250.208.205",
        value: `https://core-zabbix.mawingunetworks.com`,
      },
      {
        label: "SME/BKT  196.250.208.204",
        value: `https://hsb-bkt-zabbix.mawingunetworks.com`,
      },
      {
        label: "Enterprise 196.250.208.207",
        value: `https://e-zabbix.mawingunetworks.com/`,
      },
    ],
    Hosts: [],
    Graphs: [],
    Items: [],
    ItemIds: [],
    history: [],
    ClockValues: [],
    yAxisValues: [],
    hostApplicationHistory: [],
    hostsIds: [],
    applications: [],
    application: [0],
    itemApplications: [],
    idsForItems: [],
    idsForHosts: [],
    uniqueItemNames: [],
    itemsWithValueType: [],
    allParam: { name: "All", groupid: 0 },
    hostItemsHistory: [],
    prevousHostGroup: "",
  };
  handlePreloaderStyle = (newStyle) => {
    this.setState({ preloaderStyle: newStyle });
  };
  onChange = (e) => {
    e.preventDefault();
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  static getDerivedStateFromProps(props, state) {
    if (props.loginResponse) {
      if (props.loginResponse.result !== state.token) {
        props.fetchHostGroups(props.loginResponse.result, state.base_url);
        return {
          token: props.loginResponse.result,
        };
      }
    }
    if (props.hostGroups) {
      if (props.hostGroups.result !== state.hostGroups) {
        props.hostGroups.result instanceof Array &&
          props.hostGroups.result.push(state.allParam);
        return {
          hostGroups: props.hostGroups.result,
          preloaderStyle: "d-none",
        };
      }
    }
    if (props.hosts) {
      if (props.hosts.result !== state.Hosts) {
        let ids = props.hosts.result.map((hostID) => {
          return parseInt(hostID.hostid);
        });
        return {
          Hosts: props.hosts.result,
          hostsIds: ids,
        };
      }
    }
    if (props.hostApplicationHistory) {
      if (
        props.hostApplicationHistory.result !== state.hostApplicationHistory
      ) {
        let hostItemsHistory = state.hostItemsHistory;
        hostItemsHistory[props.hostApplicationHistory.index].history =
          hostItemsHistory[props.hostApplicationHistory.index].history === null
            ? props.hostApplicationHistory.history.result
            : hostItemsHistory[
                props.hostApplicationHistory.index
              ].history.concat(props.hostApplicationHistory.history.result);
        return {
          hostItemsHistory: hostItemsHistory,
          hostApplicationHistory: props.hostApplicationHistory.history.result,
          preloaderStyle: "d-none",
        };
      }
    }
    if (props.graphs) {
      if (props.graphs.result !== state.Graphs) {
        return {
          Graphs: props.graphs.result,
        };
      }
    }
    if (props.items) {
      if (props.items.result !== state.Items) {
        let ids = props.items.result.map((itemId) => {
          return parseInt(itemId.itemid);
        });

        return {
          Items: props.items.result,
          ItemIds: ids,
        };
      }
    }

    if (props.applications) {
      if (props.applications.result !== state.applications) {
        function onlyUnique(value, index, self) {
          return self.indexOf(value) === index;
        }

        let application = props.applications.result.map((app) => {
          return app.name;
        });
        let hostIds = props.applications.result.map((host) => {
          return host.hostid;
        });

        let unique = application.filter(onlyUnique);
        let uniqueHosts = hostIds.filter(onlyUnique);
        return {
          applications: unique,
          idsForHosts: uniqueHosts,
          preloaderStyle: "d-none",
        };
      }
    }

    if (props.itemApplications) {
      if (props.itemApplications.result !== state.itemApplications) {
        function onlyUnique(value, index, self) {
          return self.indexOf(value) === index;
        }
        let itemIds = props.itemApplications.result.map((item) => {
          return item.itemid;
        });

        let fiteredItemsWithValues = props.itemApplications.result.map(
          (item) => {
            return { value_type: item.value_type, items: itemIds };
          }
        );

        let itemNames = props.itemApplications.result.map((itm) => {
          return (
            itm.name + "(" + (itm.units === "bps" ? "Mbps" : itm.units) + ")"
          );
        });

        let unique = itemNames.filter(onlyUnique);

        const itemApplications = props.itemApplications.result;
        const Hosts = state.Hosts;
        let hostItemsHistory = [];

        // Create array of host and items
        for (let r = 0; r < Hosts.length; r++) {
          const currentHost = Hosts[r];
          const hostItems = itemApplications.filter((item) => {
            return item.hostid === currentHost.hostid;
          });

          // if (hostItems.length > 0) {
          //   for (let s = 0; s < hostItems.length; s++) {
          //     hostItems[s]["history"] = null;
          //   }
          // }

          if (hostItems.length > 0) {
            hostItemsHistory.push({
              host: currentHost,
              items: hostItems,
              history: null,
            });
          }
        }

        return {
          hostItemsHistory: hostItemsHistory,
          itemApplications: itemApplications,
          idsForItems: itemIds,
          uniqueItemNames: unique,
          itemsWithValueType: fiteredItemsWithValues,
        };
      }
    }
  }

  onSelectOptionServer = (value, e) => {
    this.handlePreloaderStyle("d-block");
    this.props.loginZabbix(value);
    this.setState({
      server: value,
      base_url: value,
    });
  };

  onSelectOptionHostGroup = (value, e) => {
    this.handlePreloaderStyle("d-block");
    const { token, base_url } = this.state;

    this.setState((prevState) => ({
      prevousHostGroup: prevState.hostGroup,
      hostGroup: value,
      application: [0],
    }));

    // this.setState({
    //   hostGroup: value,
    // });
    this.props.fetchApplications(value, token, base_url);
    this.props.fetchGroupHosts(value, token, base_url);
  };

  onSelectOptionApplication = (value) => {
    const { token, base_url, hostGroup } = this.state;

    this.setState({
      application: value,
    });

    this.props.fetchItemsOfApplication(hostGroup, value.value, token, base_url);
  };

  onSelectOptionGraphs = (value, e) => {
    const { token, base_url } = this.state;
    this.setState({
      graph: value,
    });
    this.props.fetchItems(value, token, base_url);
  };

  showSelectedGraph = () => {
    const { ItemIds, start_date, end_date, token, base_url } = this.state;

    if (start_date === "" && end_date === "") {
      this.setState({
        showGraph: true,
        showExport: true,
      });
    } else {
      this.setState({
        showGraph: true,
        showExport: true,
      });

      console.log({ ItemIds });
      this.props.fetchHistory(
        ItemIds,
        moment(start_date).format("X"),
        moment(end_date).format("X"),
        token,
        base_url
      );
    }
  };

  handleSearchGraph = () => {
    const {
      hostsIds,
      start_date,
      end_date,
      token,
      base_url,
      itemsWithValueType,
      hostItemsHistory,
      server,
      hostGroup,
      application,
    } = this.state;

    if (
      server === "" ||
      hostGroup === "" ||
      application === "" ||
      start_date === "" ||
      end_date === ""
    ) {
      JSON.stringify(Swal.fire("Ensure all fields are filled", "", "error"));
    } else {
      this.handlePreloaderStyle("d-block");

      this.setState({
        showGraph: true,
        showExport: true,
      });

      this.props.fetchHostsHistory(
        hostItemsHistory,
        hostsIds,
        moment(start_date).format("X"),
        moment(end_date).format("X"),
        token,
        base_url,
        itemsWithValueType
      );
    }
  };

  render() {
    const {
      showGraph,
      servers,
      graph,
      hostGroups,
      Hosts,
      Graphs,
      history,
      end_date,
      start_date,
      showExport,
      preloaderStyle,
      Items,
      uniqueItemNames,
      applications,
      itemApplications,
      hostGroup,
      hostItemsHistory,
      application,
    } = this.state;

    console.log(Items);

    const showHostGroups =
      hostGroups instanceof Array
        ? hostGroups.map((hg) => {
            return { label: hg.name, value: hg.groupid };
          })
        : null;

    const showApplications =
      applications instanceof Array
        ? applications.map((hg) => {
            return { label: hg, value: hg };
          })
        : null;

    const showGraphOption =
      Graphs instanceof Array
        ? Graphs.map((hg) => {
            return { label: hg.name, value: hg.graphid };
          })
        : null;

    const graphName =
      Graphs instanceof Array
        ? Graphs.filter((graphname) => graphname.graphid === graph).map(
            (hg) => (
              <h6 className="pl-3 mb-2 mt-2" key={hg.graphid}>
                {hg.name}
              </h6>
            )
          )
        : null;

    return (
      <React.Fragment>
        <Preloader preloaderStyle={preloaderStyle} />

        <Sidebar />

        <div className="content">
          {/* Filter */}
          <div className="mt-4">
            <Navbar title="NOC Reports"/>
          </div>
          <div className="card mb-4">
            <h6 className="pl-3 mb-2 mt-2">Filter NOC Reports</h6>
            <div className="d-flex flex-row justify-content-between flex-wrap align-items-center">
              <Filter
                servers={servers}
                showHostGroups={showHostGroups}
                onSelectOptionServer={this.onSelectOptionServer}
                onSelectOptionHostGroup={this.onSelectOptionHostGroup}
                showApplications={showApplications}
                onSelectOptionApplication={this.onSelectOptionApplication}
                showGraphOption={showGraphOption}
                onSelectOptionGraphs={this.onSelectOptionGraphs}
                start_date={start_date}
                end_date={end_date}
                onChange={this.onChange}
                handleSearchGraph={this.handleSearchGraph}
                showExport={showExport}
                Hosts={Hosts}
                uniqueItemNames={uniqueItemNames}
                itemApplications={itemApplications}
                hostGroup={hostGroup}
                application={application}
              />
              <div className="col-md-3">
                <ReactHTMLTableToExcel
                  id="test-table-xls-button"
                  className="btn btn-success rounded-0 d-flex flex-nowrap align-items-center"
                  table="table-to-xls"
                  filename={
                    moment().format("DD/MM/YYYY H:mm:ss") + " Zabbix.csv"
                  }
                  sheet="tablexls"
                  buttonText="Download to Excel"
                />
              </div>
            </div>
          </div>

          {/* Table */}
          {Hosts.length > 0 && (
            <Table
              Hosts={Hosts}
              Items={Items}
              history={history}
              showGraph={showGraph}
              graphName={graphName}
              itemApplications={itemApplications}
              uniqueItemNames={uniqueItemNames}
              hostItemsHistory={hostItemsHistory}
            />
          )}
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  loginResponse: state.zabbix.loginResponse,
  hostGroups: state.zabbix.hostGroups,
  hosts: state.zabbix.hosts,
  graphs: state.zabbix.graphs,
  items: state.zabbix.items,
  history: state.zabbix.history,
  hostApplicationHistory: state.zabbix.hostHistory,
  applications: state.zabbix.applications,
  itemApplications: state.zabbix.itemApplication,
});

export default connect(mapStateToProps, {
  loginZabbix,
  fetchHostGroups,
  fetchHosts,
  fetchGraph,
  fetchItems,
  fetchHistory,
  fetchHostsHistory,
  fetchApplications,
  fetchItemsOfApplication,
  fetchGroupHosts,
})(MainLayout);
