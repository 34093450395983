import {
    FETCH_CAMPAIGN,
    CREATE_CAMPAIGN,
    UPDATE_CAMPAIGN
} from '../actions/types';

const initialState = {
    campaigns: [],
    campaignsCreated: {},
    campaignsUpdated: {}
}

export default function (state = initialState, action) {
    switch (action.type) {
        case FETCH_CAMPAIGN:
            return {
                ...state,
                campaigns: action.payload
            }
            case CREATE_CAMPAIGN:
                return {
                    ...state,
                    campaignsCreated: action.payload
                }
                case UPDATE_CAMPAIGN:
                    return {
                        ...state,
                        campaignsUpdated: action.payload
                    }
                    default:
                        return state;
    }
}