import React from "react";
import Select from "react-select";

const AddAgent = ({
  first_name,
  last_name,
  agent_phone,
  username,
  saveAgent,
  onChange,
  password,
  email,
  onSelectOptionCounty,
  regions,
  locations,
}) => {
  return (
    <div
      className="modal fade"
      id="addAgent"
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Add Agent
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="">
              <div className="form-group">
                <label htmlFor="exampleInputEmail1">First Name*</label>
                <input
                  type="text"
                  className="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  name="first_name"
                  value={first_name}
                  onChange={onChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="exampleInputPassword1">Last Name</label>
                <input
                  type="text"
                  className="form-control"
                  id="exampleInputPassword1"
                  name="last_name"
                  value={last_name}
                  onChange={onChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="exampleInputPassword1">Phone Number*</label>
                <input
                  type="number"
                  className="form-control"
                  id="exampleInputPassword1"
                  name="agent_phone"
                  value={agent_phone}
                  onChange={onChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="exampleInputPassword1">User Name*</label>
                <input
                  type="text"
                  className="form-control"
                  id="exampleInputPassword1"
                  name="username"
                  value={username}
                  onChange={onChange}
                />
              </div>
              {/* county */}
              <div className="form-group">
                <label htmlFor="hostgroups">Region</label>
                <Select
                  options={regions}
                  placeholder="Select Region"
                  name="region"
                  onChange={(opt, e) => onSelectOptionCounty(opt.value, e)}
                ></Select>
              </div>
              {/* Regions */}
              <div className="form-group">
                <label htmlFor="hostgroups">Location</label>
                <Select
                  options={locations}
                  placeholder="Select Location"
                  name="location"
                  onChange={(opt, e) => onSelectOptionCounty(opt.value, e)}
                ></Select>
              </div>
              <div className="form-group">
                <label htmlFor="exampleInputPassword1">User Email</label>
                <input
                  type="email"
                  className="form-control"
                  id="exampleInputPassword1"
                  name="email"
                  value={email}
                  onChange={onChange}
                />
              </div>
              <div className="form-group d-none">
                <label htmlFor="exampleInputPassword1">Password</label>
                <input
                  type="text"
                  className="form-control"
                  id="exampleInputPassword1"
                  name="password"
                  value={password}
                  onChange={onChange}
                />
              </div>
            </div>
          </div>
          <div className="modal-footer d-flex justify-content-between">
            <button
              type="button"
              className="btn btn-secondary btn-sm"
              data-dismiss="modal"
            >
              Close
            </button>
            <button
              type="button"
              className="btn btn-primary btn-sm"
              onClick={saveAgent}
            >
              Save Agent
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddAgent;
