import {
    POST_SMS,
    SINGLE_MESSAGE,
    GET_ERRORS,
    FETCH_SMS,
    FETCH_SMS_EXPORT
} from '../actions/types';

const initialState = {
    sms: {},
    error: '',
    messages: [],
    allMessages: []
}

export default function (state = initialState, action) {
    switch (action.type) {
        case POST_SMS:
            return {
                sms: action.payload
            }
            case SINGLE_MESSAGE:
                return {
                    singleMessage: action.payload
                }
                case GET_ERRORS:
                    return {
                        error: action.payload
                    }
                    case FETCH_SMS:
                        return {
                            messages: action.payload
                        }
                        case FETCH_SMS_EXPORT:
                            return {
                                allMessages: action.payload
                            }
                            default:
                                return state;
    }
}