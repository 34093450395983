import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Swal from "sweetalert2";

import { List } from "../radUserStatus";

import {
  fetchRadiusStatus,
  deleteFromRadius,
} from "../../actions/customersActions";

import { Error, Preloader, Sidebar, Navbar } from "../common";

class RadUser extends Component {
  constructor() {
    super();
    this.state = {
      radiusStatus: {},
      radacct: [],
      payments: [],
      customer: {},
      disconnect: "",
      errors: "",
      page: 0,
      limit: 10,
      phone: "",
      status: "",
      accesscode: "",
      errorMessage: "",
      assigned: 0,
      inProgress: 0,
      completed: 0,
      deferred: 0,
      preloaderStyle: "d-none",
      currentPage: "list",
      accesscode: "",
      last_name: "",
      agent_phone: "",
      username: "",
      password: "123456",
      email: "",
      addAgentSuccess: "",
    };
  }

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handlePreloaderStyle = (newStyle) => {
    this.setState({ preloaderStyle: newStyle });
  };

  incrementPage = (e) => {
    e.preventDefault();
    const { limit, page, count, accesscode } = this.state;
    const totalPages = Math.ceil(count / limit);

    const newPage = page + 1;
    if (newPage <= totalPages) {
      this.setState({ page: newPage });
      this.fetchRadiusStatus(newPage, limit, accesscode);
    }
  };

  decrementPage = (e) => {
    e.preventDefault();
    const { page, limit, accesscode } = this.state;

    const newPage = page - 1;
    if (newPage >= 0) {
      this.setState({ page: newPage });
      this.fetchRadiusStatus(newPage, limit, accesscode);
    }
  };

  onChangePage = (e) => {
    const { limit, accesscode } = this.state;
    const newPage = parseInt(e.target.value);
    this.setState({ [e.target.name]: newPage });

    this.fetchRadiusStatus(newPage, limit, accesscode);
  };

  fetchRadiusStatus = (page, limit, accesscode) => {
    this.handlePreloaderStyle("d-block");
    this.props.fetchRadiusStatus(page * limit, limit, accesscode);
  };

  componentDidMount() {
    if (!this.props.auth.isAuthenticated) {
      this.props.history.push("/admin");
    }
    this.fetchRadiusStatus(
      this.state.page,
      this.state.limit,
      this.state.accesscode
    );
  }
  saveAgent = (e) => {
    e.preventDefault();
    const {
      accesscode,
      last_name,
      agent_phone,
      username,
      password,
      email,
    } = this.state;
    if (accesscode === "") {
      Swal.fire("Error", "Acess Code required", "error");
    } else {
      this.handlePreloaderStyle("d-block");

      let data = {
        accesscode,
        last_name,
        cell: agent_phone,
        username,
        password,
        email,
      };
      this.props.addAgent(data);
      this.setState({
        accesscode: "",
        last_name: "",
        agent_phone: "",
        username: "",
        email: "",
      });
    }
  };

  static getDerivedStateFromProps(nextProps, state) {
    // console.log({ nextProps, state });
    if (nextProps.radiusStatus !== state.radiusStatus) {
      const newCustomers = nextProps.radiusStatus;
      return {
        radiusStatus: newCustomers,
        preloaderStyle: "d-none",
      };
    }

    if (nextProps.errors.length > 0) {
      return { errorMessage: nextProps.errors, preloaderStyle: "d-none" };
    }

    if (nextProps.addAgentSuccess !== state.addAgentSuccess) {
      return {
        addAgentSuccess: nextProps.addAgentSuccess,
        preloaderStyle: "d-none",
      };
    }

    return null;
  }

  fetchStatementHandler = (customer_id) => {
    this.setState({ currentPage: "statement", preloaderStyle: "d-block" });
  };

  disconnectCustomertHandler = (access_code) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Please confirm this disconnect request",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, disconnect",
      cancelButtonText: "No, I made a mistake",
    }).then((result) => {
      if (result.value) {
        this.setState({ preloaderStyle: "d-block" });
      } else {
        this.setState({ preloaderStyle: "d-none" });
      }
    });
  };

  deleteFromRad = (accesscode) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result) {
        console.log("confirmed");

        this.props.deleteFromRadius(accesscode);
      }
    });
  };

  customersHandler = (newCustomers) => {
    this.setState({
      radiusStatus: newCustomers,
      count: newCustomers.totalCustomers,
    });
  };

  errorMessageHandler = (newError) => {
    this.setState({ errorMessage: newError });
  };

  searchHandler = (accesscode) => {
    console.log(accesscode);
    this.setState({
      accesscode: accesscode,
    });

    this.fetchRadiusStatus(this.state.page, this.state.limit, accesscode);
  };

  render() {
    const {
      radiusStatus,
      limit,
      page,
      errorMessage,
      preloaderStyle,
      accesscode,
      last_name,
      agent_phone,
      username,
      password,
      email,
    } = this.state;

    return (
      <React.Fragment>
        <Preloader preloaderStyle={preloaderStyle} />
        <Sidebar />
        <div className="content">
          {/* <Error message={errorMessage} /> */}
          <div className="mt-4">
            <Navbar title="Customer Status" toggleSearch={this.toggleSearch} />
          </div>
          <List
            page={page}
            limit={limit}
            radiusStatus={radiusStatus}
            incrementPage={this.incrementPage}
            decrementPage={this.decrementPage}
            onChangePage={this.onChangePage}
            searchHandler={this.searchHandler}
            errorMessageHandler={this.errorMessageHandler}
            fetchStatementHandler={this.fetchStatementHandler}
            handlePreloaderStyle={this.handlePreloaderStyle}
            disconnectCustomertHandler={this.disconnectCustomertHandler}
            addFloat={this.props.addFloat}
            accesscode={accesscode}
            last_name={last_name}
            agent_phone={agent_phone}
            username={username}
            saveAgent={this.saveAgent}
            onChange={this.onChange}
            password={password}
            email={email}
            deleteFromRad={this.deleteFromRad}
          />
        </div>
      </React.Fragment>
    );
  }
}

RadUser.propTypes = {
  fetchRadiusStatus: PropTypes.func.isRequired,
  radiusStatus: PropTypes.object.isRequired,
  deleteFromRadius: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  radiusStatus: state.customers.radiusStatus,
  errors: state.errors,
  auth: state.auth,
  addAgentSuccess: state.commissions.addAgentSuccess,
});

export default connect(mapStateToProps, {
  fetchRadiusStatus,
  deleteFromRadius,
})(RadUser);
