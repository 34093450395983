import React, { Component } from "react";
import moment from "moment";
import Select from "react-select";
import ReactHTMLTableToExcel from "react-html-table-to-excel";

class Filter extends Component {
  state = {};
  render() {
    const {
      servers,
      showHostGroups,
      showHosts,
      showGraphOption,
      start_date,
      end_date,
      showExport,
      hostGroup,
      host,
      graph,
      graphName,
      intervals,
      interval
    } = this.props;
    return (
      <React.Fragment>
        <div className="card mb-4">
          <h6 className="pl-3 mb-2 mt-2">Filter</h6>
          <div className="d-flex flex-row justify-content-between flex-wrap align-items-center mb-2">
            {/* server */}
            <div className="form-field mb-3 col-md-3">
              <label htmlFor="hostgroups">Server</label>
              <Select
                options={servers}
                name="server"
                onChange={(opt, e) =>
                  this.props.onSelectOptionServer(opt.value, e)
                }
              ></Select>
            </div>
            {/* hostgroup */}
            <div className="form-field mb-3 col-md-3">
              <label htmlFor="hostgroups">Host Group</label>
              <Select
                options={showHostGroups}
                name="hostGroup"
                value={hostGroup}
                onChange={this.props.onSelectOptionHostGroup}
              ></Select>
            </div>
            {/* hosts */}
            <div className="form-field mb-3 col-md-3">
              <label htmlFor="hostgroups">Host</label>
              <Select
                options={showHosts}
                name="host"
                value={host}
                onChange={this.props.onSelectOptionHosts}
              ></Select>
            </div>
            {/* graphs */}
            <div className="form-field mb-3 col-md-3">
              <label htmlFor="hostgroups">Graph</label>
              <Select
                options={showGraphOption}
                name="graph"
                value={graph}
                onChange={this.props.onSelectOptionGraphs}
              ></Select>
            </div>

             {/* Intervals */}
             <div className="form-field mb-3 col-md-3">
              <label htmlFor="hostgroups">Interval</label>
              <Select
                options={intervals}
                name="interval"
                value={interval}
                onChange={this.props.onSelectOptionInterval}
              ></Select>
            </div>

            {/* filter by date */}
            {/* start */}
            <div className="form-group col-md-3">
              <label htmlFor="start">Start Date and Time</label>
              <input
                type="datetime-local"
                name="start_date"
                className="form-control"
                placeholder="Select date"
                value={start_date}
                onChange={this.props.onChange}
              />
            </div>
            {/* end */}
            <div className="form-group col-md-3">
              <label htmlFor="end">End Date and Time</label>
              <input
                type="datetime-local"
                className="form-control"
                placeholder="Receiver's phone"
                aria-label="Receiver's phone with two button addons"
                aria-describedby="button-addon4"
                name="end_date"
                value={end_date}
                onChange={this.props.onChange}
              />
            </div>
  
            {/* submit */}
            <div className="flex col-md-3 justify-content-center">
              <button
                className="btn btn-primary"
                onClick={this.props.handleSearchGraph}
              >
                Apply
              </button>
            </div>
            {/* Export */}
            <div className="col-md-3">
              {showExport !== undefined && (
                <ReactHTMLTableToExcel
                  id="test-table-xls-button"
                  className="btn btn-success rounded-0 d-flex flex-nowrap align-items-center"
                  table="table1-to-xls"
                  filename={
                    graphName + " " + moment().format("DD/MM/YYYY H:mm:ss")
                  }
                  sheet="tablexls"
                  buttonText="Download to Excel"
                />
              )}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Filter;
