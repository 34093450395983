import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import moment from "moment";
import { CSVLink } from "react-csv";
import classnames from "classnames";

import {
  fetchCustomers,
  fetchCustomersAdmin
} from "../../actions/agentsActions";
import { fetchCommissionsExport } from "../../actions/commissionsActions";

import { getStartDate, getEndDate } from "../../utilities/momentdate";

import AddNewCustomerModal from "./AddNewCustomerModal";
import { Error, Preloader } from "../common";
import SearchLocks from "../commissions/SearchLocks";

class Commissions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      commissions: [],
      commissionsExport: [],
      errorMessage: [],
      startDate: getStartDate(),
      endDate: getEndDate(),
      focusedInput: null,
      page: 0,
      limit: 10,
      count: 0,
      message: "",
      preloaderStyle: "d-none",
      phone: "",
      agent_phone: "",
      exportTitle: Math.floor(Date.now() / 1000) + " Locks.csv"
    };
  }

  onChangeNumber = e => {
    this.setState({
      [e.target.name]: parseInt(e.target.value)
    });
  };

  handleDateChange = ({ startDate, endDate }) =>
    this.setState({
      startDate: moment(startDate),
      endDate: moment(endDate)
    });

  handleFocusChange = focusedInput => this.setState({ focusedInput });

  isOutsideRange = () => false;

  handleSearch = (e, phone, agent_phone, startDate, endDate) => {
    e.preventDefault();
    this.setState({
      phone: phone,
      agent_phone: agent_phone,
      startDate: moment(startDate),
      endDate: moment(endDate)
    });
    const { page, limit } = this.state;
    this.fetchCustomers(page, limit, phone, agent_phone, startDate, endDate);
  };

  handlePreloaderStyle = newStyle => {
    this.setState({
      preloaderStyle: newStyle
    });
  };

  handleAddCustomer = message => {
    this.setState({
      message,
      errorMessage: ""
    });

    const { page, limit, phone, agent_phone, startDate, endDate } = this.state;
    this.fetchCustomers(page, limit, phone, agent_phone, startDate, endDate);
  };

  handleCloseSearch = () => {
    this.setState({
      phone: "",
      agent_phone: ""
    });
  };

  incrementPage = e => {
    e.preventDefault();
    const {
      limit,
      page,
      count,
      phone,
      agent_phone,
      startDate,
      endDate
    } = this.state;
    const totalPages = Math.ceil(count / limit);

    const newPage = page + 1;
    if (newPage <= totalPages) {
      this.setState({ page: newPage });
      this.fetchCustomers(
        newPage,
        limit,
        phone,
        agent_phone,
        startDate,
        endDate
      );
    }
  };

  decrementPage = e => {
    e.preventDefault();
    const { limit, page, phone, agent_phone, startDate, endDate } = this.state;

    const newPage = page - 1;
    if (newPage >= 0) {
      this.setState({ page: newPage });
      this.fetchCustomers(
        newPage,
        limit,
        phone,
        agent_phone,
        startDate,
        endDate
      );
    }
  };

  onChangePage = e => {
    const newPage = parseInt(e.target.value);
    this.setState({ [e.target.name]: newPage });
    const { phone, agent_phone, startDate, endDate } = this.state;
    this.fetchCustomers(
      newPage,
      this.state.limit,
      phone,
      agent_phone,
      startDate,
      endDate
    );
  };

  fetchCustomers = (page, limit, phone, agent_phone, startDate, endDate) => {
    this.props.handlePreloaderStyle("d-block");
    if (typeof startDate !== "undefined") {
      startDate = moment(startDate).format("YYYY-MM-DD");
    } else {
      startDate = "";
    }
    if (typeof endDate !== "undefined") {
      endDate = moment(endDate).format("YYYY-MM-DD");
    } else {
      endDate = "";
    }
    if (localStorage.agentJwtToken) {
      this.props.fetchCustomers(page * limit, limit);
    } else {
      this.props.fetchCustomersAdmin(page * limit, limit, phone, agent_phone);
      this.props.fetchCommissionsExport(startDate, endDate, phone, agent_phone);
    }
  };

  componentDidMount() {
    const { page, limit, phone, agent_phone, startDate, endDate } = this.state;
    this.fetchCustomers(page, limit, phone, agent_phone, startDate, endDate);
  }

  componentWillReceiveProps(nextProps) {
    this.props.handlePreloaderStyle("d-none");
    if (nextProps.commissions) {
      const newCommissions = nextProps.commissions;
      this.setState({
        commissions: newCommissions.commissions,
        count: newCommissions.totalCommissions
      });
    }
    if (nextProps.commissionsExport) {
      this.setState({
        commissionsExport: nextProps.commissionsExport
      });
    }

    if (nextProps.errors.length > 0) {
      this.props.handlePreloaderStyle("d-none");
      this.setState({ errorMessage: nextProps.errors });
    }
  }

  errorMessageHandler = newError => {
    this.setState({ errorMessage: newError });
  };

  render() {
    const {
      commissions,
      commissionsExport,
      errorMessage,
      page,
      limit,
      count,
      message,
      preloaderStyle,
      phone,
      agent_phone,
      exportTitle,
      focusedInput,
      startDate,
      endDate
    } = this.state;

    let commissionCount = page * limit + 1;
    let pages;
    let totalPages = 0;

    if (count > 0) {
      totalPages = Math.ceil(count / limit);

      pages = Array.from(Array(totalPages).keys()).map(pageNumber => (
        <option key={pageNumber} value={pageNumber}>
          {pageNumber + 1}
        </option>
      ));
    } else {
      pages = null;
    }

    let createCustomer;
    let tableHead;
    let commissionsData;

    if (localStorage.agentJwtToken) {
      createCustomer = (
        <React.Fragment>
          <button
            type="button"
            className="btn btn-primary btn-sm"
            data-toggle="modal"
            data-target="#lockCustomer"
          >
            Create New Customer
          </button>
          <AddNewCustomerModal
            handlePreloaderStyle={this.props.handlePreloaderStyle}
            handleAddCustomer={this.handleAddCustomer}
          />
        </React.Fragment>
      );

      tableHead = (
        <tr>
          <th>#</th>
          <th>Phone Number</th>
          <th>Created</th>
        </tr>
      );

      if (commissions) {
        commissionsData = commissions.map(commission => (
          <tr key={commission.mpesa_customer_register_id}>
            <td>{commissionCount++}</td>
            <td>{commission.phone_number}</td>
            <td>{moment(commission.created_at).format("YYYY-MM-DD")}</td>
          </tr>
        ));
      }
    } else {
      createCustomer = null;

      tableHead = (
        <tr>
          <th>#</th>
          <th>Customer</th>
          <th>Phone Number</th>
          <th>Agent</th>
          <th>Agent Phone</th>
          <th>Legacy Paid</th>
          <th>Created</th>
        </tr>
      );

      if (commissions) {
        commissionsData = commissions.map(commission => (
          <tr key={commission.mpesa_customer_register_id}>
            <td>{commissionCount++}</td>
            <td>{commission.full_name}</td>
            <td>{commission.phone_number}</td>
            <td>
              {commission.agent_firstName} {commission.agent_lastName}
            </td>
            <td>{commission.agent_phoneNumber}</td>
            <td>{commission.legacy_paid === 1 ? "Yes" : "No"}</td>
            <td>{moment(commission.created_at).format("YYYY-MM-DD")}</td>
          </tr>
        ));
      }
    }
    let messageDisplay;
    if (message !== "") {
      messageDisplay = (
        <div className="alert alert-success">
          <p>{message}</p>
        </div>
      );
    } else {
      messageDisplay = null;
    }

    let exportCommissionsLink;
    if (commissionsExport.length > 0) {
      exportCommissionsLink = (
        <CSVLink
          className="btn btn-sm btn-success"
          data={commissionsExport}
          target="_blank"
          filename={exportTitle}
        >
          Export
        </CSVLink>
      );
    } else {
      exportCommissionsLink = null;
    }

    return (
      <React.Fragment>
        <Preloader preloaderStyle={preloaderStyle} />
        <div className="container-fluid">
          {errorMessage.length > 0 && <Error message={errorMessage} />}
          {messageDisplay}
          <div className="row">
            <div
              className={classnames(
                localStorage.jwtToken ? "col-md-8" : "col-md-12"
              )}
            >
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title">Locks</h5>
                  {createCustomer}
                  <div className="text-right">{exportCommissionsLink}</div>
                  <div className="table-responsive">
                    <table className="table table-bordered table-striped table-condensed table-sm">
                      <thead>{tableHead}</thead>
                      <tbody>{commissionsData}</tbody>
                    </table>
                  </div>
                  <div className="card-footer">
                    <div className="d-flex flex-row-reverse">
                      <form noValidate onSubmit={this.incrementPage}>
                        <button className="btn btn-rounded" type="submit">
                          <i className="fas fa-chevron-right" />
                        </button>
                      </form>
                      <form noValidate onSubmit={this.decrementPage}>
                        <button className="btn btn-rounded mx-2" type="submit">
                          <i className="fas fa-chevron-left" />
                        </button>
                      </form>
                      <span className="font-weight-normal">
                        of {totalPages}
                      </span>
                      <select
                        name="page"
                        id="page"
                        className="form-control mr-2"
                        value={page}
                        onChange={this.onChangePage}
                        error={errorMessage}
                      >
                        {pages}
                      </select>
                      <span className="font-weight-normal mr-2">Page</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {localStorage.jwtToken && (
              <div className="col-md-4">
                <SearchLocks
                  phone={phone}
                  agent_phone={agent_phone}
                  startDate={startDate}
                  endDate={endDate}
                  focusedInput={focusedInput}
                  startDateId="startDateLocks"
                  endDateId="endDateLocks"
                  handleSearch={this.handleSearch}
                  handleCloseSearch={this.handleCloseSearch}
                  onChangeNumber={this.onChangeNumber}
                  handleDateChange={this.handleDateChange}
                  handleFocusChange={this.handleFocusChange}
                  isOutsideRange={this.isOutsideRange}
                />
              </div>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

Commissions.propTypes = {
  fetchCustomers: PropTypes.func.isRequired,
  fetchCustomersAdmin: PropTypes.func.isRequired,
  commissions: PropTypes.object.isRequired,
  handlePreloaderStyle: PropTypes.func.isRequired,
  fetchCommissionsExport: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  commissions: state.commissions.commissions,
  errors: state.errors,
  commissionsExport: state.commissionsExport.export
});

export default connect(
  mapStateToProps,
  { fetchCustomers, fetchCustomersAdmin, fetchCommissionsExport }
)(Commissions);
