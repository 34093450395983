import React, { Component } from "react";
import { connect } from "react-redux";
import { logoutUser } from "../../actions/authActions";

class Navbar extends Component {
  state = {};

  onLogoutClick = (e) =>{
    e.preventDefault();
    let redirectLink;
    if (localStorage.agentJwtToken) {
      redirectLink = "/";
    } else {
      redirectLink = "/";
    }
    let personnel = {
      personnel_id: this.props.auth.personnel.personnel_id,
    };
    this.props.logoutUser(personnel);
    window.location.href = redirectLink;
  }

  render() {
    const { title } = this.props;
    return (
      <nav className="navbar navbar-expand-lg navbar-light">
        <div className="container-fluid">
          <a className="navbar-brand" href="#">
            {title}
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <div className="d-flex ml-auto flex-row">
              <button
                className="btn btn-outline-primary btn-sm mr-2"
                onClick={this.props.toggleSearch}
              >
                <i className="fas fa-search"></i>
              </button>
              {/* user */}
              <div className="dropdown d-flex flex-row align-items-center">
                <div
                  className="d-flex flex-row align-items-center justify-content-start dropdown-toggle"
                  id="dropdownMenuButton1"
                  data-toggle="dropdown"
                  aria-expanded="false"
                >
                  <i className="fas fa-user px-2"></i>
                  <div href="#contact" className="pr-4">
                    {this.props.auth.personnel.personnel_fname}
                  </div>
                </div>
                <div
                  class="dropdown-menu"
                  aria-labelledby="dropdownMenuButton1"
                >
                
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={this.onLogoutClick}
                  >
                    <span class="dropdown-item" href="#">
                      Logout
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  agentAuth: state.agentAuth,
});

export default connect(mapStateToProps, { logoutUser })(Navbar);
