import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../../actions/authActions";

import { NavLink, HyperLink } from "../common";
import { Link } from "react-router-dom";

class Navbar extends Component {
  
  onLogoutClick(e) {
    e.preventDefault();
    let redirectLink;
    if (localStorage.agentJwtToken) {
      redirectLink = "/";
    } else {
      redirectLink = "/";
    }
    let personnel = {
      personnel_id: this.props.auth.personnel.personnel_id,
    };
    this.props.logoutUser(personnel);
    window.location.href = redirectLink;
  }

  render() {
    const { personnel } = this.props.auth;
    const { agent } = this.props.agentAuth;

    const agentLinks = <NavLink to="/tasks" name="Tasks" />;
    const merchantLinks = (
      <React.Fragment>
        <NavLink to="/locks" name="Locks" />
        <NavLink to="/commissions" name="Commissions" />
      </React.Fragment>
    );
    const smsLinks = (
      <React.Fragment>
        <li className="nav-item dropdown">
          <a
            className="nav-link dropdown-toggle"
            href="#"
            id="navbarDropdown"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            SMS
          </a>
          <div className="dropdown-menu" aria-labelledby="navbarDropdown">
            <Link className="dropdown-item" to="/singleSms">
              Single SMS
            </Link>
            <Link className="dropdown-item" to="/sms">
              Bulk SMS
            </Link>
            {/* <Link className="dropdown-item" to="/Messages">
              Messages
            </Link> */}
            <div className="dropdown-divider"></div>
          </div>
        </li>
        <NavLink to="/customers" name="Customers" />
        <li className="nav-item dropdown">
          <a
            className="nav-link dropdown-toggle"
            href="#"
            id="navbarDropdown"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            HotSpot
          </a>
          <div className="dropdown-menu" aria-labelledby="navbarDropdown">
            <Link className="dropdown-item" to="/checkAuth">
              Check Customer in Auth
            </Link>
            <Link className="dropdown-item" to="/radUser">
              Check Customer Status
            </Link>
            <Link className="dropdown-item" to="/payments">
              Customer Payments
            </Link>
            <div className="dropdown-divider"></div>
          </div>
        </li>
      </React.Fragment>
    );

    const nockLinks = (
      <React.Fragment>
        <li className="nav-item dropdown">
          <a
            className="nav-link dropdown-toggle"
            href="#"
            id="navbarDropdown"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            NOC
          </a>
          <div className="dropdown-menu" aria-labelledby="navbarDropdown">
            <Link className="dropdown-item" to="/cst_noc">
              CST NOC
            </Link>
            <Link className="dropdown-item" to="/noc_report">
              NOC Reports
            </Link>
            <div className="dropdown-divider"></div>
          </div>
        </li>
      </React.Fragment>
    );
    const adminLinks = (
      <React.Fragment>
        {/* <NavLink to="/dashboard" name="Telesales Dashboard" /> */}
        <NavLink to="/personnel" name="Personnel" />
        <li className="nav-item dropdown">
          <a
            className="nav-link dropdown-toggle"
            href="#"
            id="navbarDropdown"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            SMS
          </a>
          <div className="dropdown-menu" aria-labelledby="navbarDropdown">
            <Link className="dropdown-item" to="/singleSms">
              Single SMS
            </Link>
            <Link className="dropdown-item" to="/sms">
              Bulk SMS
            </Link>
            {/* <Link className="dropdown-item" to="/Messages">
              Messages
            </Link> */}
            <div className="dropdown-divider"></div>
          </div>
        </li>

        <NavLink to="/customers" name="Customers" />
        {/* <NavLink to="/agents" name="Agents" /> */}
        <li className="nav-item dropdown">
          <a
            className="nav-link dropdown-toggle"
            href="#"
            id="navbarDropdown"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            Agents
          </a>
          <div className="dropdown-menu" aria-labelledby="navbarDropdown">
            <Link className="dropdown-item" to="/agents">
              Agents
            </Link>
            <Link className="dropdown-item" to="/agentsPayments">
              Float Payments
            </Link>

            <div className="dropdown-divider"></div>
          </div>
        </li>
        {/* <NavLink to="/disconnect" name="Disconnect Portal" /> */}
        <li className="nav-item dropdown">
          <a
            className="nav-link dropdown-toggle"
            href="#"
            id="navbarDropdown"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            NOC
          </a>
          <div className="dropdown-menu" aria-labelledby="navbarDropdown">
            <Link className="dropdown-item" to="/cst_noc">
              CST NOC
            </Link>
            <Link className="dropdown-item" to="/noc_report">
              NOC Reports
            </Link>
            <div className="dropdown-divider"></div>
          </div>
        </li>
        <li className="nav-item dropdown">
          <a
            className="nav-link dropdown-toggle"
            href="#"
            id="navbarDropdown"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            HotSpot
          </a>
          <div className="dropdown-menu" aria-labelledby="navbarDropdown">
            <Link className="dropdown-item" to="/checkAuth">
              Check Customer in Auth
            </Link>
            <Link className="dropdown-item" to="/radUser">
              Check Customer Status
            </Link>
            <Link className="dropdown-item" to="/payments">
              Customer Payments
            </Link>
            <div className="dropdown-divider"></div>
          </div>
        </li>
        <NavLink to="/announcements" name="Announcements" />

        {/* <NavLink to="/dashboard" name="Commission Dashboard" />
        <NavLink to="/locks" name="Locks" />
        <NavLink to="/campaigns" name="Campaigns" />
        <NavLink to="/commissions" name="Commissions" />
        <NavLink to="/announcements" name="Announcements"/> */}
      </React.Fragment>
    );
    return (
      <nav className="navbar navbar-expand-sm navbar-light bg-white border-bottom">
        <div className="navbar-brand" href="#">
          <img
            src={require("../../assets/img/mawingu.png")}
            alt="Mawingu Logo"
            className="img-fluid"
          />
        </div>
        <button
          className="navbar-toggler d-lg-none"
          type="button"
          data-toggle="collapse"
          data-target="#collapsibleNavId"
          aria-expanded="false"
          aria-controls="collapsibleNavId"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" />
        </button>
        <div className="container">
          <div className="collapse navbar-collapse" id="collapsibleNavId">
            <ul className="navbar-nav mr-auto mt-2 mt-lg-0">
              {localStorage.agentJwtToken
                ? merchantLinks
                : personnel.personnel_type_id === 1
                ? adminLinks
                : personnel.personnel_type_id === 2
                ? agentLinks
                : personnel.personnel_type_id === 5
                ? nockLinks
                : personnel.personnel_type_id === 3 && smsLinks}
            </ul>
            <div className="my-2 my-lg-0">
              <div className="nav-item dropdown">
                <div
                  className="nav-link dropdown-toggle"
                  id="userAccount"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  {/* <i className="fa fa-user" />{agents.agent_firstName} */}
                  <i className="fa fa-user" />{" "}
                  {localStorage.agentJwtToken
                    ? agent.firstName
                    : personnel.personnel_fname}
                </div>
                <div className="dropdown-menu" aria-labelledby="userAccount">
                  <HyperLink
                    className="dropdown-item"
                    to="/resetPassword"
                    name="Change Password"
                  />
                  <HyperLink
                    className="dropdown-item"
                    to=""
                    name="Logout"
                    onClick={this.onLogoutClick.bind(this)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    );
  }
}

Navbar.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  agentAuth: state.agentAuth,
});

export default connect(mapStateToProps, { logoutUser })(Navbar);
