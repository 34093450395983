import {
  GET_ERRORS,
  FETCH_ISP_CUSTOMERS,
  FETCH_CUSTOMER_STATEMENT,
  DISCONNECT_CUSTOMER,
  CHANGE_ACCESS_CODE,
  SAVE_TO_AUTH_TABLE,
  FETCH_RADCHECK,
  FETCH_RADIUS_STATUS,
  DELETE_FROM_RADIUS,
  FETCH_CUSTOMER_PAYMENTS,
  FETCH_CUSTOMER_PAYMENTS_EXPORT,
  FETCH_CUSTOMER_PAYMENTS_EXPORT_CREATED,
} from "./types";

import { axios, createError } from "../utilities";
import Swal from "sweetalert2";

const headers = {
  Accept: "application/json",
  "Content-Type": "application/json",
};

export const fetchCustomers =
  (page, limit, phone, status, accesscode) => (dispatch) => {
    let base_url = `https://admin.mawingunetworks.com`;

    let url = `customers/all?page=${page}&limit=${limit}`;
    if (phone !== "") {
      url += `&phone=${phone}`;
    }
    if (status !== "") {
      url += `&status=${status}`;
    }
    if (accesscode !== "") {
      url += `&accesscode=${accesscode}`;
    }

    axios({
      transformRequest: [
        (data, headers) => {
          delete headers.common.Authorization;
          return data;
        },
      ],
      method: "get",
      baseURL: base_url,
      url,
      headers,
    })
      .then((customers) => {
        // console.log(customers.data.items);
        dispatch({
          type: FETCH_ISP_CUSTOMERS,
          payload: customers.data,
        });
        if (customers.data.items.length === 0) {
          Swal.fire("Error", "Customer not found", "error");
        }
      })
      .catch((err) => {
        console.log(err.response);
        dispatch(createError(err, GET_ERRORS));
      });
  };

export const fetchCustomerStatement = (customer_id) => (dispatch) => {
  let base_url = `https://admin.mawingunetworks.com`;

  let url = `customers/statement`;
  if (customer_id !== "") {
    url += `?customer_id=${customer_id}`;

    axios({
      transformRequest: [
        (data, headers) => {
          delete headers.common.Authorization;
          return data;
        },
      ],
      method: "get",
      baseURL: base_url,
      url,
      headers,
    })
      .then((statement) => {
        console.log(statement.data);
        dispatch({
          type: FETCH_CUSTOMER_STATEMENT,
          payload: statement.data,
        });
      })
      .catch((err) => {
        // console.log(err.response);
        dispatch(createError(err, GET_ERRORS));
      });
  } else {
    dispatch(
      createError(
        {
          error: "Customer id is required",
        },
        GET_ERRORS
      )
    );
  }
};

export const disconnectCustomer = (access_code) => (dispatch) => {
  let base_url = `https://admin.mawingunetworks.com`;

  let url = `radius/disconnectSingle`;
  if (access_code !== "") {
    url += `?access_code=${access_code}`;

    axios({
      transformRequest: [
        (data, headers) => {
          delete headers.common.Authorization;
          return data;
        },
      ],
      method: "get",
      baseURL: base_url,
      url,
      headers,
    })
      .then((customers) => {
        console.log(customers.data);
        dispatch({
          type: DISCONNECT_CUSTOMER,
          payload: customers.data,
        });
      })
      .catch((err) => {
        console.log(err.response)
        dispatch(createError(err, GET_ERRORS));
      });
  } else {
    dispatch(
      createError(
        {
          error: "Customer id is required",
        },
        GET_ERRORS
      )
    );
  }
};

export const changeAccessCode = (accesscode) => (dispatch) => {
  let url = `customers/changeaccesscode`;
  if (accesscode !== "") {
    axios({
      method: "post",
      baseURL: "https://admin.mawingunetworks.com",
      url,
      data: {
        accesscode,
      },
    })
      .then((customers) => {
        Swal.fire("Changed", "Access Code Changed Successful", "success");

        dispatch({
          type: CHANGE_ACCESS_CODE,
          payload: customers.data,
        });
      })
      .catch((err) => {
        dispatch(createError(err, GET_ERRORS));
      });
  } else {
    dispatch(
      createError(
        {
          error: "Customer id is required",
        },
        GET_ERRORS
      )
    );
  }
};

export const saveToAuthTable = (accesscode) => (dispatch) => {
  let url = `customers/addRadcheck`;
  console.log(accesscode);
  if (accesscode !== "") {
    axios({
      method: "post",
      baseURL: "https://admin.mawingunetworks.com",
      url,
      data: accesscode,
    })
      .then((customers) => {
        Swal.fire("Success", customers.data.message, "success");
        dispatch({
          type: SAVE_TO_AUTH_TABLE,
          payload: customers.data,
        });
      })
      .catch((err) => {
        console.log(err.response);
        Swal.fire("Error", err.response.data.error, "error");
        dispatch(createError(err, GET_ERRORS));
      });
  } else {
    dispatch(
      createError(
        {
          error: "Customer id is required",
        },
        GET_ERRORS
      )
    );
  }
};
export const fetchFromRad = (page, limit, accesscode) => (dispatch) => {
  let url = `customers/radcheck?page=${page}&limit=${limit}`;

  if (accesscode !== "") {
    url += `&access_code=${accesscode}`;
    axios({
      method: "get",
      baseURL: `https://admin.mawingunetworks.com`,
      url,
      // data: { accesscode },
    })
      .then((customers) => {
        console.log(customers.data);
        dispatch({
          type: FETCH_RADCHECK,
          payload: customers.data,
        });
      })
      .catch((err) => {
        console.log(err.response.data);
        Swal.fire("Error", err.response.data.error, "error");
        dispatch(createError(err, GET_ERRORS));
      });
  } else {
    dispatch(
      createError(
        {
          error: "Customer id is required",
        },
        GET_ERRORS
      )
    );
  }
};

export const fetchRadiusStatus = (page, limit, accesscode) => (dispatch) => {
  let url = `customers/radusergroup?page=${page}&limit=${limit}`;

  if (accesscode !== "") {
    url += `&accesscode=${accesscode}`;
    axios({
      method: "get",
      baseURL: `https://admin.mawingunetworks.com`,
      url,
      // data: { accesscode },
    })
      .then((customers) => {
        console.log(customers.data);
        dispatch({
          type: FETCH_RADIUS_STATUS,
          payload: customers.data,
        });
      })
      .catch((err) => {
        Swal.fire("Error", err.response.data.error, "error");
        dispatch(createError(err, GET_ERRORS));
      });
  } else {
    dispatch(
      createError(
        {
          error: "Customer id is required",
        },
        GET_ERRORS
      )
    );
  }
};

export const deleteFromRadius = (accesscode) => (dispatch) => {
  console.log(accesscode);
  console.log("its getting here");
  let url = `customers/radusergroup?accesscode=${accesscode}`;

  if (accesscode !== "") {
    axios({
      method: "delete",
      baseURL: `https://admin.mawingunetworks.com`,
      url,
      // data: { accesscode },
    })
      .then((customers) => {
        console.log(customers.data);
        Swal.fire("Success", customers.data.message, "success");
        dispatch({
          type: DELETE_FROM_RADIUS,
          payload: customers.data,
        });
      })
      .catch((err) => {
        console.log(err.response.data);
        Swal.fire("Error", err.response.data.error, "error");
        dispatch(createError(err, GET_ERRORS));
      });
  } else {
    dispatch(
      createError(
        {
          error: "Customer id is required",
        },
        GET_ERRORS
      )
    );
  }
};

// customers payments
export const fetchPayments =
  (page, limit, phone, mpesaReceipt) => (dispatch) => {
    let url = `payments/?page=${page}&limit=${limit}`;

    if (phone !== "") {
      url += `&phone=${phone}`;
    }
    if (mpesaReceipt !== "") {
      url += `&mpesaReceipt=${mpesaReceipt}`;
    }

    axios({
      method: "get",
      baseURL: `https://admin.mawingunetworks.com`,
      url,
    })
      .then((response) => {
        dispatch({
          type: FETCH_CUSTOMER_PAYMENTS,
          payload: response.data,
        });
        if (response.data.items.length === 0) {
          Swal.fire("Error", "Customer not found", "error");
        }
      })
      .catch((err) => {
        dispatch(
          createError(
            {
              error: "Ooops something went wrong",
            },
            GET_ERRORS
          )
        );
      });
  };

// customers payments export validated
export const fetchPaymentsExport = (date) => (dispatch) => {
  let url = `payments/exportValidated`;

  if (date !== "") {
    url += `?date=${date}`;
  }

  axios({
    method: "get",
    baseURL: `https://admin.mawingunetworks.com`,
    url,
  })
    .then((response) => {
      dispatch({
        type: FETCH_CUSTOMER_PAYMENTS_EXPORT,
        payload: response.data,
      });
    })
    .catch((err) => {
      dispatch(
        createError(
          {
            error: "Ooops something went wrong",
          },
          GET_ERRORS
        )
      );
    });
};

// customers payments export created
export const fetchPaymentsExportCreated = (date) => (dispatch) => {
  let url = `payments/exportCreated`;

  if (date !== "") {
    url += `?date=${date}`;
  }

  axios({
    method: "get",
    baseURL: `https://admin.mawingunetworks.com`,
    url,
  })
    .then((response) => {
      // console.log(response.data);
      dispatch({
        type: FETCH_CUSTOMER_PAYMENTS_EXPORT_CREATED,
        payload: response.data,
      });
    })
    .catch((err) => {
      dispatch(
        createError(
          {
            error: "Ooops something went wrong",
          },
          GET_ERRORS
        )
      );
    });
};
