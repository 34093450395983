import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import LoginTextFieldGroup from "../common/LoginTextFieldGroup";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { resetPersonnelPassowrd } from "../../actions/authActions";

class ResetPassword extends Component {
  constructor() {
    super();
    this.state = {
      phone: "",
      password: "",
      confirmPassword: "",
      errors: {}
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.auth.reset) {
      this.props.history.push("/");
    }

    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }
  }

  onSubmit(e) {
    e.preventDefault();
    if (this.state.password !== this.state.confirmPassword) {
      this.setState({ errors: { password: "Passwords do not match" } });
    } else {
      const personnel = {
        phone: this.state.phone,
        password: this.state.password
      };

      this.props.resetPersonnelPassowrd(personnel, this.props.history);
    }
  }

  render() {
    const { errors } = this.state;
    return (
      <div className="mt-5 d-flex justify-content-center">
        <div className="mt-5 p-3 login-container shadow">
          <div className="login-header">
            <img
              src={require("../../assets/img/mawingu.png")}
              alt="Mawingu Logo"
            />
            <h1 className="my-3">Reset Password</h1>
          </div>
          <div className="login-body">
            <form noValidate onSubmit={this.onSubmit}>
              <LoginTextFieldGroup
                icon="fa fa-phone"
                type="phone"
                name="phone"
                value={this.state.phone}
                onChange={this.onChange}
                id="phone"
                error={errors.phone}
                placeholder="Enter your phone"
                style={{ padding: "10px", fontSize: "17px" }}
              />
              <LoginTextFieldGroup
                icon="fa fa-lock"
                type="password"
                name="password"
                value={this.state.password}
                onChange={this.onChange}
                id="password"
                error={errors.password}
                placeholder="Enter your password"
                style={{ padding: "10px", fontSize: "17px" }}
              />
              <LoginTextFieldGroup
                icon="fa fa-lock"
                type="password"
                name="confirmPassword"
                value={this.state.confirmPassword}
                onChange={this.onChange}
                id="password"
                error={errors.password}
                placeholder="Confirm password"
                style={{ padding: "10px", fontSize: "17px" }}
              />
              <button type="submit" className="btn btn-primary">
                Reset Password
              </button>
              <div className="text-center mt-3">
                <Link to="/" style={{ color: "white" }}>
                  Login
                </Link>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

ResetPassword.propTypes = {
  resetPersonnelPassowrd: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    auth: state.auth,
    errors: state.auth.resetErrors,
  };
};

export default connect(
  mapStateToProps,
  { resetPersonnelPassowrd }
)(withRouter(ResetPassword));
