import React from "react";
import Moment from "moment";

import { Aside, Select, AddFloat, AddAgent, EditAgent } from ".";

const List = ({
  page,
  limit,
  agents,
  incrementPage,
  decrementPage,
  onChangePage,
  searchHandler,
  errorMessageHandler,
  addFloat,
  loggedInUser,
  first_name,
  last_name,
  agent_phone,
  username,
  saveAgent,
  onChange,
  password,
  email,
  onSelectOptionCounty,
  locations,
  editLocations,
  regions,
  location,
  region,
  editAgent,
  changeFreeInternetUserName,
}) => {
  console.log(locations);
  let customerCount = page * limit + 1;
  let ispAgents;

  let pages;
  let totalPages = 0;
  let assigned = 0;
  let inProgress = 0;
  let completed = 0;
  let deferred = 0;
  let count = agents.rows ? agents.rows : 0;

  if (count > 0) {
    totalPages = Math.ceil(count / limit);

    const allPages = Array.from(Array(totalPages).keys());
    if (allPages.length > 0) {
      pages = allPages.map((pg) => (
        <option key={pg} value={pg}>
          {pg + 1}
        </option>
      ));
    } else {
      pages = null;
    }
  } else {
    pages = null;
  }

  if (agents.items) {
    if (agents.items instanceof Array) {
      ispAgents = agents.items.map((agent) => (
        <tr key={agent.id}>
          <td>{customerCount++}</td>
          <td>{agent.first_name}</td>
          <td>{agent.last_name}</td>
          <td>{agent.cell}</td>
          <td>{agent.FreeInernetUsername}</td>
          <td>{agent.id}</td>
          {agent.nas_id !== null ? (
            editLocations
              .filter((location) => location.id === agent.nas_id)
              .map((agnL) => (
                <React.Fragment>
                  <td>{agnL.description}</td>
                  <td>{agnL.shortname}</td>
                </React.Fragment>
              ))
          ) : (
            <React.Fragment>
              <td></td>
              <td></td>
            </React.Fragment>
          )}
          {/* <td>
            <Select
              customerName={agent.first_name + " " + agent.last_name}
              customer_id={agent.id}
              customer_status_id={2}
              handlePreloaderStyle={handlePreloaderStyle}
              onSubmit={fetchStatementHandler}
              buttonTitle="View Statement"
              buttonClass="btn-success"
            />
          </td> */}
          <td>
            <AddFloat
              addFloat={addFloat}
              loggedInUser={loggedInUser}
              id={agent.id}
              agentFirstName={agent.first_name}
              agentLastName={agent.last_name}
            />
          </td>
          <td>
            <button
              className="btn btn-sm btn-success"
              onClick={() =>
                changeFreeInternetUserName(agent.FreeInernetUsername)
              }
            >
              Change AccessCode
            </button>
          </td>
          {agent.nas_id !== null ? (
            editLocations
              .filter((location) => location.id === agent.nas_id)
              .map((agnL) => (
                <React.Fragment>
                  <td>
                    <button
                      className="btn btn-info btn-sm"
                      data-toggle="modal"
                      data-target={"#editAgent" + agent.id}
                    >
                      Edit Agent
                    </button>
                    <EditAgent
                      first_name={agent.first_name}
                      last_name={agent.last_name}
                      agent_phone={agent.cell}
                      username={agent.username}
                      saveAgent={saveAgent}
                      onChange={onChange}
                      password={password}
                      email={agent.email}
                      onSelectOptionCounty={onSelectOptionCounty}
                      locations={locations}
                      regions={regions}
                      location={{
                        label: agnL.shortname,
                        value: agnL.id,
                      }}
                      region={{
                        label: agnL.description,
                        value: agnL.description,
                      }}
                      id={agent.id}
                      editLocations={editLocations}
                      editAgent={editAgent}
                    />
                  </td>
                </React.Fragment>
              ))
          ) : (
            <React.Fragment>
              <td>
                <button
                  className="btn btn-info btn-sm"
                  data-toggle="modal"
                  data-target={"#editAgent" + agent.id}
                >
                  Edit Agent
                </button>
                <EditAgent
                  first_name={agent.first_name}
                  last_name={agent.last_name}
                  agent_phone={agent.cell}
                  username={agent.username}
                  saveAgent={saveAgent}
                  onChange={onChange}
                  password={password}
                  email={agent.email}
                  onSelectOptionCounty={onSelectOptionCounty}
                  locations={locations}
                  regions={regions}
                  location={location}
                  id={agent.id}
                  editLocations={editLocations}
                  editAgent={editAgent}
                />
              </td>
            </React.Fragment>
          )}

          {/* <td>
            <button className="btn btn-danger btn-sm">Delete Agent</button>
          </td> */}
        </tr>
      ));
    } else {
      ispAgents = null;
    }
  } else {
    ispAgents = null;
  }
  return (
    <div className="row">
      <div className="col-md-8">
        <div className="card">
          <div className="card-body">
            <div className="d-flex justify-content-between">
              <h5 className="card-title">Agents</h5>
              <button
                className="btn btn-success btn-sm mb-2"
                data-toggle="modal"
                data-target="#addAgent"
              >
                Add Agent
              </button>
              <AddAgent
                first_name={first_name}
                last_name={last_name}
                agent_phone={agent_phone}
                username={username}
                saveAgent={saveAgent}
                onChange={onChange}
                password={password}
                email={email}
                onSelectOptionCounty={onSelectOptionCounty}
                locations={locations}
                regions={regions}
                location={location}
              />
            </div>
            <div className="table-responsive">
              <table className="table table-bordered table-striped tabe-condensed">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>First Name</th>
                    <th>Last Name</th>
                    <th>Phone</th>
                    <th>Access Code</th>
                    <th>Agent Id</th>
                    <th>Region</th>
                    <th>Location</th>
                    <th colSpan="3">Actions</th>
                  </tr>
                </thead>
                <tbody>{ispAgents}</tbody>
              </table>
            </div>
          </div>

          <div className="card-footer">
            <div className="d-flex flex-row-reverse">
              <form noValidate onSubmit={incrementPage}>
                <button className="btn btn-rounded" type="submit">
                  <i className="fas fa-chevron-right" />
                </button>
              </form>
              <form noValidate onSubmit={decrementPage}>
                <button className="btn btn-rounded mx-2" type="submit">
                  <i className="fas fa-chevron-left" />
                </button>
              </form>
              <span className="font-weight-normal">of {totalPages}</span>
              <select
                name="page"
                id="page"
                className="form-control mr-2"
                value={page}
                onChange={onChangePage}
              >
                {pages}
              </select>
              <span className="font-weight-normal mr-2">Page</span>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-4">
        <Aside
          searchHandler={searchHandler}
          errorMessageHandler={errorMessageHandler}
          assigned={assigned}
          inProgress={inProgress}
          deferred={deferred}
          completed={completed}
        />
      </div>
    </div>
  );
};

export default List;
