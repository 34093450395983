import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Swal from "sweetalert2";

import { CheckAuthList } from "../customers";

import { saveToAuthTable, fetchFromRad } from "../../actions/customersActions";

import { Error, Preloader, Navbar, Sidebar } from "../common";

class CheckAuth extends Component {
  constructor() {
    super();
    this.state = {
      allRadCheck: {},
      errors: "",
      page: 0,
      limit: 10,
      access_code: "",
      errorMessage: "",
      preloaderStyle: "d-none",
      currentPage: "list",
    };
  }

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handlePreloaderStyle = (newStyle) => {
    this.setState({ preloaderStyle: newStyle });
  };

  incrementPage = (e) => {
    e.preventDefault();
    const { limit, page, count, access_code } = this.state;
    const totalPages = Math.ceil(count / limit);

    const newPage = page + 1;
    if (newPage <= totalPages) {
      this.setState({ page: newPage });
      this.fetchFromRad(newPage, limit, access_code);
    }
  };

  decrementPage = (e) => {
    e.preventDefault();
    const { page, limit, access_code } = this.state;

    const newPage = page - 1;
    if (newPage >= 0) {
      this.setState({ page: newPage });
      this.fetchFromRad(newPage, limit, access_code);
    }
  };

  onChangePage = (e) => {
    const { limit, access_code } = this.state;
    const newPage = parseInt(e.target.value);
    this.setState({ [e.target.name]: newPage });

    this.fetchFromRad(newPage, limit, access_code);
  };

  fetchFromRad = (page, limit, access_code) => {
    this.handlePreloaderStyle("d-block");
    this.props.fetchFromRad(page * limit, limit, access_code);
  };

  componentDidMount() {
    if (!this.props.auth.isAuthenticated) {
      this.props.history.push("/admin");
    }
    this.fetchFromRad(
      this.state.page,
      this.state.limit,
      this.state.access_code
    );
  }

  saveToAuth = (e) => {
    console.log("its getting here");
    e.preventDefault();
    const { access_code } = this.state;
    if (access_code === "") {
      Swal.fire("Error", "Access Code is required", "error");
    } else {
      this.handlePreloaderStyle("d-block");

      let data = {
        accesscode: access_code,
      };
      this.props.saveToAuthTable(data);
      this.setState({
        access_code: "",
      });
    }
  };

  static getDerivedStateFromProps(nextProps, state) {
    // console.log({ nextProps, state });
    if (nextProps.allRadCheck !== state.allRadCheck) {
      const newCustomers = nextProps.allRadCheck;
      return {
        allRadCheck: newCustomers,
        preloaderStyle: "d-none",
      };
    }

    if (nextProps.errors.length > 0) {
      return { errorMessage: nextProps.errors, preloaderStyle: "d-none" };
    }

    // if (nextProps.addAgentSuccess !== state.addAgentSuccess) {
    //   return {
    //     addAgentSuccess: nextProps.addAgentSuccess,
    //     preloaderStyle: "d-none",
    //   };
    // }

    return null;
  }

  fetchStatementHandler = (customer_id) => {
    this.setState({ currentPage: "statement", preloaderStyle: "d-block" });
  };

  disconnectCustomertHandler = (access_code) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Please confirm this disconnect request",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, disconnect",
      cancelButtonText: "No, I made a mistake",
    }).then((result) => {
      if (result.value) {
        this.setState({ preloaderStyle: "d-block" });
      } else {
        this.setState({ preloaderStyle: "d-none" });
      }
    });
  };

  customersHandler = (newCustomers) => {
    this.setState({
      allRadCheck: newCustomers,
      count: newCustomers.totalCustomers,
    });
  };

  errorMessageHandler = (newError) => {
    this.setState({ errorMessage: newError });
  };

  searchHandler = (access_code) => {
    console.log(access_code);
    this.setState({
      access_code: access_code,
    });

    this.fetchFromRad(this.state.page, this.state.limit, access_code);
  };

  render() {
    const {
      allRadCheck,
      limit,
      page,
      errorMessage,
      preloaderStyle,
      access_code,
      last_name,
      agent_phone,
      username,
      password,
      email,
    } = this.state;

    return (
      <React.Fragment>
        <Preloader preloaderStyle={preloaderStyle} />
        <Sidebar />
        <div className="content">
          {/* <Error message={errorMessage} /> */}
          <div className="mt-4">
            <Navbar
              title="Customers in Auth"
              toggleSearch={this.toggleSearch}
            />
          </div>
          <CheckAuthList
            page={page}
            limit={limit}
            allRadCheck={allRadCheck}
            incrementPage={this.incrementPage}
            decrementPage={this.decrementPage}
            onChangePage={this.onChangePage}
            searchHandler={this.searchHandler}
            errorMessageHandler={this.errorMessageHandler}
            fetchStatementHandler={this.fetchStatementHandler}
            handlePreloaderStyle={this.handlePreloaderStyle}
            disconnectCustomertHandler={this.disconnectCustomertHandler}
            addFloat={this.props.addFloat}
            access_code={access_code}
            last_name={last_name}
            agent_phone={agent_phone}
            username={username}
            saveToAuth={this.saveToAuth}
            onChange={this.onChange}
            password={password}
            email={email}
          />
        </div>
      </React.Fragment>
    );
  }
}

CheckAuth.propTypes = {
  fetchFromRad: PropTypes.func.isRequired,
  allRadCheck: PropTypes.object.isRequired,
  saveToAuthTable: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  allRadCheck: state.customers.allRadCheck,
  errors: state.errors,
  auth: state.auth,
});

export default connect(mapStateToProps, {
  fetchFromRad,
  saveToAuthTable,
})(CheckAuth);
