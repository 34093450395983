import {
  FETCH_CUSTOMERS,
  ADD_NEW_CUSTOMER,
  FETCH_ISP_AGENTS,
  ADD_AGENT_FLOAT,
  ADD_NEW_AGENT,
  FETCH_LOCATIONS,
  FETCH_REGIONS,
  CHANGE_AGENT_ACCESS,
  FETCH_AGENTS_PAYMENTS_EXPORT,
  FETCH_AGENTS_PAYMENTS,
} from "../actions/types";

const initialState = {
  commissions: {},
  customer: {},
  agents: {},
  addFloatSuccess: {},
  addAgentSuccess: {},
  locations: [],
  regions: [],
  changeAccessResponse: {},
  agentPayments: {},
  agentPaymentsExport: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_CUSTOMERS:
      return {
        ...state,
        commissions: action.payload,
      };
    case ADD_NEW_CUSTOMER:
      return {
        ...state,
        customer: action.payload,
      };
    case FETCH_ISP_AGENTS:
      return {
        ...state,
        agents: action.payload,
      };
    case ADD_AGENT_FLOAT:
      return {
        ...state,
        addFloatSuccess: action.payload,
      };
    case ADD_NEW_AGENT:
      return {
        ...state,
        addAgentSuccess: action.payload,
      };
    case FETCH_REGIONS:
      return {
        ...state,
        regions: action.payload,
      };
    case FETCH_LOCATIONS:
      return {
        ...state,
        locations: action.payload,
      };
    case CHANGE_AGENT_ACCESS:
      return {
        ...state,
        changeAccessResponse: action.payload,
      };
    case FETCH_AGENTS_PAYMENTS:
      return {
        ...state,
        agentPayments: action.payload,
      };
    case FETCH_AGENTS_PAYMENTS_EXPORT:
      return {
        ...state,
        agentPaymentsExport: action.payload,
      };
    default:
      return state;
  }
}
