import React, { Component } from "react";
import moment from "moment";

class Table extends Component {
  state = {
    historyCount: 0,
  };
  render() {
    const { history, Items, showGraph, graphName } = this.props;

    console.log(Items);

    const allHistoryDisplay =
      history instanceof Array
        ? history.map((history, index) => {
            const items = Items.map((itm) => {
              if (history.itemid === itm.itemid) {
                return (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{itm.name}</td>
                    <td>
                      {moment.unix(history.clock).format("YYYY/MM/DD h:mm:ss")}
                    </td>
                    <td>
                      {itm.units === "bps"
                        ? (history.value / 1000000).toFixed(2) + " Mbps"
                        : itm.units === "s"
                        ? (history.value * 1000).toFixed(2) + " ms"
                        : history.value + " " + itm.units}
                    </td>
                  </tr>
                );
              } else return null;
            });
            return items;
          })
        : null;
    return (
      <React.Fragment>
        <div className="card" id="zabbixTable">
          {showGraph && (
            <React.Fragment>
              <h6 className="pl-3 mb-2 mt-2">{graphName}</h6>
              <div className="table-responsive">
                <table
                  className="table table-bordered table-striped tabe-condensed"
                  id="table1-to-xls"
                >
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Item</th>
                      <th>Clock</th>
                      <th>Value</th>
                    </tr>
                  </thead>
                  <tbody>{allHistoryDisplay}</tbody>
                </table>
              </div>
            </React.Fragment>
          )}
        </div>
      </React.Fragment>
    );
  }
}

export default Table;
