import React from "react";
import { Pie } from "react-chartjs-2";

const DataBalance = ({ dataLimit, dataTransfered }) => {
  const pieData = {
    labels: ["Data Limit", "Data Transfered"],
    datasets: [
      {
        label: "Data Balance",
        data: [dataLimit, dataTransfered],
        backgroundColor: ["rgb(255, 99, 132)", "rgb(255, 205, 86)"],
      },
    ],
  };

  return <Pie data={pieData} />;
};

export default DataBalance;
