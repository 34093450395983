import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import moment from "moment-timezone";
import Swal from "sweetalert2";

import {
  fetchAnnouncements,
  createAnnouncement,
  updateAnnouncement,
  deleteAnnouncement,
  postAnnouncement,
  createNotification,
} from "../../actions/announcementsActions";

import { Pagination, Navbar, Sidebar } from "../common";
import { CreateAnnouncement, EditAnnouncement, CreateNotification } from "./";

class AnnouncementsLayout extends Component {
  state = {
    announcements: [],
    count: 0,
    text: "",
    page: 1,
    limit: 20,
    error: "",
    announcementCount: 0,
    create_announcement: "",
    create_notification: "",
    create_body: "",
    showActions: true,
    post: 0,
  };

  hideActions = () => {
    this.setState({
      showActions: false,
    });
  };

  handlePreloaderStyle = (newStyle) => {
    this.setState({ preloaderStyle: newStyle });
  };

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleCreateAnnouncement = (announcement) => {
    this.handlePreloaderStyle("d-block");
    this.setState({
      create_announcement: announcement.text,
    });
    this.props.createAnnouncement(announcement);
  };

  handleCreateNotification = (notification) => {
    this.handlePreloaderStyle("d-block");
    this.setState({
      create_notification: notification.title,
      create_body: notification.body,
    });
    this.props.createNotification(notification);
  };

  //   delete confirmation
  sweetAlert = (id) => {
    Swal.fire({
      title: "Are you sure you want to delete?",
      text: "You won't be able to revert this!",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete announcement!",
    }).then((result) => {
      if (result.value) {
        this.props.deleteAnnouncement(id);
        Swal.fire("Deleted!", "Announcement has been deleted.", "success");
      }
    });
  };

  handleIncrementPage = (e) => {
    e.preventDefault();
    const { page, limit, count } = this.state;
    // console.log(page);
    const totalPages = Math.ceil(count / limit);
    const newPage = page + 1;

    if (newPage < totalPages) {
      this.setState({ page: newPage, announcementCount: newPage * limit });
      this.fetchAnnouncements(newPage, limit);
    }
  };

  handleDecrementPage = (e) => {
    e.preventDefault();
    const { page, limit } = this.state;
    const newPage = page - 1;
    if (newPage >= 0) {
      this.setState({ page: newPage, announcementCount: newPage * limit });
      this.fetchAnnouncements(newPage, limit);
    }
  };

  handleOnChangePage = (e) => {
    const { limit } = this.state;
    const newPage = parseInt(e.target.value);
    this.setState({
      [e.target.name]: newPage,
      announcementCount: newPage * limit,
    });
    this.fetchAnnouncements(newPage, limit);
  };

  fetchAnnouncements = (page, limit) => {
    this.props.fetchAnnouncements(page, limit);
  };

  componentDidMount() {
    const { page, limit } = this.state;
    this.fetchAnnouncements(page, limit);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.announcements) {
      console.log(nextProps.announcements.items);
      this.setState({
        announcements: nextProps.announcements.items,
        count: nextProps.announcements.rows,
      });
    }
    if (nextProps.errors) {
      console.log(nextProps.errors.text);
      this.setState({
        error: nextProps.errors.text,
      });
    }

    if (nextProps.announcementCreated) {
      const announcementCreated = nextProps.announcementCreated;
      if (announcementCreated.message === "Success") {
        this.setState({
          successMessage: announcementCreated.message,
          create_announcement: "",
        });
        JSON.stringify(
          Swal.fire("Announcement Created Successful", "", "success")
        );
        const { page, limit, name } = this.state;
        this.fetchAnnouncements(page, limit);
        nextProps.announcementCreated.message = "";
      }
    }

    if (nextProps.notificationCreated) {
      const notificationCreated = nextProps.notificationCreated;
      if (notificationCreated.message === "Success") {
        this.setState({
          successMessage: notificationCreated.message,
          create_announcement: "",
          create_notification: "",
          create_body: "",
        });
        JSON.stringify(
          Swal.fire("Notification Created Successful", "", "success")
        );
        // const { page, limit, name } = this.state;
        this.fetchAnnouncements();
        nextProps.notificationCreated.message = "";
      }
    }

    if (nextProps.announcementUpdated) {
      const announcementUpdated = nextProps.announcementUpdated;

      if (announcementUpdated.message === "Success") {
        this.setState({
          successMessage: announcementUpdated.message,
        });

        JSON.stringify(
          Swal.fire("Announcement Updated Successful", "", "success")
        );
        nextProps.announcementUpdated.message = "";

        // this.fetchPoint(text);
        this.fetchAnnouncements();
      }
    }

    if (nextProps.announcementPosted) {
      const announcementPosted = nextProps.announcementPosted;

      if (announcementPosted.message === "Success") {
        this.setState({
          successMessage: announcementPosted.message,
        });

        JSON.stringify(
          Swal.fire("Announcement Posted Successful", "", "success")
        );
        nextProps.announcementPosted.message = "";

        // this.fetchPoint(text);
        this.fetchAnnouncements();
      }
    }

    if (nextProps.annoucementDeleted) {
      const annoucementDeleted = nextProps.annoucementDeleted;

      if (annoucementDeleted.message === "Success") {
        this.setState({
          successMessage: annoucementDeleted.message,
        });

        nextProps.annoucementDeleted.message = "";
        // const { page, limit, name } = this.state;
        // this.fetchPoint(page, limit, name);
        this.fetchAnnouncements();
      }
    }
  }

  // onSubmitPostAnnouncement = (id) => {
  //   // e.preventDefault();
  //   console.log(id);

  //   const { post } = this.state;
  //   this.setState({
  //     post: 1,
  //   });
  //   const announcement = {
  //     post,
  //   };

  //   this.handlePreloaderStyle("d-block");
  //   this.props.postAnnouncement(id, announcement);
  // };

  onSubmitPostAnnouncement = (e, id, text) => {
    e.preventDefault();

    const announcement = {
      text,
      post: 1,
    };

    this.handlePreloaderStyle("d-block");
    this.props.updateAnnouncement(id, announcement);
  };

  render() {
    const {
      announcements,
      count,
      create_announcement,
      create_notification,
      create_body,
      error,
      post,
      page,
      limit,
      announcementCount,
    } = this.state;

    let announcementCountAll = announcementCount + 1;

    const AllAnnouncements = announcements
      ? announcements.map((announcement, index) => (
          <React.Fragment key={index}>
            <tr>
              <td>{announcementCountAll++}</td>
              <td>{announcement.text}</td>
              <td>
                {moment
                  .tz(announcement.updatedAt, "Africa/Nairobi")
                  .format("LLLL")}
              </td>
              {announcement.post === 0 ? (
                <React.Fragment>
                  <td>
                    <button
                      className="btn btn-danger btn-sm"
                      onClick={() => {
                        this.sweetAlert(announcement.id);
                      }}
                    >
                      <i
                        className="fas fa-trash"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Delete Announcement"
                      />
                    </button>
                  </td>
                  <td>
                    <EditAnnouncement
                      updateAnnouncement={this.props.updateAnnouncement}
                      key={announcement.id}
                      id={announcement.id}
                      text={announcement.text}
                      post={announcement.post}
                      errors={error}
                      handlePreloaderStyle={this.handlePreloaderStyle}
                      successMessage={this.props.successMessage}
                    />
                  </td>
                  <td>
                    <form noValidate>
                      <input
                        hidden
                        value={announcement.text}
                        oncChange={this.onChange}
                        name="text"
                      />
                      <button
                        className="btn btn-info btn-sm"
                        onClick={(e) => {
                          this.onSubmitPostAnnouncement(
                            e,
                            announcement.id,
                            announcement.text
                          );
                        }}
                      >
                        Post Announcement
                      </button>
                    </form>
                  </td>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <td />
                  <td />
                  <td className="text-success">
                    Posted
                    <span>
                      <i className="fas fa-check ml-1"></i>
                    </span>
                  </td>
                </React.Fragment>
              )}
            </tr>
          </React.Fragment>
        ))
      : null;
    return (
      <React.Fragment>
        <Sidebar />
        <div className="content">
          <div className="mt-4">
            <Navbar title="Personnel" toggleSearch={this.toggleSearch} />
          </div>
          <div className="col-md-12">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">Announcements</h5>
                <div
                  className="mb-3"
                  style={{
                    display: "flex",
                    width: "25%",
                    justifyContent: "space-between",
                  }}
                >
                  <CreateAnnouncement
                    handleCreateAnnouncement={this.handleCreateAnnouncement}
                    handlePreloaderStyle={this.handlePreloaderStyle}
                    text={create_announcement}
                    errors={error}
                  />
                  {/* <CreateNotification
                    handleCreateAnnouncement={this.handleCreateAnnouncement}
                    handlePreloaderStyle={this.handlePreloaderStyle}
                    title={create_notification}
                    body={create_body}
                    errors={error}
                  /> */}
                </div>

                <div className="table-responsive">
                  <table className="table table-sm table-bordered table-striped table-condensed">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Announcement</th>
                        <th>Updated At</th>
                        <th colSpan="3">Actions</th>
                      </tr>
                    </thead>
                    <tbody>{AllAnnouncements}</tbody>
                  </table>
                </div>
                {/* <Pagination
                  page={page}
                  limit={limit}
                  count={count}
                  handleDecrementPage={this.handleDecrementPage}
                  handleIncrementPage={this.handleIncrementPage}
                  handleOnChangePage={this.handleOnChangePage}
                /> */}
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

AnnouncementsLayout.propTypes = {
  announcements: PropTypes.object.isRequired,
  announcementUpdated: PropTypes.object,
  announcementCreated: PropTypes.object,
  annoucementDeleted: PropTypes.object,
};

const mapStateToProps = (state) => ({
  announcements: state.announcements.announcements,
  errors: state.errors,
  announcementCreated: state.announcements.announcementCreated,
  announcementUpdated: state.announcements.announcementUpdated,
  annoucementDeleted: state.announcements.annoucementDeleted,
  announcementPosted: state.announcements.announcementPosted,
  notificationCreated: state.announcements.notificationCreated,
});

export default connect(mapStateToProps, {
  fetchAnnouncements,
  createAnnouncement,
  updateAnnouncement,
  deleteAnnouncement,
  postAnnouncement,
  createNotification,
})(AnnouncementsLayout);
