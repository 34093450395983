import React, { Component } from "react";
import { logo } from "../../assets/img";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

class Sidebar extends Component {
  state = {};
  render() {
    const { personnel } = this.props.auth;
    const { agent } = this.props.agentAuth;

    const adminLinks = (
      <React.Fragment>
        <div className="d-flex flex-row align-items-center justify-content-start">
          <i className="fas fa-user text-warning menu-icon"></i>
          <Link href="#personnel" to="/personnel">
            Personnel
          </Link>
        </div>
        <div className="d-flex flex-row align-items-center justify-content-start">
          <i className="fas fa-users text-warning menu-icon"></i>
          <Link href="#customers" to="/customers">
            Customers
          </Link>
        </div>
        <div className="d-flex flex-row align-items-center justify-content-start">
          <i className="fas fa-university text-warning menu-icon"></i>
          <Link href="#transactions" to="/transactions">
            Transactions
          </Link>
        </div>
        {/* SMS */}
        <div className="dropdown">
          <div
            className="d-flex flex-row align-items-center justify-content-start dropdown-toggle text-warning menu-icon"
            id="dropdownMenuButton1"
            data-toggle="dropdown"
            aria-expanded="false"
          >
            <i className="fas fa-sms text-warning menu-icon mr-4"></i>
            <a href="#contact" className="pr-4">
              SMS
            </a>
          </div>
          <div class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
            <Link to="/sms">
              <span class="dropdown-item" href="#">
                Bulk SMS
              </span>
            </Link>
            <Link to="/singleSms">
              <span class="dropdown-item" href="#">
                Single SMS
              </span>
            </Link>
          </div>
        </div>
        {/* AGENTS */}
        <div className="dropdown">
          <div
            className="d-flex flex-row align-items-center justify-content-start dropdown-toggle text-warning menu-icon"
            id="dropdownMenuButton1"
            data-toggle="dropdown"
            aria-expanded="false"
          >
            <i className="fas fa-headphones-alt text-warning menu-icon"></i>
            <a href="#contact" className="pr-4">
              Agents
            </a>
          </div>
          <div class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
            <Link to="/agents">
              <span class="dropdown-item" href="#">
                All Agents
              </span>
            </Link>
            <Link to="/agentsPayments">
              <span class="dropdown-item" href="#">
                Float Payments
              </span>
            </Link>
          </div>
        </div>

        {/* noc */}
        <div className="dropdown">
          <div
            className="d-flex flex-row align-items-center justify-content-start dropdown-toggle text-warning menu-icon"
            id="dropdownMenuButton1"
            data-toggle="dropdown"
            aria-expanded="false"
          >
            <i className="fas fa-network-wired text-warning menu-icon"></i>
            <a href="#contact" className="pr-5">
              NOC
            </a>
          </div>
          <div class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
            <Link to="/cst_noc">
              <span class="dropdown-item" href="#">
                CST NOC
              </span>
            </Link>
            <Link to="/noc_report">
              <span class="dropdown-item" href="#">
                NOC Reports
              </span>
            </Link>
          </div>
        </div>

        {/* HotSpot */}
        <div className="dropdown">
          <div
            className="d-flex flex-row align-items-center justify-content-start dropdown-toggle text-warning menu-icon"
            id="dropdownMenuButton1"
            data-toggle="dropdown"
            aria-expanded="false"
          >
            <i className="fas fa-wifi text-warning menu-icon"></i>
            <a href="#contact" className="pr-4">
              HotSpot
            </a>
          </div>
          <div className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
            <Link to="/checkAuth">
              <span className="dropdown-item">Customers in Auth</span>
            </Link>
            <Link to="/radUser">
              <span className="dropdown-item">Customer Status</span>
            </Link>
            <Link to="/payments">
              <span className="dropdown-item">Customer Payments</span>
            </Link>
          </div>
        </div>
        {/* Announcements */}
        <Link
          className="d-flex flex-row align-items-center justify-content-start"
          to="/announcements"
        >
          <i className="fas fa-bell text-warning menu-icon"></i>
          <a href="#contact">Notifications</a>
        </Link>
      </React.Fragment>
    );
    const cstLinks = (
      <React.Fragment>
        {/* customers */}
        <div className="d-flex flex-row align-items-center justify-content-start">
          <i className="fas fa-users text-warning menu-icon"></i>
          <Link href="#customers" to="/customers">
            Customers
          </Link>
        </div>
        {/* SMS */}
        <div className="dropdown">
          <div
            className="d-flex flex-row align-items-center justify-content-start dropdown-toggle text-warning menu-icon"
            id="dropdownMenuButton1"
            data-toggle="dropdown"
            aria-expanded="false"
          >
            <i className="fas fa-sms text-warning menu-icon mr-4"></i>
            <a href="#contact" className="pr-4">
              SMS
            </a>
          </div>
          <div class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
            <Link to="/sms">
              <span class="dropdown-item" href="#">
                Bulk SMS
              </span>
            </Link>
            <Link to="/singleSms">
              <span class="dropdown-item" href="#">
                Single SMS
              </span>
            </Link>
          </div>
        </div>
        {/* HotSpot */}
        <div className="dropdown">
          <div
            className="d-flex flex-row align-items-center justify-content-start dropdown-toggle text-warning menu-icon"
            id="dropdownMenuButton1"
            data-toggle="dropdown"
            aria-expanded="false"
          >
            <i className="fas fa-wifi text-warning menu-icon"></i>
            <a href="#contact" className="pr-4">
              HotSpot
            </a>
          </div>
          <div className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
            <Link to="/checkAuth">
              <span className="dropdown-item">Customers in Auth</span>
            </Link>
            <Link to="/radUser">
              <span className="dropdown-item">Customer Status</span>
            </Link>
            <Link to="/payments">
              <span className="dropdown-item">Customer Payments</span>
            </Link>
          </div>
        </div>
      </React.Fragment>
    );

    const nocLinks = (
      <React.Fragment>
        {/* noc */}
        <div className="dropdown">
          <div
            className="d-flex flex-row align-items-center justify-content-start dropdown-toggle text-warning menu-icon"
            id="dropdownMenuButton1"
            data-toggle="dropdown"
            aria-expanded="false"
          >
            <i className="fas fa-network-wired text-warning menu-icon"></i>
            <a href="#contact" className="pr-5">
              NOC
            </a>
          </div>
          <div class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
            <Link to="/cst_noc">
              <span class="dropdown-item" href="#">
                CST NOC
              </span>
            </Link>
            <Link to="/noc_report">
              <span class="dropdown-item" href="#">
                NOC Reports
              </span>
            </Link>
          </div>
        </div>
      </React.Fragment>
    );

    return (
      <div className="sidebar">
        <div className="d-flex justify-content-center align-items-center mt-4">
          <img src={logo} alt="" srcset="" height="77" />
        </div>
        <div className="d-flex justify-content-center align-items-center flex-column mt-5">
          {personnel.personnel_type_id === 1
            ? adminLinks
            : personnel.personnel_type_id === 3
            ? cstLinks
            : personnel.personnel_type_id === 5 && nocLinks}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  agentAuth: state.agentAuth,
});

export default connect(mapStateToProps)(Sidebar);
