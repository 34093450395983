import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import Navbar from "./Navbar";

import { fetchCampaign, createCampaign } from "../../actions/campaignActions";

import { Error, Preloader } from "../common";
import { Create } from "../campaigns";

class Campaign extends Component {
  constructor() {
    super();
    this.state = {
      campaign: [],
      campaignUpdated: {},
      campaignCreated: {},
      errors: [],
      preloaderStyle: "d-none",
      campaignCount: 1,
      errorMessage: "",
    };
  }

  handlePreloaderStyle = (newStyle) => {
    this.setState({ preloaderStyle: newStyle });
  };

  fetchCampaign = () => {
    this.handlePreloaderStyle("d-block");
    this.props.fetchCampaign();
  };

  componentDidMount() {
    if (!this.props.auth.isAuthenticated) {
      this.props.history.push("/admin");
    }
    this.fetchCampaign();
  }

  componentWillReceiveProps(nextProps) {
    this.handlePreloaderStyle("d-none");

    if (nextProps.campaign) {
      const newCampaign = nextProps.campaign;
      this.setState({
        campaign: newCampaign,
      });
    }

    if (nextProps.campaignCreated) {
      const campaignCreated = nextProps.campaignCreated;
      if (campaignCreated.message === "Success") {
        nextProps.campaignCreated.message = "";
        this.fetchCampaign();
      }
    }

    if (nextProps.campaignUpdated) {
      const campaignUpdated = nextProps.campaignUpdated;
      if (campaignUpdated.message === "Success") {
        nextProps.campaignUpdated.message = "";
        this.fetchCampaign();
      }
    }
    if (nextProps.errors) {
      //  console.log(nextProps.errors);
      this.setState({ errors: nextProps.errors });
    }
  }

  render() {
    const { campaign, preloaderStyle, errorMessage, errors } = this.state;
    return (
      <React.Fragment>
        <Preloader preloaderStyle={preloaderStyle} />
        <Navbar />
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <Error message={errorMessage} />
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title">Campaigns</h5>

                  <Create
                    campaign_id={campaign.campaign_id}
                    campaign_onames={campaign.campaign_onames}
                    campaign_fname={campaign.campaign_fname}
                    campaign_phone={campaign.campaign_phone}
                    campaign_status={campaign.campaign_status}
                    campaign_type_id={campaign.campaign_type_id}
                    createCampaign={this.props.createCampaign}
                    errors={errors}
                    handlePreloaderStyle={this.handlePreloaderStyle}
                  />
                  {/* <Display
                    campaign={campaign}
                    campaignCount={campaignCount}
                    updateCampaign={this.props.updateCampaign}
                    errors={errors}
                    handlePreloaderStyle={this.handlePreloaderStyle}
                  /> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

Campaign.propTypes = {
  fetchCampaign: PropTypes.func.isRequired,
  createCampaign: PropTypes.func.isRequired,
  campaign: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  campaign: state.campaign.campaigns,
  campaignUpdated: state.campaign.campaignsUpdated,
  campaignCreated: state.campaign.campaignsCreated,
  errors: state.errors,
  auth: state.auth,
});

export default connect(mapStateToProps, {
  fetchCampaign,
  createCampaign,
})(Campaign);
