import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

class Select extends Component {
  constructor() {
    super();
    this.state = {
      customer: "",
      task_id: "",
      task_status_id: "",
      onSubmit: {}
    };
    this.selectTask = this.selectTask.bind(this);
  }

  componentDidMount() {
    this.setState({
      customer: this.props.customerName, 
      task_id: this.props.task_id,
      task_status_id: this.props.task_status_id,
      onSubmit: this.props.onSubmit
    });
  }

  selectTask(e) {
    e.preventDefault();
    this.props.handlePreloaderStyle("d-block");
    const task = {
      task_id: this.state.task_id,
      task_status_id: this.state.task_status_id
    };

    this.props.onSubmit(task, this.props.history);
  }

  render() {
    return (
      <form noValidate onSubmit={this.selectTask}>
        <button type="submit" className="btn btn-warning btn-sm">
          Select
        </button>
      </form>
    );
  }
}

Select.propTypes = {
  customerName: PropTypes.string.isRequired,
  task_id: PropTypes.number.isRequired,
  task_status_id: PropTypes.number.isRequired,
  onSubmit: PropTypes.func.isRequired,
  handlePreloaderStyle: PropTypes.func.isRequired
};

export default connect(
  null,
  {}
)(withRouter(Select));
