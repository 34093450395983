import React from "react";
import Moment from "moment";

import { Aside, Select } from ".";

const List = ({
  page,
  limit,
  customers,
  incrementPage,
  decrementPage,
  onChangePage,
  searchHandler,
  errorMessageHandler,
  handlePreloaderStyle,
  fetchStatementHandler,
  disconnectCustomertHandler,
  changeAccessCodeHandler,
  changeAccessButtonVisibility
}) => {
  console.log(changeAccessButtonVisibility);
  let customerCount = page * limit + 1;
  let ispCustomers;

  let pages;
  let totalPages = 0;
  let assigned = 0;
  let inProgress = 0;
  let completed = 0;
  let deferred = 0;
  let count = customers.rows ? customers.rows : 0;

  if (count > 0) {
    totalPages = Math.ceil(count / limit);

    const allPages = Array.from(Array(totalPages).keys());
    if (allPages.length > 0) {
      pages = allPages.map((pg) => (
        <option key={pg} value={pg}>
          {pg + 1}
        </option>
      ));
    } else {
      pages = null;
    }
  } else {
    pages = null;
  }

  if (customers.items) {
    if (customers.items instanceof Array) {
      ispCustomers = customers.items.map((customer) => (
        <tr key={customer.id}>
          <td>{customerCount++}</td>
          <td>{customer.first_name}</td>
          <td>{customer.last_name}</td>
          <td>{customer.phone}</td>
          <td>{customer.access_code}</td>
          <td>{Moment(customer.created_at).format("DD/MM/YYYY")}</td>
          <td>
            <Select
              customerName={customer.first_name + " " + customer.last_name}
              customer_id={customer.id}
              customer_status_id={2}
              handlePreloaderStyle={handlePreloaderStyle}
              onSubmit={fetchStatementHandler}
              buttonTitle="View Statement"
              buttonClass="btn-success"
            />
          </td>
          <td>
            <Select
              customerName={
                customer.customer_first_name + " " + customer.customer_last_name
              }
              customer_id={customer.access_code}
              customer_status_id={2}
              handlePreloaderStyle={handlePreloaderStyle}
              onSubmit={disconnectCustomertHandler}
              buttonTitle="Disconnect"
              buttonClass="btn-danger"
            />
          </td>
          <td>
            {!customer.access_code.includes("agent") && (
              <Select
                customerName={
                  customer.customer_first_name +
                  " " +
                  customer.customer_last_name
                }
                customer_id={customer.access_code}
                customer_status_id={2}
                handlePreloaderStyle={handlePreloaderStyle}
                onSubmit={changeAccessCodeHandler}
                buttonTitle="Change Access Code"
                changeAccessButtonVisibility={changeAccessButtonVisibility}
                buttonClass="btn-warning"
              />
            )}
          </td>
        </tr>
      ));
    } else {
      ispCustomers = null;
    }
  } else {
    ispCustomers = null;
  }
  return (
    <div className="row">
      <div className="col-md-8">
        <div className="card">
          <div className="card-body">
            <h5 className="card-title">Customers</h5>
            <div className="table-responsive">
              <table className="table table-bordered table-striped tabe-condensed">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>First Name</th>
                    <th>Last Name</th>
                    <th>Phone</th>
                    <th>Access Code</th>
                    <th>Registration Date</th>
                    <th colSpan="3">Actions</th>
                  </tr>
                </thead>
                <tbody>{ispCustomers}</tbody>
              </table>
            </div>
          </div>

          <div className="card-footer">
            <div className="d-flex flex-row-reverse">
              <form noValidate onSubmit={incrementPage}>
                <button className="btn btn-rounded" type="submit">
                  <i className="fas fa-chevron-right" />
                </button>
              </form>
              <form noValidate onSubmit={decrementPage}>
                <button className="btn btn-rounded mx-2" type="submit">
                  <i className="fas fa-chevron-left" />
                </button>
              </form>
              <span className="font-weight-normal">of {totalPages}</span>
              <select
                name="page"
                id="page"
                className="form-control mr-2"
                value={page}
                onChange={onChangePage}
              >
                {pages}
              </select>
              <span className="font-weight-normal mr-2">Page</span>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-4">
        <Aside
          searchHandler={searchHandler}
          errorMessageHandler={errorMessageHandler}
          assigned={assigned}
          inProgress={inProgress}
          deferred={deferred}
          completed={completed}
        />
      </div>
    </div>
  );
};

export default List;
