import React from "react";
import PropTypes from "prop-types";

const View = ({
  customerName,
  taskId,
  gender,
  location,
  age,
  accessCode,
  splashPage,
  autoplay,
  mpesa,
  comments,
  personnelFirstName,
  personnelLastName,
  date
}) => {
  return (
    <React.Fragment>
      <button
        className="btn btn-sm btn-primary"
        data-toggle="modal"
        data-target={"#view_customer" + taskId}
      >
        View
      </button>
      <div
        id={"view_customer" + taskId}
        className="modal fade"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="myModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                View {customerName}
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <table className="table table-condensed table-hover table-stiped table-bordered">
                <tbody>
                  <tr>
                    <th>Gender</th>
                    <td>{gender}</td>
                  </tr>
                  <tr>
                    <th>Location</th>
                    <td>{location}</td>
                  </tr>
                  <tr>
                    <th>Age</th>
                    <td>{age}</td>
                  </tr>
                  <tr>
                    <th>Access Code</th>
                    <td>{accessCode === 1 ? "Completed" : "Incompleted"}</td>
                  </tr>
                  <tr>
                    <th>Splash Page</th>
                    <td>{splashPage === 1 ? "Completed" : "Incompleted"}</td>
                  </tr>
                  <tr>
                    <th>Turn off Autoplay</th>
                    <td>{autoplay === 1 ? "Completed" : "Incompleted"}</td>
                  </tr>
                  <tr>
                    <th>M-Pesa Training</th>
                    <td>{mpesa === 1 ? "Completed" : "Incompleted"}</td>
                  </tr>
                  <tr>
                    <th>Comments</th>
                    <td>{comments}</td>
                  </tr>
                  <tr>
                    <th>Updated By</th>
                    <td>
                      {personnelFirstName} {personnelLastName}
                    </td>
                  </tr>
                  <tr>
                    <th>Updated On</th>
                    <td>{date}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-default"
                data-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

View.propTypes = {
  customerName: PropTypes.string.isRequired,
  taskId: PropTypes.number.isRequired,
  gender: PropTypes.string,
  location: PropTypes.string,
  age: PropTypes.number,
  accessCode: PropTypes.number,
  splashPage: PropTypes.number,
  autoplay: PropTypes.number,
  mpesa: PropTypes.number,
  comments: PropTypes.string,
  personnelFirstName: PropTypes.string.isRequired,
  personnelLastName: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired
};

export default View;
