import React from "react";
import { Line } from "react-chartjs-2";

const Graph = ({ showGraph, data, graphName, options }) => {
  console.log(options);
  return (
    <div className="card mb-3">
      {showGraph && (
        <div className="d-flex justify-content-center">
          <div className="mb-4" style={{ width: "800px" }}>
            <h6 className="pl-3 mb-2 mt-2">{graphName}</h6>
            <Line data={data} options={options} />
          </div>
        </div>
      )}
    </div>
  );
};

export default Graph;
