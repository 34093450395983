//actions to be evaluated
export const GET_ERRORS = "GET_ERRORS";
export const SET_CURRENT_PERSONNEL = "SET_CURRENT_PERSONNEL";
export const SET_PASSWORD_RESET = "SET_PASSWORD_RESET";
export const FETCH_ASSIGNED_TASKS = "FETCH_ASSIGNED_TASKS";
export const SELECT_TASK = "SELECT_TASK";
export const FETCH_TASKS_SUMMARY = "FETCH_TASKS_SUMMARY";
export const FETCH_ADMIN_TASK_SUMMARY = "FETCH_ADMIN_TASK_SUMMARY";
export const FETCH_PERSONNEL_TRANSACTIONS = "FETCH_PERSONNEL_TRANSACTIONS";
export const FETCH_ADMIN_TASK_REVENUE = "FETCH_ADMIN_TASK_REVENUE";
export const FETCH_ADMIN_TIME_SUMMARY = "FETCH_ADMIN_TIME_SUMMARY";
export const SET_CURRENT_AGENT = "SET_CURRENT_AGENT";
export const SET_AGENT_PASSWORD_RESET = "SET_AGENT_PASSWORD_RESET";
export const FETCH_CUSTOMERS = "FETCH_CUSTOMERS";
export const ADD_NEW_CUSTOMER = "ADD_NEW_CUSTOMER";
export const REASSIGN_TASK = "REASSIGN_TASK";
export const FETCH_TELESALES = "FETCH_TELESALES";
export const ADD_PERSONNEL = "ADD_PERSONNEL";
export const FETCH_ADMIN_PAYMENTS = "FETCH_ADMIN_PAYMENTS";
export const EXPORT_ADMIN_PAYMENTS = "EXPORT_ADMIN_PAYMENTS";
export const EDIT_PERSONNEL = "EDIT_PERSONNEL";
export const GET_PERSONNEL = "GET_PERSONNEL";
export const FETCH_PERSONNEL = "FETCH_PERSONNEL";
export const CREATE_PERSONNEL = "CREATE_PERSONNEL";
export const UPDATE_PERSONNEL = "UPDATE_PERSONNEL";
export const FETCH_COMMISSIONS_EXPORT = "FETCH_COMMISSIONS_EXPORT";
export const EXPORT_ADMIN_LOCKS = "EXPORT_ADMIN_LOCKS";
export const FETCH_CAMPAIGN = "FETCH_CAMPAIGN";
export const CREATE_CAMPAIGN = "CREATE_CAMPAIGN";
export const UPDATE_CAMPAIGN = "UPDATE_CAMPAIGN";
export const FETCH_ANNOUNCEMENTS = "FETCH_ANNOUNCEMENTS";
export const CREATE_ANNOUNCEMENT = "CREATE_ANNOUNCEMENT";
export const UPDATE_ANNOUNCEMENT = "UPDATE_ANNOUNCEMENT";
export const DELETE_ANNOUNCEMENT = "DELETE_ANNOUNCEMENT";
export const POST_ANNOUNCEMENT = "POST_ANNOUNCEMENT";
export const CREATE_NOTIFICATION = "CREATE_NOTIFICATION";
export const POST_SMS = "POST_SMS";
export const SINGLE_MESSAGE = "SINGLE_MESSAGE";
export const FETCH_SMS = "FETCH_SMS";
export const FETCH_SMS_EXPORT = "FETCH_SMS_EXPORT";
export const LOGIN_ZABBIX = "LOGIN_ZABBIX";
export const FETCH_SERVERS = "FETCH_SERVERS";
export const FETCH_HOSTGROUPS = "FETCH_HOSTGROUPS";
export const FETCH_HOSTS = "FETCH_HOSTS";
export const FETCH_GRAPHS = "FETCH_GRAPHS";
export const FETCH_ITEMS = "FETCH_ITEMS";
export const FETCH_HISTORY = "FETCH_HISTORY";
export const FETCH_HOSTS_HISTORY = "FETCH_HOSTS_HISTORY";
export const FETCH_APPLICATIONS = "FETCH_APPLICATIONS";
export const FETCH_ITEM_APPLICATION = "FETCH_ITEM_APPLICATION";
export const FETCH_HOSTS_HOSTGROUP = "FETCH_HOSTS_HOSTGROUP";
export const FETCH_ISP_CUSTOMERS = "FETCH_ISP_CUSTOMERS";
export const FETCH_CUSTOMER_STATEMENT = "FETCH_CUSTOMER_STATEMENT";
export const DISCONNECT_CUSTOMER = "DISCONNECT_CUSTOMER";
export const FETCH_ISP_AGENTS = "FETCH_ISP_AGENTS";
export const ADD_AGENT_FLOAT = "ADD_AGENT_FLOAT";
export const ADD_NEW_AGENT = "ADD_NEW_AGENT";
export const FETCH_DISCONNECT_CUSTOMERS = "FETCH_DISCONNECT_CUSTOMERS";
export const CHANGE_ACCESS_CODE = "CHANGE_ACCESS_CODE";
export const FETCH_SPECIAL_CUSTOMERS = "FETCH_SPECIAL_CUSTOMERS";
export const FETCH_ALL_DISCONNECT_CUSTOMERS = "FETCH_ALL_DISCONNECT_CUSTOMERS";
export const REMOVE_FROM_SPECIAL = "REMOVE_FROM_SPECIAL";
export const SAVE_TO_AUTH_TABLE = "SAVE_TO_AUTH_TABLE";
export const FETCH_RADCHECK = "FETCH_RADCHECK";
export const FETCH_RADIUS_STATUS = "FETCH_RADIUS_STATUS";
export const DELETE_FROM_RADIUS = "DELETE_FROM_RADIUS";
export const FETCH_LOCATIONS = 'FETCH_LOCATIONS';
export const FETCH_REGIONS = 'FETCH_REGIONS';
export const EDIT_AGENT = 'EDIT_AGENT';
export const DELETE_AGENT = 'DELETE_AGENT';
export const CHANGE_AGENT_ACCESS = 'CHANGE_AGENT_ACCESS';
export const FETCH_CUSTOMER_PAYMENTS = 'FETCH_CUSTOMER_PAYMENTS';
export const FETCH_CUSTOMER_PAYMENTS_EXPORT = 'FETCH_CUSTOMER_PAYMENTS_EXPORT';
export const FETCH_AGENTS_PAYMENTS = 'FETCH_AGENTS_PAYMENTS';
export const FETCH_AGENTS_PAYMENTS_EXPORT = 'FETCH_AGENTS_PAYMENTS_EXPORT';
export const FETCH_CUSTOMER_PAYMENTS_EXPORT_CREATED = 'FETCH_CUSTOMER_PAYMENTS_EXPORT_CREATED';
export const REQUEST_PIN = 'REQUEST_PIN';
export const GET_ERRORS_RESET = 'GET_ERRORS_RESET';