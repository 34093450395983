import {
    FETCH_DISCONNECT_CUSTOMERS,
    FETCH_SPECIAL_CUSTOMERS,
    FETCH_ALL_DISCONNECT_CUSTOMERS,
    REMOVE_FROM_SPECIAL
} from '../actions/types';

const initialState = {
    allCustomers: [],
    allSpecialList: [],
    allCustomersDisconnect: [],
    removeFromeSpecialResponse: {}
}

export default function (state = initialState, action) {
    switch (action.type) {
        case FETCH_DISCONNECT_CUSTOMERS:
            return {
                allCustomers: action.payload
            }
            case FETCH_SPECIAL_CUSTOMERS:
                return {
                    allSpecialList: action.payload
                }
                case FETCH_ALL_DISCONNECT_CUSTOMERS:
                    return {
                        allCustomersDisconnect: action.payload
                    }
                    case REMOVE_FROM_SPECIAL:
                        return {
                            removeFromeSpecialResponse: action.payload
                        }
                        default:
                            return state;
    }
}