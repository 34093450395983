import {
    FETCH_ADMIN_PAYMENTS,
    EXPORT_ADMIN_PAYMENTS,
    EXPORT_ADMIN_LOCKS
} from '../actions/types';

const initialState = {
    payments: {},
    export: [],
    exportLocks: []
}

export default function (state = initialState, action) {
    switch (action.type) {
        case FETCH_ADMIN_PAYMENTS:
            return {
                ...state,
                payments: action.payload
            }
            case EXPORT_ADMIN_PAYMENTS:
                return {
                    ...state,
                    export: action.payload
                }
            case EXPORT_ADMIN_LOCKS:
                return {
                    ...state,
                exportLocks: action.payload
                }
                default:
                    return state;
    }
}