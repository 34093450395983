import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Swal from "sweetalert2";

import { List } from "../payments";

import {
  fetchPayments,
  fetchPaymentsExport,
  fetchPaymentsExportCreated,
} from "../../actions/customersActions";

import { Error, Preloader, Navbar, Sidebar } from "../common";

class Payments extends Component {
  constructor() {
    super();
    this.state = {
      customerPayments: {},
      mpesaReceipt: "",
      errors: "",
      page: 0,
      limit: 10,
      phone: "",
      preloaderStyle: "d-none",
      count: 0,
      date: "",
      paymentsExport: [],
      paymentsExportCreated: [],
    };
  }

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handlePreloaderStyle = (newStyle) => {
    this.setState({ preloaderStyle: newStyle });
  };

  incrementPage = (e) => {
    e.preventDefault();
    const { limit, page, count, phone, mpesaReceipt } = this.state;
    const totalPages = Math.ceil(count / limit);

    const newPage = page + 1;
    if (newPage <= totalPages) {
      this.setState({ page: newPage });
      this.fetchPayments(newPage, limit, phone, mpesaReceipt);
    }
  };

  decrementPage = (e) => {
    e.preventDefault();
    const { page, limit, phone, mpesaReceipt } = this.state;

    const newPage = page - 1;
    if (newPage >= 0) {
      this.setState({ page: newPage });
      this.fetchPayments(newPage, limit, phone, mpesaReceipt);
    }
  };

  onChangePage = (e) => {
    const { limit, phone, mpesaReceipt } = this.state;
    const newPage = parseInt(e.target.value);
    this.setState({ [e.target.name]: newPage });

    this.fetchPayments(newPage, limit, phone, mpesaReceipt);
  };

  fetchPayments = (page, limit, phone, mpesaReceipt) => {
    this.handlePreloaderStyle("d-block");
    this.props.fetchPayments(page * limit, limit, phone, mpesaReceipt);
  };

  componentDidMount() {
    if (!this.props.auth.isAuthenticated) {
      this.props.history.push("/admin");
    }
    this.fetchPayments(
      this.state.page,
      this.state.limit,
      this.state.phone,
      this.state.mpesaReceipt
    );
  }

  onChangeDate = (e) => {
    this.handlePreloaderStyle("d-block");

    e.preventDefault();
    this.setState({
      [e.target.name]: e.target.value,
    });
    this.props.fetchPaymentsExport(e.target.value);
    this.props.fetchPaymentsExportCreated(e.target.value);
  };

  static getDerivedStateFromProps(nextProps, state) {
    // console.log({ nextProps, state });
    if (nextProps.customerPayments !== state.customerPayments) {
      const newCustomers = nextProps.customerPayments;
      return {
        customerPayments: newCustomers,
        preloaderStyle: "d-none",
      };
    }
    if (nextProps.paymentsExport !== state.paymentsExport) {
      const newCustomers = nextProps.paymentsExport;
      return {
        paymentsExport: newCustomers,
        preloaderStyle: "d-none",
      };
    }
    if (nextProps.paymentsExportCreated !== state.paymentsExportCreated) {
      const newCustomers = nextProps.paymentsExportCreated;
      return {
        paymentsExportCreated: newCustomers,
        preloaderStyle: "d-none",
      };
    }

    if (nextProps.errors.length > 0) {
      return { errorMessage: nextProps.errors };
    }
    return null;
  }

  errorMessageHandler = (newError) => {
    this.setState({ errorMessage: newError });
  };

  searchHandler = (phone, mpesaReceipt) => {
    this.handlePreloaderStyle("d-block");
    this.setState({
      phone: phone,
      mpesaReceipt: mpesaReceipt,
    });

    this.fetchPayments(this.state.page, this.state.limit, phone, mpesaReceipt);
  };

  render() {
    const {
      limit,
      page,
      errorMessage,
      preloaderStyle,
      customerPayments,
      date,
      paymentsExport,
      paymentsExportCreated,
    } = this.state;

    return (
      <React.Fragment>
        <Preloader preloaderStyle={preloaderStyle} />
        <Sidebar />
        <div className="content">
          {/* <Error message={errorMessage} /> */}
          <div className="mt-4">
            <Navbar
              title="Customer Payments"
              toggleSearch={this.toggleSearch}
            />
          </div>
          <List
            page={page}
            limit={limit}
            incrementPage={this.incrementPage}
            decrementPage={this.decrementPage}
            onChangePage={this.onChangePage}
            searchHandler={this.searchHandler}
            errorMessageHandler={this.errorMessageHandler}
            handlePreloaderStyle={this.handlePreloaderStyle}
            onChange={this.onChange}
            customerPayments={customerPayments}
            date={date}
            onChangeDate={this.onChangeDate}
            paymentsExport={paymentsExport}
            paymentsExportCreated={paymentsExportCreated}
          />
        </div>
      </React.Fragment>
    );
  }
}

Payments.propTypes = {
  fetchPayments: PropTypes.func.isRequired,
  fetchPaymentsExport: PropTypes.func.isRequired,
  fetchPaymentsExportCreated: PropTypes.func.isRequired,
  paymentsExport: PropTypes.array.isRequired,
  paymentsExportCreated: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  customerPayments: state.customers.customerPayments,
  errors: state.errors,
  auth: state.auth,
  paymentsExport: state.customers.paymentsExport,
  paymentsExportCreated: state.customers.paymentsExportCreated,
});

export default connect(mapStateToProps, {
  fetchPayments,
  fetchPaymentsExport,
  fetchPaymentsExportCreated,
})(Payments);
