import {
  FETCH_DISCONNECT_CUSTOMERS,
  GET_ERRORS,
  FETCH_SPECIAL_CUSTOMERS,
  FETCH_ALL_DISCONNECT_CUSTOMERS,
  REMOVE_FROM_SPECIAL,
  MAKE_SPECIAL,
} from "./types";

import { axios, createError } from "../utilities";

// get all customers to disconnect
export const getAllCustomers = () => (dispatch) => {
  let url = `automate/disconnect`;

  axios
    .get(url)
    .then((response) => {
      // console.log(response.data);
      dispatch({
        type: FETCH_DISCONNECT_CUSTOMERS,
        payload: response.data,
      });
    })
    .catch((err) => {
      console.log(err.response);
      dispatch(createError(err, GET_ERRORS));
    });
};
// get all special list customers
export const getAllSpecialCustomers = () => (dispatch) => {
  let url = `automate/getSpecial`;

  axios
    .get(url)
    .then((response) => {
      // console.log(response.data);
      dispatch({
        type: FETCH_SPECIAL_CUSTOMERS,
        payload: response.data,
      });
    })
    .catch((err) => {
      console.log(err.response);
      dispatch(createError(err, GET_ERRORS));
    });
};
// get all customers
export const fetchAllCustomers = () => (dispatch) => {
  let url = `automate`;

  axios
    .get(url)
    .then((response) => {
      // console.log(response.data);
      dispatch({
        type: FETCH_ALL_DISCONNECT_CUSTOMERS,
        payload: response.data,
      });
    })
    .catch((err) => {
      console.log(err.response);
      dispatch(createError(err, GET_ERRORS));
    });
};

// change special status
export const changeSpecialStatus = (details) => dispatch => {
  let url = `automate/makeSpecial`;

  axios
    .patch(url, details)
    .then((response) => {
      console.log(response.data);
      dispatch({
        type: REMOVE_FROM_SPECIAL,
        payload: response.data,
      });
    })
    .catch((err) => {
      console.log(err.response);
      dispatch(createError(err, GET_ERRORS));
    });
};


