import React, { Component } from "react";
import Swal from "sweetalert2";

class AddFloat extends Component {
  state = {
    amount: "",
    receiptNumber: "",
  };

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onSubmit = () => {
    const { amount, receiptNumber } = this.state;
    const { id, loggedInUser } = this.props;
    if (amount === "" || receiptNumber === "") {
      Swal.fire("Make sure all fields are not empty", "", "error");
    } else {
      let data = {
        id,
        amount,
        receiptNumber,
        loggedInUser,
      };
      this.props.addFloat(data);
      this.setState({
        amount: "",
        receiptNumber: "",
      });
    }
  };

  render() {
    const { amount, receiptNumber } = this.state;
    const { agentFirstName, agentLastName, id } = this.props;
    return (
      <React.Fragment>
        <button
          type="button"
          className="btn btn-primary btn-sm"
          data-toggle="modal"
          data-target={"#exampleModal" + id}
        >
          Add Float
        </button>
        <div
          className="modal fade"
          id={"exampleModal" + id}
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  {"Add Float to " + agentFirstName + " " + agentLastName}
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <form action="">
                  <div className="input-group mb-3">
                    <div className="input-group-prepend">
                      <span className="input-group-text" id="basic-addon3">
                        Amount
                      </span>
                    </div>
                    <input
                      type="number"
                      className="form-control"
                      id="basic-url"
                      aria-describedby="basic-addon3"
                      name="amount"
                      value={amount}
                      onChange={this.onChange}
                    />
                  </div>
                  <div className="input-group mb-3">
                    <div className="input-group-prepend">
                      <span className="input-group-text" id="basic-addon3">
                        Receipt Number
                      </span>
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      id="basic-url"
                      aria-describedby="basic-addon3"
                      name="receiptNumber"
                      value={receiptNumber}
                      onChange={this.onChange}
                    />
                  </div>
                </form>
              </div>
              <div className="d-flex justify-content-between modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary btn-sm"
                  data-dismiss="modal"
                >
                  Close
                </button>
                <button
                  type="button btn-sm"
                  className="btn btn-primary btn-sm"
                  onClick={this.onSubmit}
                  data-dismiss="modal"
                >
                  Add Float
                </button>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default AddFloat;
