import {
    FETCH_PERSONNEL,
    CREATE_PERSONNEL,
    UPDATE_PERSONNEL
} from '../actions/types';

const initialState = {
    personnel: [],
    personnelCreated: {},
    personnelUpdated: {}
}

export default function (state = initialState, action) {
    switch (action.type) {
        case FETCH_PERSONNEL:
            return {
                ...state,
                personnel: action.payload
            }
            case CREATE_PERSONNEL:
                return {
                    ...state,
                    personnelCreated: action.payload
                }
                case UPDATE_PERSONNEL:
                    return {
                        ...state,
                        personnelUpdated: action.payload
                    }
                    default:
                        return state;
    }
}