import {
  FETCH_ISP_CUSTOMERS,
  FETCH_CUSTOMER_STATEMENT,
  DISCONNECT_CUSTOMER,
  CHANGE_ACCESS_CODE,
  SAVE_TO_AUTH_TABLE,
  FETCH_RADCHECK,
  FETCH_RADIUS_STATUS,
  DELETE_FROM_RADIUS,
  FETCH_CUSTOMER_PAYMENTS,
  FETCH_CUSTOMER_PAYMENTS_EXPORT,
  FETCH_CUSTOMER_PAYMENTS_EXPORT_CREATED,
} from "../actions/types";

const initialState = {
  ispcustomers: {},
  ispstatement: {},
  ispdisconnect: "",
  accessCodeChange: {},
  customersCreated: {},
  customersUpdated: {},
  saveToAuthResponse: {},
  allRadCheck: {},
  radiusStatus: {},
  deleteFromRadResponse: {},
  customerPayments: {},
  paymentsExport: [],
  paymentsExportCreated: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_ISP_CUSTOMERS:
      return {
        ...state,
        ispcustomers: action.payload,
      };
    case FETCH_CUSTOMER_STATEMENT:
      return {
        ...state,
        ispstatement: action.payload,
      };
    case DISCONNECT_CUSTOMER:
      return {
        ...state,
        ispdisconnect: action.payload,
      };
    case CHANGE_ACCESS_CODE:
      return {
        ...state,
        accessCodeChange: action.payload,
      };
    case SAVE_TO_AUTH_TABLE:
      return {
        ...state,
        saveToAuthResponse: action.payload,
      };
    case FETCH_RADCHECK:
      return {
        ...state,
        allRadCheck: action.payload,
      };
    case FETCH_RADIUS_STATUS:
      return {
        ...state,
        radiusStatus: action.payload,
      };
    case DELETE_FROM_RADIUS:
      return {
        ...state,
        deleteFromRadResponse: action.payload,
      };
    case FETCH_CUSTOMER_PAYMENTS:
      return {
        ...state,
        customerPayments: action.payload,
      };
    case FETCH_CUSTOMER_PAYMENTS_EXPORT:
      return {
        ...state,
        paymentsExport: action.payload,
      };
    case FETCH_CUSTOMER_PAYMENTS_EXPORT_CREATED:
      return {
        ...state,
        paymentsExportCreated: action.payload,
      };
    default:
      return state;
  }
}
