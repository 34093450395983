import React from "react";

import { Aside } from ".";

const List = ({
  page,
  limit,
  radiusStatus,
  incrementPage,
  decrementPage,
  onChangePage,
  searchHandler,
  errorMessageHandler,
  deleteFromRad,
}) => {
  let customerCount = page * limit + 1;
  let ispAgents;

  let pages;
  let totalPages = 0;
  let assigned = 0;
  let inProgress = 0;
  let completed = 0;
  let deferred = 0;
  let count = radiusStatus.rows ? radiusStatus.rows : 0;

  if (count > 0) {
    totalPages = Math.ceil(count / limit);

    const allPages = Array.from(Array(totalPages).keys());
    if (allPages.length > 0) {
      pages = allPages.map((pg) => (
        <option key={pg} value={pg}>
          {pg + 1}
        </option>
      ));
    } else {
      pages = null;
    }
  } else {
    pages = null;
  }

  if (radiusStatus.items) {
    if (radiusStatus.items instanceof Array) {
      ispAgents = radiusStatus.items.map((customer, index) => (
        <tr key={index}>
          <td>{customerCount++}</td>
          <td>{customer.username}</td>
          <td>
            {customer.groupname === "disabled"
              ? "Depleted"
              : customer.groupname}
          </td>
          <td>
            <button
              className="btn btn-danger btn-sm"
              onClick={() => deleteFromRad(customer.username)}
            >
              Delete
            </button>
          </td>
        </tr>
      ));
    } else {
      ispAgents = null;
    }
  } else {
    ispAgents = null;
  }
  return (
    <div className="row">
      <div className="col-md-8">
        <div className="card">
          <div className="card-body">
            <div className="d-flex justify-content-between">
              <h5 className="card-title">Remove From Radius</h5>
            </div>
            <div className="table-responsive">
              <table className="table table-bordered table-striped tabe-condensed">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Access Code</th>
                    <th>Group Name</th>
                    <th>Delete</th>
                  </tr>
                </thead>
                <tbody>{ispAgents}</tbody>
              </table>
            </div>
          </div>

          <div className="card-footer">
            <div className="d-flex flex-row-reverse">
              <form noValidate onSubmit={incrementPage}>
                <button className="btn btn-rounded" type="submit">
                  <i className="fas fa-chevron-right" />
                </button>
              </form>
              <form noValidate onSubmit={decrementPage}>
                <button className="btn btn-rounded mx-2" type="submit">
                  <i className="fas fa-chevron-left" />
                </button>
              </form>
              <span className="font-weight-normal">of {totalPages}</span>
              <select
                name="page"
                id="page"
                className="form-control mr-2"
                value={page}
                onChange={onChangePage}
              >
                {pages}
              </select>
              <span className="font-weight-normal mr-2">Page</span>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-4">
        <Aside
          searchHandler={searchHandler}
          errorMessageHandler={errorMessageHandler}
          assigned={assigned}
          inProgress={inProgress}
          deferred={deferred}
          completed={completed}
        />
      </div>
    </div>
  );
};

export default List;
