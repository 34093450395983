import {
    FETCH_ANNOUNCEMENTS,
    CREATE_ANNOUNCEMENT,
    UPDATE_ANNOUNCEMENT,
    DELETE_ANNOUNCEMENT,
    POST_ANNOUNCEMENT,
    CREATE_NOTIFICATION
} from '../actions/types';

const initialState = {
    announcements: {},
    announcementCreated: {},
    announcementUpdated: {},
    annoucementDeleted: {},
    announcementPosted: {},
    notificationCreated: {}
}

export default function (state = initialState, action) {
    switch (action.type) {
        case FETCH_ANNOUNCEMENTS:
            return {
                ...state,
                announcements: action.payload
            }
            case CREATE_ANNOUNCEMENT:
                return {
                    ...state,
                    announcementCreated: action.payload
                }
                 case UPDATE_ANNOUNCEMENT:
                return {
                    ...state,
                    announcementUpdated: action.payload
                }
                 case DELETE_ANNOUNCEMENT:
                return {
                    ...state,
                    annoucementDeleted: action.payload
                }
                 case POST_ANNOUNCEMENT:
                return {
                    ...state,
                    announcementPosted: action.payload
                }
                 case CREATE_NOTIFICATION:
                return {
                    ...state,
                    notificationCreated: action.payload
                }
                default:
                    return state;
    }
}