import {
    FETCH_ANNOUNCEMENTS,
    GET_ERRORS,
    CREATE_ANNOUNCEMENT,
    UPDATE_ANNOUNCEMENT,
    DELETE_ANNOUNCEMENT,
    POST_ANNOUNCEMENT,
    CREATE_NOTIFICATION
} from './types'

import {
    axios,
    createError
} from "../utilities";

// Fetch announcements
export const fetchAnnouncements = (page, limit) => dispatch => {
    let url = `/announcements`;
    // url += `?page=${page}&limit=${limit}`;
    return axios
        .get(url)
        .then(announcement => {
            console.log(announcement.data);
            dispatch({
                type: FETCH_ANNOUNCEMENTS,
                payload: announcement.data
            })
        })
        .catch(err => {
            dispatch(createError(err, GET_ERRORS));
        })
}

//Create Announcement
export const createAnnouncement = (announcementDetails) => dispatch => {
    let url = `announcements`;
    return axios
        .post(url, announcementDetails)
        .then(announcement => {
            dispatch({
                type: CREATE_ANNOUNCEMENT,
                payload: announcement.data
            })
        })
        .catch(err => {
            dispatch(createError(err, GET_ERRORS));
        })
}


//Create Notification
export const createNotification = (NotificationDetails) => dispatch => {
    let url = `devices/notification`;
    return axios
        .post(url, NotificationDetails)
        .then(notification => {
            dispatch({
                type: CREATE_NOTIFICATION,
                payload: notification.data
            })
        })
        .catch(err => {
            dispatch(createError(err, GET_ERRORS));
        })
}


//Edit Announcement
export const updateAnnouncement = (id, announcementDetails) => dispatch => {
    console.log('its getting here');
    let url = `announcements/${id}`;
    return axios
        .patch(url, announcementDetails)
        .then(announcements => {
            dispatch({
                type: UPDATE_ANNOUNCEMENT,
                payload: announcements.data
            })
        })
        .catch(err => {
            dispatch(createError(err, GET_ERRORS));
        })
}

//Edit Announcement
export const postAnnouncement = (id, announcementDetails) => dispatch => {
    console.log('its getting here');
    let url = `announcements/posted/${id}`;
    return axios
        .patch(url, announcementDetails)
        .then(announcements => {
            dispatch({
                type: POST_ANNOUNCEMENT,
                payload: announcements.data
            })
        })
        .catch(err => {
            dispatch(createError(err, GET_ERRORS));
        })
}

//Delete Point
export const deleteAnnouncement = (id) => dispatch => {
    let url = `announcements/${id}`;
    return axios
        .delete(url)
        .then(announcement => {
            dispatch({
                type: DELETE_ANNOUNCEMENT,
                payload: announcement.data
            })
        })
        .catch(err => {
            dispatch(createError(err, GET_ERRORS));
        })
}