import React, { Component } from "react";
import { Sidebar } from "../common";
import { connect } from "react-redux";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/dist/jquery.min.js';
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery';
import axios from 'axios';

import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import * as XLSX from 'xlsx'
import Swal from "sweetalert2";
import { withRouter } from 'react-router-dom';
class MpesaTransactions extends Component {

  constructor(props) {
    super(props);
    this.state = {
      // data: [],

      excelFile: null,
      excelFileError: null,
      excelData: null,
      excelData2: null,
      submitted: false,
      isLoading: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleFile = this.handleFile.bind(this);
    this.handleRawSubmit = this.handleRawSubmit.bind(this);
    this.handleRawFile = this.handleRawFile.bind(this);
    this.handleReconcile = this.handleReconcile.bind(this);
  }

  componentDidMount() {
    if (!this.props.auth.isAuthenticated) {
      this.props.history.push("/admin");
    }
  }

  componentDidUpdate() {
    if (this.state.submitted) {
      this.initializeDataTable();
    }
  }

  initializeDataTable() {  
    $(document).ready(function () {
      $('#transTb').DataTable();
    });
  }

  // handle File
  handleRawFile = (e) => {
    let selectedFile = e.target.files[0];
    if (selectedFile) {
      let reader = new FileReader();
      reader.readAsArrayBuffer(selectedFile);
      reader.onload = (e) => {
        this.setState({
          excelFileError: null,
          excelFile: e.target.result,
        });
        const workbook = XLSX.read(this.state.excelFile, { type: 'buffer' });
        const worksheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[worksheetName];
        const mydata = XLSX.utils.sheet_to_json(worksheet);
        this.setState({
          excelData2: mydata,
        });
      };
    } else {
      console.log('please select your file');
    }
  };

   handleRawSubmit = (e) => {
    e.preventDefault()
    this.setState({ isLoading: true });
    const getHarshedPhoneUrl = process.env.REACT_APP_BACKEND + "/transactions/save_imported_trans"
        axios.post(getHarshedPhoneUrl,{
          transactions: this.state.excelData2            
        })
        .then(function (response) {
          console.log(response)
            Swal.fire({
              icon: "success",
              title: "Success!",
              text: `Your data has been added successfully`,
            });
            //window.location.href = '/reconciliations';
        })
        .catch(function (error) {
            // handle error
            console.log(error.response);
            Swal.fire({
              icon: "error",
              title: "Error!",
              text: "Failed!!",
              customClass: {
                title: 'text-red'
              }
            });
        })
        .finally(() => {
          this.setState({ isLoading: false });
          this.closeModal();
        });
  };

  handleFile = (e) => {
    let selectedFile = e.target.files[0];
    if (selectedFile) {
      let reader = new FileReader();
      reader.readAsArrayBuffer(selectedFile);
      reader.onload = (e) => {
        this.setState({
          excelFileError: null,
          excelFile: e.target.result,
        });
      };
    } else {
      console.log('please select your file');
    }
  };

  // submit function
  handleSubmit = (e) => {
    e.preventDefault();
    if (this.state.excelFile !== null) {
      const workbook = XLSX.read(this.state.excelFile, { type: 'buffer' });
      const worksheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[worksheetName];
      const mydata = XLSX.utils.sheet_to_json(worksheet);
      this.setState({
        excelData: mydata,
      });
    } else {
      this.setState({
        excelData: null,
      });
    }
    this.setState({ submitted: true });
  };

  handleReconcile = (e) => {
    e.preventDefault();
    this.setState({ isLoading: true });
    const reconcileUrl = process.env.REACT_APP_BACKEND + "/transactions/reconcile"
        axios.post(reconcileUrl,{
          data: this.state.excelData            
        })
        .then(function (response) {
          console.log(response)

          const responseDataCount = response.data.count;
          if(response.data.message = 'Success'){
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: `Your transaction ${responseDataCount} has been added successfully`,
        });

          }      
        })
        .catch(function (error) {
            // handle error
            console.log(error);
            //alert(error.response.data.message)
            Swal.fire({
              icon: "error",
              title: "Error!",
              text: "Failed!!",
              customClass: {
                title: 'text-red'
              }
            });
        })
        .finally(() => {
          this.setState({ isLoading: false });
        });
  }

  closeModal = () => {
    // Access the Popup component using a ref
    if (this.popupRef) {
      this.popupRef.close();
    }
  }

  render() {
    const { submitted } = this.state;
    const { isLoading } = this.state;

    return (
      <React.Fragment>
        <Sidebar />
        <div className="content">
          <div className="mt-4">
            <h4>Mpesa Transactions</h4>
          </div>
        {submitted ? 
          <div className='form' style={{float:'right'}}>
            <button type='submit' className='btn btn-success' style={{marginTop:5+'px', marginRight: '15px'}} onClick={this.handleReconcile.bind(this)} disabled={isLoading}>{isLoading ? 'Loading...' : 'Reconcile'}</button>
          </div> : (
          <div className='form' style={{float:'right'}}>
                <Popup
                      ref={ref => (this.popupRef = ref)}
                      trigger={<button className="btn btn-primary">Upload transactions</button>}
                      position="right center"
                      modal
                      closeOnDocumentClick
                      contentStyle={{ width: '310px', height: 'auto', padding: '20px' }}
                    >
                    <div className='form'>
                      <form className='form-group' autoComplete="off"
                      onSubmit={this.handleRawSubmit}>
                        <label><h6>Upload Transactions with Harshed Phone Numbers</h6></label>
                        <br></br>
                        <input type='file' className='form-control'
                        onChange={this.handleRawFile} required></input>                  
                        
                        <button type='submit' className='btn btn-success'
                        style={{marginTop:5+'px'}}>Submit</button>
                      </form>
                    </div>
                </Popup>

                <span className="button2two">
                <Popup
                      trigger={<button className="btn btn-primary">Import transactions</button>}
                      position="right center"
                      modal
                      closeOnDocumentClick
                      contentStyle={{ width: '310px', height: 'auto', padding: '20px' }}
                    >
                    <div className='form'>
                      <form className='form-group' autoComplete="off"
                      onSubmit={this.handleSubmit}>
                        <label><h6>Upload Transactions to Reconsile</h6></label>
                        <br></br>
                        <input type='file' className='form-control'
                        onChange={this.handleFile} required></input>                  
                        
                        <button type='submit' className='btn btn-success'
                        style={{marginTop:5+'px'}}>Submit</button>
                      </form>
                    </div>
                </Popup>
              </span>
          </div>
        )}

          <br></br>

          <div className="mt-4">
            <div className="container">
              {this.state.excelData === null ? <div><p>No file selected</p></div>:
                <table id="transTb" class="display">
                    <thead>
                        <tr>
                            <th>Receipt No.</th>
                            <th>Initiation Time</th>
                            <th>Completion Time</th>
                            <th>Paid Amount</th>
                            <th>Phone No</th>
                            <th>Balance</th>
                        </tr>
                    </thead>
                    {this.state.excelData!==null && (
                      <tbody>
                          {this.state.excelData.map(trans => {
                            return (
                              <tr key={trans.ReceiptNo}>
                                <td>
                                  <>{trans.ReceiptNo}</>
                                </td>
                                <td>
                                  <>{trans.InitiationTime}</>
                                </td>
                                <td>
                                  <>{trans.CompletionTime}</>
                                </td>
                                <td>
                                  <>{trans.PaidIn}</>
                                </td>
                                <td>
                                  <>{trans.OtherPartyInfo}</>
                                </td>
                                <td>
                                  <>{trans.Balance}</>
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                      )}
                    <tfoot>
                        <tr>
                            <th>Receipt No.</th>
                            <th>Initiation Time</th>
                            <th>Completion Time</th>
                            <th>Paid Amount</th>
                            <th>Phone No</th>
                            <th>Balance</th>
                        </tr>
                    </tfoot>
                </table>
                }
                </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

MpesaTransactions.propTypes = {
  
};

const mapStateToProps = (state) => ({
    errors: state.errors,
    auth: state.auth,
  });
  

  //export default connect(mapStateToProps, withRouter, {
  //})(MpesaTransactions);
export default connect(mapStateToProps, {})(withRouter(MpesaTransactions));
