import {
    FETCH_ADMIN_PAYMENTS,
    EXPORT_ADMIN_PAYMENTS,
    GET_ERRORS,
    EXPORT_ADMIN_LOCKS
} from './types';

import {
    axios,
    createError
} from "../utilities";

export const fetchAdminPayments = (page, limit, sdate, edate, customer_phone, agent_phone, payment_type) => dispatch => {

    let url = (localStorage.agentJwtToken) ? `/payments/summary/agent` : `/payments/summary`;

    url += `?page=${page}&limit=${limit}`;

    if (sdate !== '') {
        url += `&sdate=${sdate}`
    }
    if (edate !== '') {
        url += `&edate=${edate}`
    }
    if (customer_phone !== '') {
        url += `&customer_phone=${customer_phone}`
    }
    if (agent_phone !== '') {
        url += `&agent_phone=${agent_phone}`
    }
    if (payment_type !== '') {
        url += `&payment_type=${payment_type}`
    }
    axios
        .get(url)
        .then(payments => {
            dispatch({
                type: FETCH_ADMIN_PAYMENTS,
                payload: payments.data
            })
        })
        .catch(err => {
            dispatch(createError(err, GET_ERRORS));
        })
}

export const exportPayments = (startDate, endDate, customer_phone, agent_phone, payment_type) => dispatch => {
    // console.log("its getting to this point");
    let url = `/payments/export?stub=1`;
    if (startDate !== '') {
        url += `&sdate=${startDate}`
    }
    if (endDate !== '') {
        url += `&edate=${endDate}`
    }
    if (customer_phone !== '') {
        url += `&customer_phone=${customer_phone}`
    }
    if (agent_phone !== '') {
        url += `&agent_phone=${agent_phone}`
    }
    if (payment_type !== '') {
        url += `&payment_type=${payment_type}`
    }
    axios
        .get(url)
        .then(payments => {
            dispatch({
                type: EXPORT_ADMIN_PAYMENTS,
                payload: payments.data
            })
        })
        .catch(err => {
            console.log(err);
            dispatch(createError(err, GET_ERRORS));
        })
}

//eport locks
export const exportLocks = () => dispatch => {
    // console.log("its getting to this point");
    let url = `/commissions/export`;
    axios
        .get(url)
        .then(commissions => {
            // console.log(commissions.data);
            dispatch({
                type: EXPORT_ADMIN_LOCKS,
                payload: commissions.data
            })
        })
        .catch(err => {
            console.log(err);
            dispatch({
                type: GET_ERRORS,
                payload: "Oops something went wrong. Please try again."
            });
        })
}