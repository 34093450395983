import React, { Component } from 'react';
import { Pie } from "react-chartjs-2";

class AdminChartSummary extends Component {    
    constructor(props){
        super(props);
        this.state = {
            pending:0,
            completed: 0
        };
    }

    render() {  
        const completed = this.props.completed;
        const pending=  this.props.totalDailyAssignedTarget - completed;
        const pieData = {
            labels: ["Pending", "Completed"], 
            datasets: [
                {
                    label: "Daily Target",
                    data: [pending, completed],
                    backgroundColor: ["rgb(255, 99, 132)", "rgb(255, 205, 86)"]
                }
            ]
        }
        return ( 
           <Pie data={pieData} />
         );
    }
}
 
export default AdminChartSummary;