import isEmpty from "../validation/is-empty";
import {
  SET_CURRENT_PERSONNEL,
  SET_PASSWORD_RESET,
  REQUEST_PIN,
  GET_ERRORS_RESET,
} from "../actions/types";

const initialState = {
  isAuthenticated: false,
  personnel: {},
  requestPin: {},
  resetErrors: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_CURRENT_PERSONNEL:
      return {
        ...state,
        isAuthenticated: !isEmpty(action.payload),
        personnel: action.payload,
      };
    case SET_PASSWORD_RESET:
      return {
        ...state,
        reset: true,
      };
    case REQUEST_PIN:
      return {
        ...state,
        requestPin: action.payload,
      };

    case GET_ERRORS_RESET:
      return {
        ...state,
        resetErrors: action.payload,
      };

    default:
      return state;
  }
}
