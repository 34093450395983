import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { addCustomer } from "../../actions/agentsActions";

class AddNewCustomerModal extends Component {
  constructor() {
    super();
    this.state = {
      phone: "",
      location: "",
      customer: {}
    };
  }

  onChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  onClose = e => {
    this.props.onClose && this.props.onClose(e);
  };

  addNewCustomer = e => {
    e.preventDefault();
    // this.props.handlePreloaderStyle("d-block");
    const customer = {
      phone: this.state.phone,
      location: this.state.location
    };
    this.props.addCustomer(customer);
  };

  componentWillReceiveProps(nextProps) {
    this.props.handlePreloaderStyle("d-none");
    if (nextProps.customer) {
      this.props.handleAddCustomer(nextProps.customer.message);
    }
  }

  render() {
    const { phone, location } = this.state;
    return (
      <React.Fragment>
        <div
          className="modal fade"
          id="lockCustomer"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Create New Customer
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <form noValidate>
                <div className="modal-body">
                  <div className="form-group">
                    <label htmlFor="phone">Customer Phone Number</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Phone Number"
                      value={phone}
                      onChange={this.onChange}
                      name="phone"
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="location">Customer's Location</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Location"
                      value={location}
                      onChange={this.onChange}
                      name="location"
                    />
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-secondary btn-sm"
                      data-dismiss="modal"
                    >
                      Close
                    </button>
                    <button
                      type="button"
                      onClick={this.addNewCustomer}
                      className="btn btn-sm btn-primary"
                      data-dismiss="modal"
                    >
                      Add New Customer
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

AddNewCustomerModal.propTypes = {
  addCustomer: PropTypes.func.isRequired,
  handleAddCustomer: PropTypes.func.isRequired,
  handlePreloaderStyle: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  customer: state.customers.customer
});

export default connect(
  mapStateToProps,
  { addCustomer }
)(AddNewCustomerModal);
