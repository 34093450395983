import React, { Component } from "react";
import PropTypes from "prop-types";

import { InputFields, DateRangePickerComponent } from "../common";

class SearchLocks extends Component {
  render() {
    const {
      phone,
      agent_phone,
      startDate,
      endDate,
      focusedInput,
      startDateId,
      endDateId
    } = this.props;

    return (
      <React.Fragment>
        <div className="card">
          <div className="card-body">
            <h5 className="card-title">Search</h5>
            <form
              noValidate
              onSubmit={e =>
                this.props.handleSearch(
                  e,
                  phone,
                  agent_phone,
                  startDate,
                  endDate
                )
              }
            >
              <div className="form-group">
                <label className="control-label mb-10 text-left">Date</label>
                <DateRangePickerComponent
                  startDate={startDate}
                  endDate={endDate}
                  startDateId={startDateId}
                  endDateId={endDateId}
                  focusedInput={focusedInput}
                  handleDateChange={this.props.handleDateChange}
                  handleFocusChange={this.props.handleFocusChange}
                  isOutsideRange={this.props.isOutsideRange}
                />
              </div>
              <InputFields
                type="number"
                name="phone"
                onChange={this.props.onChangeNumber}
                value={phone}
                label="Customer Phone"
              />

              <InputFields
                type="number"
                name="agent_phone"
                onChange={this.props.onChangeNumber}
                value={agent_phone}
                label="Agent Phone"
              />
              <div className="mt-3">
                <button
                  onClick={this.props.handleCloseSearch}
                  className="btn btn-default btn-sm"
                >
                  <i className="fa fa-times" /> Close Search
                </button>
                <button type="submit" className="btn btn-warning btn-sm">
                  <i className="fa fa-search" />
                  Search
                </button>
              </div>
            </form>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

SearchLocks.propTypes = {
  handleSearch: PropTypes.func.isRequired,
  handleCloseSearch: PropTypes.func.isRequired,
  onChangeNumber: PropTypes.func.isRequired,
  handleDateChange: PropTypes.func.isRequired,
  handleFocusChange: PropTypes.func.isRequired,
  isOutsideRange: PropTypes.func.isRequired
};

export default SearchLocks;
