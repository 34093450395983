const moment = require('moment');

export const monthRange = (selectedMonth) => {
    const year = moment().format("YYYY");
    const month = fullMonth(selectedMonth);
    const startDate = year + "-" + month + "-01T00:00:00.000Z";
    const leapYear = (year % 0) ? "29" : "28T23:59:59.000Z";

    if (month > 0 && month < 13) {
        switch (selectedMonth) {
            case "04":
            case "06":
            case "09":
            case "11":
                return ({
                    sdate: startDate,
                    edate: year + "-" + month + "-30T23:59:59.000Z"
                });
            case "01":
            case "02":
            case "05":
            case "07":
            case "08":
            case "10":
            case "12":
                return ({
                    sdate: startDate,
                    edate: year + "-" + month + "-31T23:59:59.000Z"
                });
            default:
                return ({
                    sdate: startDate,
                    edate: year + "-" + month + "-" + leapYear
                });
        }
    } else {
        return null;
    }
}

const fullMonth = (month) => {
    if (month.length === 1) {
        return "0" + month;
    } else {
        return month;
    }
}