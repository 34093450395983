import React, { Component } from 'react';
// import NavBar from '../layout/Navbar';

class NotFound extends Component {
    state = {  }
    render() { 
        return ( 
            <div className="container" style={{margin: '210px' }}>
                <h1 className="display-4">404 Page Not Found</h1>
                <p className="lead">Sorry that page doesn't exist, ask the admin for more information</p>
            </div>
         );
    }
}
 
export default NotFound;