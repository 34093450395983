import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import LoginTextFieldGroup from "../common/LoginTextFieldGroup";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { resetAgentPassowrd } from "../../actions/authActions";

class MerchantResetPassword extends Component {
  constructor() {
    super();
    this.state = {
      phone: "",
      password: "",
      confirmPassword: "",
      errors: {}
    };

    this.onSubmit = this.onSubmit.bind(this);
  }

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.agentAuth.reset) {
      this.props.history.push("/merchant");
    }

    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }
  }

  onSubmit(e) {
    e.preventDefault();
    if (this.state.password !== this.state.confirmPassword) {
      this.setState({ errors: { password: "Password do not match" } });
    } else {
      const agent = {
        phone: this.state.phone,
        password: this.state.password
      };

      this.props.resetAgentPassowrd(agent, this.props.history);
    }
  }

  render() {
    const { errors } = this.state;
    return (
      <div className="mt-5 d-flex justify-content-center">
        <div className="mt-5 p-3 login-container shadow">
          <div className="login-header">
            <img
              src={require("../../assets/img/mawingu.png")}
              alt="Mawingu Logo"
            />
            <h1 className="my-3">Reset Password</h1>
          </div>
          <div className="login-body">
            <form noValidate onSubmit={this.onSubmit}>
              <LoginTextFieldGroup
                icon="fa fa-phone"
                type="phone"
                name="phone"
                value={this.state.phone}
                onChange={this.onChange}
                error={errors.phone}
                id="phone"
                placeholder="Enter your phone"
                style={{ padding: "10px", fontSize: "17px" }}
              />
              <LoginTextFieldGroup
                icon="fa fa-lock"
                type="password"
                name="password"
                value={this.state.password}
                onChange={this.onChange}
                error={errors.password}
                id="password"
                placeholder="Enter your password"
                style={{ padding: "10px", fontSize: "17px" }}
              />
              <LoginTextFieldGroup
                icon="fa fa-lock"
                type="password"
                name="confirmPassword"
                value={this.state.confirmPassword}
                onChange={this.onChange}
                error={errors.password}
                id="password"
                placeholder="Confirm password"
                style={{ padding: "10px", fontSize: "17px" }}
              />
              <button type="submit" className="btn btn-primary">
                Reset Password
              </button>
              <div className="text-center mt-3">
                <Link to="/merchant" style={{ color: "white" }}>
                  Login
                </Link>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

MerchantResetPassword.propTypes = {
  resetAgentPassowrd: PropTypes.func.isRequired,
  agentAuth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    agentAuth: state.agentAuth,
    errors: state.errors
  };
};

export default connect(
  mapStateToProps,
  { resetAgentPassowrd }
)(withRouter(MerchantResetPassword));
