import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { loginMerchant } from "../../actions/authActions";
import LoginTextFieldGroup from "../common/LoginTextFieldGroup";
import Preloader from "../common/Preloader";

class MerchantLogin extends Component {
  constructor() {
    super();
    this.state = {
      phone: "",
      password: "",
      errors: {},
      preloaderStyle: "d-none"
    };
    this.onSubmit = this.onSubmit.bind(this);
  }

  handlePreloaderStyle = newStyle => {
    this.setState({
      preloaderStyle: newStyle
    });
  };

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  componentDidMount() {
    if (this.props.agentAuth.isAuthenticated) {
      this.props.history.push("/locks");
    }
  }

  componentWillReceiveProps(nextProps) {
    console.log(nextProps.agentAuth);
    if (nextProps.agentAuth.isAuthenticated) {
      this.props.history.push("/locks");
    }

    if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors
      });
      this.handlePreloaderStyle("d-none");
    }
  }

  onSubmit(e) {
    e.preventDefault();

    this.handlePreloaderStyle("d-block");
    const agent = {
      phone: this.state.phone,
      password: this.state.password
    };

    this.props.loginMerchant(agent, this.props.history);
  }

  render() {
    const { errors, preloaderStyle } = this.state;
    return (
      <React.Fragment>
        <Preloader preloaderStyle={preloaderStyle} />
        <div className="mt-5 d-flex justify-content-center">
          <div className="mt-5 p-3 login-container shadow">
            <div className="login-header">
              <img
                src={require("../../assets/img/mawingu.png")}
                alt="Mawingu Logo"
              />
              <h1 className="my-3">Merchant Login</h1>
            </div>
            <div className="login-body">
              <form noValidate onSubmit={this.onSubmit}>
                <LoginTextFieldGroup
                  icon="fa fa-phone"
                  type="phone"
                  name="phone"
                  value={this.state.phone}
                  onChange={this.onChange}
                  error={errors.phone}
                  id="phone"
                  placeholder="Enter your phone"
                  style={{ padding: "10px", fontSize: "17px" }}
                />
                <LoginTextFieldGroup
                  icon="fa fa-lock"
                  type="password"
                  name="password"
                  value={this.state.password}
                  onChange={this.onChange}
                  error={errors.password}
                  id="password"
                  placeholder="Enter your password"
                  style={{ padding: "10px", fontSize: "17px" }}
                />
                <button type="submit" className="btn btn-primary">
                  Login
                </button>
                <div className="text-center mt-3">
                  <Link to="/merchantResetPassword" style={{ color: "white" }}>
                    Reset Password
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

MerchantLogin.propTypes = {
  loginMerchant: PropTypes.func.isRequired,
  agentAuth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    agentAuth: state.agentAuth,
    errors: state.errors
  };
};

export default connect(
  mapStateToProps,
  { loginMerchant }
)(withRouter(MerchantLogin));
