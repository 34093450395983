import {
  FETCH_PERSONNEL,
  CREATE_PERSONNEL,
  UPDATE_PERSONNEL,
  GET_ERRORS
} from './types';

import {
  axios,
  createError
} from "../utilities";

export const fetchPersonnel = () => dispatch => {
  let url = `personnel`;
  axios
    .get(url)
    .then(peronnel => {
      dispatch({
        type: FETCH_PERSONNEL,
        payload: peronnel.data
      })
    })
    .catch(err => {
      // console.log(err.response);
      dispatch(createError(err, GET_ERRORS));
    })
}

export const createPersonnel = (personnelDetails) => dispatch => {
  let url = `personnel`;
  axios
    .post(url, personnelDetails)
    .then(personnel => {
      dispatch({
        type: CREATE_PERSONNEL,
        payload: personnel.data
      })
    })
    .catch(err => {
      // console.log(err.response);
      dispatch(createError(err, GET_ERRORS));
    })
}

export const updatePersonnel = (personnel_id, personelData) => dispatch => {
  // console.log(personelData);
  axios
    .patch(`personnel/${personnel_id}`, personelData)
    .then(personnel => {
      dispatch({
        type: UPDATE_PERSONNEL,
        payload: personnel.data
      })
    })
    .catch(err => {
      dispatch(createError(err, GET_ERRORS));
    })
}