import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import NavBar from "../layout/Navbar";
import { fetchSMS, fetchSMSForExport } from "../../actions/smsActions";
import { Preloader, Pagination, ExportButton } from "../common";
import moment from "moment";

class Messages extends Component {
  state = {
    messages: [],
    allMessages: [],
    page: 0,
    limit: 10,
    count: 0,
    content: "",
    receiver: "",
    date: "",
    messageCount: 0,
    preloaderStyle: "d-none",
  };

  handlePreloaderStyle = (newStyle) => {
    this.setState({ preloaderStyle: newStyle });
  };

  handleSearch = (e) => {
    e.preventDefault();
    const { page, limit, receiver, content, date } = this.state;
    this.fetchSMS(page, limit, receiver, content, date);
  };

  handleCloseSearch = (e) => {
    e.preventDefault();
    this.setState({
      receiver: "",
      content: "",
      date: "",
    });
    const { page, limit } = this.state;
    this.fetchSMS(page, limit, "", "", "");
  };

  handleIncrementPage = (e) => {
    e.preventDefault();
    const { page, limit, count, receiver, content, date } = this.state;
    // console.log(page);
    const totalPages = Math.ceil(count / limit);
    const newPage = page + 1;

    if (newPage < totalPages) {
      this.setState({ page: newPage, messageCount: newPage * limit });
      this.fetchSMS(newPage, limit, receiver, content, date);
    }
  };

  handleDecrementPage = (e) => {
    e.preventDefault();
    const { page, limit, receiver, content, date } = this.state;
    const newPage = page - 1;
    if (newPage >= 0) {
      this.setState({ page: newPage, messageCount: newPage * limit });
      this.fetchSMS(newPage, limit, receiver, content, date);
    }
  };

  handleOnChangePage = (e) => {
    const { limit, receiver, content, date } = this.state;
    const newPage = parseInt(e.target.value);
    this.setState({
      [e.target.name]: newPage,
      messageCount: newPage * limit,
    });
    this.fetchSMS(newPage, limit, receiver, content, date);
  };

  fetchSMS = (page, limit, receiver, content, date) => {
    this.handlePreloaderStyle("d-block");
    this.props.fetchSMS(page, limit, receiver, content, date);
    this.props.fetchSMSForExport(receiver, content, date);
  };

  componentDidMount() {
    const { page, limit, receiver, content, date } = this.state;
    this.fetchSMS(page, limit, receiver, content, date);
  }
  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  componentWillReceiveProps(nextProps) {
    this.handlePreloaderStyle("d-none");
    if (nextProps.messages) {
      console.log(nextProps.messages);
      this.setState({
        messages: nextProps.messages.items,
        count: nextProps.messages.rows,
      });
    }
    if (nextProps.allMessages) {
      this.setState({
        allMessages: nextProps.allMessages,
      });
    }
  }

  render() {
    const {
      messages,
      preloaderStyle,
      page,
      limit,
      count,
      messageCount,
      receiver,
      allMessages,
      date,
    } = this.state;

    let messageCountAll = messageCount + 1;

    const exportMessages = allMessages.map((message) => {
      const data = {
        Receiver: message.message_receiver,
        Content: message.message,
        Apllication: message.application_name,
        Date: moment(message.created_at).format("YYYY-MM-DD"),
      };
      return data;
    });

    // const countMessage = messageCount++;

    const allMessagesDisplay =
      messages instanceof Array
        ? messages.map((message) => (
            <tr key={message.id}>
              <td>{messageCountAll++}</td>
              <td>{message.message_receiver}</td>
              <td>{message.message}</td>
              {message.campaign_id === 0 ? <td>Single</td> : <td>Bulk</td>}
              {message.status_id === 0 ? (
                <td>Pending Approval</td>
              ) : message.status_id === 1 ? (
                <td>Queued</td>
              ) : (
                <td>Sent</td>
              )}
              <td>{message.application_name}</td>
              <td>
                {moment.tz(message.created_at, "Africa/Nairobi").format("LLLL")}
              </td>
            </tr>
          ))
        : null;
    return (
      <React.Fragment>
        <NavBar />
        <div className="container-fluid">
          <Preloader preloaderStyle={preloaderStyle} />
          <div className="card">
            <div className="card-body">
              <div className="d-flex justify-content-between">
                <h5 className="card-title">Messages</h5>
                <ExportButton
                  data={exportMessages}
                  filename={
                    moment().format("DD/MM/YYYY H:mm:ss") + " Messages.csv"
                  }
                />
                <div class="d-flex flex-row input-group col-md-5 mr-0">
                  <input
                    type="date"
                    name="date"
                    className="form-control"
                    placeholder="Select date"
                    value={date}
                    onChange={this.onChange}
                  />
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Receiver's phone"
                    aria-label="Receiver's phone with two button addons"
                    aria-describedby="button-addon4"
                    name="receiver"
                    value={receiver}
                    onChange={this.onChange}
                  />
                  <div class="input-group-append" id="button-addon4">
                    <button
                      class="btn btn-outline-primary"
                      type="button"
                      onClick={this.handleSearch}
                    >
                      Search
                    </button>
                    <button
                      class="btn btn-outline-danger"
                      type="button"
                      onClick={this.handleCloseSearch}
                    >
                      Reset
                    </button>
                  </div>
                </div>
              </div>

              <div className="table-responsive">
                <table className="table table-bordered table-striped tabe-condensed">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Receiver</th>
                      <th>Message</th>
                      <th>Campaign</th>
                      <th>Status</th>
                      <th>Appplication</th>
                      <th>Created</th>
                    </tr>
                  </thead>
                  <tbody>{allMessagesDisplay}</tbody>
                </table>
              </div>
              {/* PAGINATION */}
              {count > 10 ? (
                <Pagination
                  page={page}
                  limit={limit}
                  count={count}
                  handleDecrementPage={this.handleDecrementPage}
                  handleIncrementPage={this.handleIncrementPage}
                  handleOnChangePage={this.handleOnChangePage}
                />
              ) : null}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

Messages.propTypes = {
  messages: PropTypes.array.isRequired,
  fetchSMS: PropTypes.func.isRequired,
  fetchSMSForExport: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  messages: state.sms.messages,
  auth: state.auth,
  allMessages: state.sms.allMessages,
});

export default connect(mapStateToProps, { fetchSMS, fetchSMSForExport })(
  Messages
);
