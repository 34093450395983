import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

const TextAreaFields = ({ value, name, onChange, error, label }) => {
  return (
    <div className="form-group">
      <label htmlFor="exampleInputPassword1">{label}</label>
      <textarea
        className={classnames("form-control", {
          "is-invalid": error
        })}
        placeholder={label}
        onChange={onChange}
        name={name}
        value={value}
      ></textarea>
      {error && <div className="invalid-feedback">{error}</div>}
    </div>
  );
};

TextAreaFields.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired
};

export default TextAreaFields;
