import React, { Component } from "react";
import PropTypes from "prop-types";

class PersonnelSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      adminTaskSummary: [],
      personnelTransactions: []
    };
  }

  componentDidMount() {
    this.setState({
      adminTaskSummary: this.props.adminTaskSummary,
      personnelTransactions: this.props.personnelTransactions
    });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.adminTaskSummary) {
      this.setState({
        personnelTransactions: nextProps.personnelTransactions,
        adminTaskSummary: nextProps.adminTaskSummary
      });
    }
  }

  onClick = () => {
    this.props.onClick();
  };

  showPersonnelForm = () => {
    this.props.showAddPersonnel();
  };

  EditPersonnel = () => {
    this.props.showEditPersonnel();
  };

  render() {
    const { adminTaskSummary, personnelTransactions } = this.state;

    for (let r = 0; r < adminTaskSummary.length; r++) {
      let transactionAmount = 0;
      for (let s = 0; s < personnelTransactions.length; s++) {
        if (
          adminTaskSummary[r].personnel_id ===
          personnelTransactions[s].personnel_id
        ) {
          transactionAmount = personnelTransactions[s].amount;
          break;
        }
      }
      adminTaskSummary[r].purchased = transactionAmount;
    }

    const taskSummary = adminTaskSummary.map(personnel => (
      <tr key={personnel.personnel_id}>
        <td>
          {personnel.personnel_first_name} {personnel.personnel_other_name}
        </td>
        <td>{personnel.assigned}</td>
        <td>{personnel.in_progress}</td>
        <td>{personnel.completed}</td>
        <td>{personnel.deferred}</td>
        <td>{personnel.purchased}</td>
      </tr>
    ));

    return (
      <React.Fragment>
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-md-4">
                <h5 className="card-title">Daily Completed Tasks</h5>
              </div>
              <div className="col-md-8">
                <button
                  type="button"
                  className="btn btn-primary btn-sm float-right"
                  data-toggle="modal"
                  data-target="#reassignTasks"
                >
                  Bulk Reassign
                </button>
              </div>
            </div>
            <div className="table-responsive">
              <table className="table table-bordered table-striped table-condensed">
                <thead>
                  <tr>
                    <th />
                    <th>Assigned</th>
                    <th>In Progress</th>
                    <th>Completed</th>
                    <th>Deferred</th>
                    <th>Purchased</th>
                  </tr>
                </thead>
                <tbody>{taskSummary}</tbody>
              </table>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
PersonnelSummary.propTypes = {
  adminTaskSummary: PropTypes.array.isRequired,
  personnelTransactions: PropTypes.array.isRequired
};

export default PersonnelSummary;
