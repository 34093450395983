import React, { Component } from "react";
import { SingleDatePicker } from "react-dates";
import PropTypes from "prop-types";

class DatePicker extends Component {
  render = () => (
    <SingleDatePicker
      date={this.props.date}
      focused={this.props.focused}
      onDateChange={this.props.handleDateChange}
      onFocusChange={this.props.handleFocusChange}
      id={this.props.dateId}
      isOutsideRange={() => false}
    />
  );
}
DatePicker.propTypes = {
  //   date: PropTypes.object.isRequired,
  dateId: PropTypes.string.isRequired,
  focused: PropTypes.bool.isRequired,
  handleDateChange: PropTypes.func.isRequired,
  handleFocusChange: PropTypes.func.isRequired
};
export default DatePicker;
