import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Swal from "sweetalert2";

import Navbar from "./Navbar";

import { List } from "../agents";

import {
  fetchAgents,
  addFloat,
  addAgent,
  fetchLocations,
  fetchRegions,
  editAgent,
  changeFreeInternetUserName,
} from "../../actions/agentsActions";

import { Error, Preloader } from "../common";

class Agents extends Component {
  constructor() {
    super();
    this.state = {
      agents: {},
      radacct: [],
      payments: [],
      customer: {},
      disconnect: "",
      errors: "",
      page: 0,
      limit: 10,
      phone: "",
      status: "",
      accesscode: "",
      errorMessage: "",
      assigned: 0,
      inProgress: 0,
      completed: 0,
      deferred: 0,
      preloaderStyle: "d-none",
      currentPage: "list",
      first_name: "",
      last_name: "",
      agent_phone: "",
      username: "",
      password: "123456",
      email: "",
      addAgentSuccess: "",
      location: "",
      locations: [],
      region: "",
      regions: [],
      changeAccessResponse: {},
    };
  }

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handlePreloaderStyle = (newStyle) => {
    this.setState({ preloaderStyle: newStyle });
  };

  incrementPage = (e) => {
    e.preventDefault();
    const { limit, page, count, phone, status, accesscode } = this.state;
    const totalPages = Math.ceil(count / limit);

    const newPage = page + 1;
    if (newPage <= totalPages) {
      this.setState({ page: newPage });
      this.fetchAgents(newPage, limit, phone, status, accesscode);
    }
  };

  decrementPage = (e) => {
    e.preventDefault();
    const { page, limit, phone, status, accesscode } = this.state;

    const newPage = page - 1;
    if (newPage >= 0) {
      this.setState({ page: newPage });
      this.fetchAgents(newPage, limit, phone, status, accesscode);
    }
  };

  onChangePage = (e) => {
    const { limit, phone, status, accesscode } = this.state;
    const newPage = parseInt(e.target.value);
    this.setState({ [e.target.name]: newPage });

    this.fetchAgents(newPage, limit, phone, status, accesscode);
  };

  fetchAgents = (page, limit, phone, status, accesscode) => {
    this.handlePreloaderStyle("d-block");
    this.props.fetchAgents(page * limit, limit, phone, status, accesscode);
  };

  onSelectOptionCounty = (value, e) => {
    // this.handlePreloaderStyle("d-block");
    this.setState({
      // county: value,
      [e.name]: value,
    });
  };

  componentDidMount() {
    if (!this.props.auth.isAuthenticated) {
      this.props.history.push("/admin");
    }
    this.fetchAgents(
      this.state.page,
      this.state.limit,
      this.state.phone,
      this.state.status,
      this.state.accesscode
    );
    this.props.fetchLocations();
    this.props.fetchRegions();
  }
  saveAgent = (e) => {
    e.preventDefault();
    const {
      first_name,
      last_name,
      agent_phone,
      username,
      password,
      email,
      location,
    } = this.state;
    if (first_name === "" || agent_phone === "" || username === "") {
      Swal.fire(
        "Error",
        "First Name, Phone and Username are required",
        "error"
      );
    } else {
      this.handlePreloaderStyle("d-block");

      let data = {
        first_name,
        last_name,
        cell: agent_phone,
        username,
        password,
        email,
        nas_id: location,
      };
      this.props.addAgent(data);
      this.setState({
        first_name: "",
        last_name: "",
        agent_phone: "",
        username: "",
        email: "",
      });
    }
  };

  changeFreeInternetUserName = (user) => {
    let data = {
      accesscode: user,
    };
    Swal.fire({
      title: "Are you sure?",
      text: "Please confirm this change of access code request",
      showCancelButton: true,
      confirmButtonText: "Yes, change access code",
      cancelButtonText: "No, I made a mistake",
    }).then((result) => {
      if (result.value) {
        this.handlePreloaderStyle("d-block");
        this.props.changeFreeInternetUserName(data);
        this.setState({
          preloaderStyle: "d-block",
        });
      } else {
        this.setState({ preloaderStyle: "d-none" });
      }
    });
  };

  static getDerivedStateFromProps(nextProps, state) {
    // console.log({ nextProps, state });
    if (nextProps.agents !== state.agents) {
      const newCustomers = nextProps.agents;
      return {
        agents: newCustomers,
        preloaderStyle: "d-none",
      };
    }
    if (nextProps.locations !== state.locations) {
      const allCounties = nextProps.locations;
      return {
        locations: allCounties,
        preloaderStyle: "d-none",
      };
    }
    if (nextProps.regions !== state.regions) {
      const allRegions = nextProps.regions;
      return {
        regions: allRegions,
        preloaderStyle: "d-none",
      };
    }
    if (nextProps.changeAccessResponse !== state.changeAccessResponse) {
      const allRegions = nextProps.changeAccessResponse;
      return {
        changeAccessResponse: allRegions,
        preloaderStyle: "d-none",
      };
    }

    if (nextProps.errors.length > 0) {
      return { errorMessage: nextProps.errors, preloaderStyle: "d-none" };
    }

    if (nextProps.addAgentSuccess !== state.addAgentSuccess) {
      return {
        addAgentSuccess: nextProps.addAgentSuccess,
        preloaderStyle: "d-none",
      };
    }

    return null;
  }

  fetchStatementHandler = (customer_id) => {
    this.setState({ currentPage: "statement", preloaderStyle: "d-block" });
  };

  disconnectCustomertHandler = (access_code) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Please confirm this disconnect request",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, disconnect",
      cancelButtonText: "No, I made a mistake",
    }).then((result) => {
      if (result.value) {
        this.setState({ preloaderStyle: "d-block" });
      } else {
        this.setState({ preloaderStyle: "d-none" });
      }
    });
  };

  customersHandler = (newCustomers) => {
    this.setState({
      agents: newCustomers,
      count: newCustomers.totalCustomers,
    });
  };

  errorMessageHandler = (newError) => {
    this.setState({ errorMessage: newError });
  };

  searchHandler = (phone, status, accesscode) => {
    this.setState({
      phone: phone,
      status: status,
      accesscode: accesscode,
    });

    this.fetchAgents(
      this.state.page,
      this.state.limit,
      phone,
      status,
      accesscode
    );
  };

  render() {
    const {
      agents,
      limit,
      page,
      errorMessage,
      preloaderStyle,
      first_name,
      last_name,
      agent_phone,
      username,
      password,
      email,
      locations,
      regions,
      region,
      location,
    } = this.state;
    const uniqueRegion = [...new Set(regions.map((item) => item.description))];

    console.log(locations);

    let allLocations =
      locations instanceof Array
        ? locations
            .filter((location) => region === location.description)
            .map((county) => {
              return { label: county.shortname, value: county.id };
            })
        : null;

    let allRegions =
      uniqueRegion instanceof Array
        ? uniqueRegion.map((region) => {
            return { label: region, value: region };
          })
        : null;

    return (
      <React.Fragment>
        <Preloader preloaderStyle={preloaderStyle} />
        <Navbar />
        <div className="container-fluid">
          <Error message={errorMessage} />
          <List
            page={page}
            loggedInUser={this.props.auth.personnel.personnel_id}
            limit={limit}
            agents={agents}
            incrementPage={this.incrementPage}
            decrementPage={this.decrementPage}
            onChangePage={this.onChangePage}
            searchHandler={this.searchHandler}
            errorMessageHandler={this.errorMessageHandler}
            fetchStatementHandler={this.fetchStatementHandler}
            handlePreloaderStyle={this.handlePreloaderStyle}
            disconnectCustomertHandler={this.disconnectCustomertHandler}
            addFloat={this.props.addFloat}
            first_name={first_name}
            last_name={last_name}
            agent_phone={agent_phone}
            username={username}
            saveAgent={this.saveAgent}
            onChange={this.onChange}
            password={password}
            email={email}
            onSelectOptionCounty={this.onSelectOptionCounty}
            locations={allLocations}
            editLocations={locations}
            regions={allRegions}
            location={location}
            region={region}
            editAgent={this.props.editAgent}
            changeFreeInternetUserName={this.changeFreeInternetUserName}
          />
        </div>
      </React.Fragment>
    );
  }
}

Agents.propTypes = {
  fetchAgents: PropTypes.func.isRequired,
  agents: PropTypes.object.isRequired,
  addFloat: PropTypes.func.isRequired,
  addAgent: PropTypes.func.isRequired,
  fetchLocations: PropTypes.func.isRequired,
  fetchRegions: PropTypes.func.isRequired,
  editAgent: PropTypes.func.isRequired,
  changeFreeInternetUserName: PropTypes.func,
};

const mapStateToProps = (state) => ({
  agents: state.commissions.agents,
  errors: state.errors,
  auth: state.auth,
  addAgentSuccess: state.commissions.addAgentSuccess,
  locations: state.commissions.locations,
  regions: state.commissions.regions,
  changeAccessResponse: state.commissions.changeAccessResponse,
});

export default connect(mapStateToProps, {
  fetchAgents,
  addFloat,
  addAgent,
  fetchLocations,
  fetchRegions,
  editAgent,
  changeFreeInternetUserName,
})(Agents);
