import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

const InputFields = ({ value, name, type, onChange, error, label }) => {
  return (
    <div className="form-group">
      <label htmlFor="exampleInputPassword1">{label}</label>
      <input
        type={type}
        className={classnames("form-control", {
            "is-invalid": error
          })}
        placeholder={label}
        onChange={onChange}
        name={name}
        value={value}
      />
      {error && <div className="invalid-feedback">{error}</div>}
    </div>
  );
};

InputFields.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired
};

export default InputFields;
