import React from "react";

const AddToAuthTable = ({
  access_code,
  saveToAuth,
  onChange,
}) => {
  return (
    <div
      className="modal fade"
      id="addAuth"
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Add To Auth
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="">
              <div className="form-group">
                <label htmlFor="exampleInputEmail1">Access Code*</label>
                <input
                  type="text"
                  className="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  name="access_code"
                  value={access_code}
                  onChange={onChange}
                />
              </div>
            </div>
          </div>
          <div className="modal-footer d-flex justify-content-between">
            <button
              type="button"
              className="btn btn-secondary btn-sm"
              data-dismiss="modal"
            >
              Close
            </button>
            <button
              type="button"
              className="btn btn-primary btn-sm"
              onClick={saveToAuth}
            >
              Save To Auth
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddToAuthTable;
