import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import moment from "moment";

import { getStartDate, getEndDate } from "../../utilities/momentdate";

import Navbar from "./Navbar";

import { Pagination, Error, Preloader } from "../common";

import { SummaryList, SearchCommissions } from "../commissions";

import {
  fetchAdminPayments,
  exportPayments
} from "../../actions/paymentsAction";

class Commissions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      limit: 10,
      count: 0,
      errors: "",
      startDate: getStartDate(),
      endDate: getEndDate(),
      customer_phone: "",
      agent_phone: "",
      payment_type: "",
      focusedInput: null,
      payments: {},
      preloaderStyle: "d-none",
      paymentsExport: [],
      exportTitle: Math.floor(Date.now() / 1000) + " Commissions.csv"
    };
  }

  handlePreloaderStyle = newStyle => {
    this.setState({
      preloaderStyle: newStyle
    });
  };
  handleDateChange = ({ startDate, endDate }) =>
    this.setState({ startDate, endDate });

  handleFocusChange = focusedInput => this.setState({ focusedInput });

  isOutsideRange = () => false;

  onChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  onChangeNumber = e => {
    this.setState({
      [e.target.name]: parseInt(e.target.value)
    });
  };

  handleSearch = (
    e,
    startDate,
    endDate,
    customer_phone,
    agent_phone,
    payment_type
  ) => {
    e.preventDefault();
    this.setState({
      startDate: moment(startDate),
      endDate: moment(endDate),
      customer_phone: customer_phone,
      agent_phone: agent_phone,
      payment_type: payment_type
    });
    const { page, limit } = this.state;
    this.fetchPayments(
      page,
      limit,
      startDate,
      endDate,
      customer_phone,
      agent_phone,
      payment_type
    );
    this.exportPayments(
      startDate,
      endDate,
      customer_phone,
      agent_phone,
      payment_type
    );
  };

  handleCloseSearch = () => {
    this.setState({
      startDate: getStartDate(),
      endDate: getEndDate(),
      customer_phone: "",
      agent_phone: "",
      payment_type: "",
      exportPayments: []
    });
  };

  handleIncrementPage = e => {
    e.preventDefault();
    const {
      page,
      count,
      limit,
      startDate,
      endDate,
      customer_phone,
      agent_phone,
      payment_type
    } = this.state;
    const totalPages = Math.ceil(count / limit);

    const newPage = page + 1;
    if (newPage < totalPages) {
      this.setState({ page: newPage });
      this.fetchPayments(
        newPage,
        limit,
        startDate,
        endDate,
        customer_phone,
        agent_phone,
        payment_type
      );
    }
  };

  handleDecrementPage = e => {
    e.preventDefault();
    const {
      limit,
      page,
      customer_phone,
      agent_phone,
      payment_type,
      startDate,
      endDate
    } = this.state;

    const newPage = page - 1;

    if (newPage >= 0) {
      this.setState({ page: newPage });
      this.fetchPayments(
        newPage,
        limit,
        startDate,
        endDate,
        customer_phone,
        agent_phone,
        payment_type
      );
    }
  };

  handleOnChangePage = e => {
    const {
      limit,
      customer_phone,
      agent_phone,
      payment_type,
      startDate,
      endDate
    } = this.state;
    const newPage = parseInt(e.target.value);
    this.setState({ [e.target.name]: newPage });

    this.fetchPayments(
      newPage,
      limit,
      startDate,
      endDate,
      customer_phone,
      agent_phone,
      payment_type
    );
  };

  fetchPayments = (
    page,
    limit,
    startDate,
    endDate,
    customer_phone,
    agent_phone,
    payment_type
  ) => {
    this.handlePreloaderStyle("d-block");
    if (typeof startDate !== "undefined") {
      startDate = moment(startDate).format("YYYY-MM-DD");
    } else {
      startDate = "";
    }
    if (typeof endDate !== "undefined") {
      endDate = moment(endDate).format("YYYY-MM-DD");
    } else {
      endDate = "";
    }
    this.props.fetchAdminPayments(
      page * limit,
      limit,
      startDate,
      endDate,
      customer_phone,
      agent_phone,
      payment_type
    );
  };

  exportPayments = (
    startDate,
    endDate,
    customer_phone,
    agent_phone,
    payment_type
  ) => {
    if (typeof startDate !== "undefined") {
      startDate = moment(startDate).format("YYYY-MM-DD");
    } else {
      startDate = "";
    }
    if (typeof endDate !== "undefined") {
      endDate = moment(endDate).format("YYYY-MM-DD");
    } else {
      endDate = "";
    }
    this.props.exportPayments(
      startDate,
      endDate,
      customer_phone,
      agent_phone,
      payment_type
    );
  };

  componentDidMount() {
    // if (!this.props.auth.isAuthenticated) {
    //   this.props.history.push("/admin");
    // }

    const {
      page,
      limit,
      startDate,
      endDate,
      customer_phone,
      agent_phone,
      payment_type
    } = this.state;
    this.fetchPayments(
      page,
      limit,
      startDate,
      endDate,
      customer_phone,
      agent_phone,
      payment_type
    );
  }

  componentWillReceiveProps(nextProps) {
    this.handlePreloaderStyle("d-none");
    if (nextProps.payments.count) {
      this.setState({
        payments: nextProps.payments,
        count: nextProps.payments.count
      });
    }
    if (nextProps.paymentsExport) {
      this.setState({
        paymentsExport: nextProps.paymentsExport
      });
    }
  }

  render() {
    const {
      page,
      limit,
      count,
      errors,
      startDate,
      endDate,
      customer_phone,
      agent_phone,
      payment_type,
      focusedInput,
      payments,
      preloaderStyle,
      paymentsExport,
      exportTitle
    } = this.state;
    let commissionPayments = payments.items || [];
    return (
      <React.Fragment>
        <Preloader preloaderStyle={preloaderStyle} />
        <Navbar />
        <div className="container-fluid">
          <Error message={errors} />
          <div className="row">
            <div className="col-md-8">
              <SummaryList
                payments={commissionPayments}
                page={page}
                limit={limit}
                paymentsExport={paymentsExport}
                exportTitle={exportTitle}
              />
              <Pagination
                page={page}
                limit={limit}
                count={count}
                handleDecrementPage={this.handleDecrementPage}
                handleIncrementPage={this.handleIncrementPage}
                handleOnChangePage={this.handleOnChangePage}
              />
            </div>
            <div className="col-md-4">
              <SearchCommissions 
                startDate={startDate}
                endDate={endDate}
                focusedInput={focusedInput}
                startDateId="startDate"
                endDateId="endDate"
                customer_phone={customer_phone}
                agent_phone={agent_phone}
                payment_type={payment_type}
                handleSearch={this.handleSearch}
                handleCloseSearch={this.handleCloseSearch}
                handleDateChange={this.handleDateChange}
                handleFocusChange={this.handleFocusChange}
                isOutsideRange={this.isOutsideRange}
                onChange={this.onChange}
                onChangeNumber={this.onChangeNumber}
              />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

Commissions.propTypes = {
  fetchAdminPayments: PropTypes.func.isRequired,
  exportPayments: PropTypes.func.isRequired,
  payments: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  payments: state.payments.payments,
  paymentsExport: state.payments.export,
  errors: state.errors,
  auth: state.auth
});

export default connect(
  mapStateToProps,
  { fetchAdminPayments, exportPayments }
)(Commissions);
