import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { singleMessage } from "../../actions/smsActions";
import Swal from "sweetalert2";
import jwt_decode from "jwt-decode";

import InputFields from "../common/InputFields";
import { Preloader, Sidebar, Navbar } from "../common";

class SingleSms extends Component {
  state = {
    receiver: "",
    message: "",
    smsResponse: "",
    application: "API",
    email: "",
    preloaderStyle: "d-none",
    auth: {},
    characterCount: 0,
    cost: 0,
  };

  handlePreloaderStyle = (newStyle) => {
    this.setState({
      preloaderStyle: newStyle,
    });
  };

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  componentDidMount() {
    const decoded = jwt_decode(localStorage.jwtToken);
    this.setState({
      email: decoded.personnel_email,
    });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.sms) {
      const smsSent = nextProps.sms;
      if (smsSent.message) {
        this.setState({
          message: "",
          receiver: "",
        });
        nextProps.sms.message = "";
        JSON.stringify(Swal.fire("SMS Sent Successful", "", "success"));
        this.handlePreloaderStyle("d-none");
      }
    }

    if (nextProps.auth) {
      this.setState({
        auth: nextProps.auth,
        // email: nextProps.auth.personnel.personnel_email,
      });
    }
  }

  singleMessageDetails = (e) => {
    e.preventDefault();
    this.handlePreloaderStyle("d-block");
    const { receiver, message, application, email } = this.state;
    const formData = {
      message_receiver: receiver,
      application,
      email,
      message_content: message,
    };

    this.props.singleMessage(formData);
  };

  count_up = (e) => {
    let characters = e.target.value.length;
    let price = (characters / 180) * 0.6;
    const rounded = Math.round(price * 100) / 100;
    this.setState({
      characterCount: characters,
      cost: rounded,
    });
  };

  render() {
    const { message, receiver, preloaderStyle, characterCount, cost } =
      this.state;

    return (
      <React.Fragment>
        <Preloader preloaderStyle={preloaderStyle} />
        <Sidebar />
        <div className="content">
          <div className="mt-4">
            <Navbar title="Single SMS" toggleSearch={this.toggleSearch} />
          </div>
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">Single SMS</h5>
              <form className="md-form mt-5">
                <div className="form-group">
                  <InputFields
                    type="text"
                    name="receiver"
                    onChange={this.onChange}
                    value={receiver}
                    label="Receiver"
                  />
                </div>
                <div className="form-group mt-3">
                  <label className="control-label mb-10 text-left">
                    Message
                  </label>
                  <textarea
                    className="form-control"
                    aria-label="With textarea"
                    name="message"
                    value={message}
                    onChange={this.onChange}
                    onKeyUp={this.count_up}
                  ></textarea>
                  <span className="text-muted pull-right">
                    {characterCount + " characters at Ksh." + cost}
                  </span>
                </div>
                <button
                  className="btn btn-primary"
                  onClick={this.singleMessageDetails}
                >
                  SUBMIT
                </button>
              </form>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

SingleSms.propTypes = {
  singleMessage: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  sms: state.sms.singleMessage,
  errors: state.sms.error,
  auth: state.auth,
});

export default connect(mapStateToProps, { singleMessage })(SingleSms);
