import {
    FETCH_COMMISSIONS_EXPORT
} from '../actions/types';

const initialState = {
    export: {}
}

export default function (state = initialState, action) {
    switch (action.type) {
        case FETCH_COMMISSIONS_EXPORT:
            return {
                ...state,
                export: action.payload
            }
            default:
                return state;
    }
}