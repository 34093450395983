import React, { Component } from "react";
import PropTypes from "prop-types";

import { TaskSummary } from "../charts";

class Aside extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: "",
      summaryDate: "",
      phone: "",
      status: "",
      assigned: 0,
      inProgress: 0,
      completed: 0,
      deferred: 0
    };
  }
  componentDidMount() {
    this.setState({
      assigned: this.props.assigned,
      inProgress: this.props.inProgress,
      completed: this.props.completed,
      deferred: this.props.deferred
    });
  }
  componentWillReceiveProps(nextProps) {
    this.setState({
      assigned: nextProps.assigned,
      inProgress: nextProps.inProgress,
      completed: nextProps.completed,
      deferred: nextProps.deferred
    });
  }

  onChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  closeSearch = () => {
    this.setState({ phone: "", status: "", date: "" });
    this.props.searchHandler("", "", "");
  };

  onSubmit = e => {
    e.preventDefault();
    this.props.searchHandler(
      this.state.phone,
      this.state.status,
      this.state.date
    );
  };
  render() {
    const { assigned, inProgress, deferred, completed } = this.state;
    // console.log(completed);
    return (
      <React.Fragment>
        <div className="card">
          <div className="card-body">
            <h5 className="card-title">Daily Target</h5>
            <TaskSummary completed={completed} />
            <div className="row task-status mt-5">
              <div className="col-sm-3 bg-primary text-center">
                <span>Assigned</span>
                <div>{assigned}</div>
              </div>
              <div className="col-sm-3 bg-warning text-center">
                <span>In Progress</span>
                <div>{inProgress}</div>
              </div>
              <div className="col-sm-3 bg-success text-center">
                <span>Completed</span>
                <div>{completed}</div>
              </div>
              <div className="col-sm-3 bg-danger text-center">
                <span>Deferred</span>
                <div>{deferred}</div>
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">Search</h5>
              <form noValidate onSubmit={this.onSubmit}>
                <div className="form-group">
                  Customer Phone
                  <input
                    type="number"
                    className="form-control"
                    name="phone"
                    onChange={this.onChange}
                    value={this.state.phone}
                  />
                  <div className="form-group">
                    <label
                      htmlFor="Task Status"
                      className="control-label mb-10"
                    >
                      Task Status
                    </label>
                    <select
                      className="form-control"
                      onChange={this.onChange}
                      name="status"
                      value={this.state.status}
                    >
                      <option value="">--Select Task Status--</option>
                      <option value="Assigned">Assigned</option>
                      <option value="Completed">Completed</option>
                      <option value="Deferred">Deferred</option>
                      <option value="In Progress">In Progress</option>
                    </select>
                  </div>
                  <div className="form-group">
                    <label className="control-label mb-10 text-left">
                      Date Assigned
                    </label>
                    <div className="input-group mb-3">
                      <input
                        type="text"
                        className="form-control datepicker"
                        name="date"
                        value={this.state.date}
                        onChange={this.onChange}
                      />
                      <div className="input-group-append">
                        <span className="input-group-text">
                          <i className="fa fa-calendar" aria-hidden="true" />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-3">
                  <button
                    onClick={this.closeSearch}
                    className="btn btn-default btn-sm"
                  >
                    <i className="fa fa-times" /> Close Search
                  </button>
                  <button type="submit" className="btn btn-warning btn-sm">
                    <i className="fa fa-search" />
                    Search
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

Aside.propTypes = {
  searchHandler: PropTypes.func.isRequired,
  assigned: PropTypes.number.isRequired,
  inProgress: PropTypes.number.isRequired,
  completed: PropTypes.number.isRequired,
  deferred: PropTypes.number.isRequired
};

export default Aside;
