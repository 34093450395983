import React, { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import InputFields from "../common/InputFields";

class Create extends Component {
  constructor() {
    super();
    this.state = {
      personnel_email: "",
      personnel_onames: "",
      personnel_fname: "",
      personnel_phone: "",
      personnel_status: "",
      personnel_type_id: "",
    };
  }

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  submitNewPersonnel = (e) => {
    e.preventDefault();

    const {
      personnel_onames,
      personnel_email,
      personnel_fname,
      personnel_phone,
      personnel_status,
      personnel_type_id,
    } = this.state;

    const personnel = {
      personnel_onames,
      personnel_email,
      personnel_fname,
      personnel_phone,
      personnel_status,
      personnel_type_id,
    };
    this.props.handlePreloaderStyle("d-block");
    this.props.createPersonnel(personnel);
  };

  render() {
    const {
      personnel_onames,
      personnel_email,
      personnel_fname,
      personnel_phone,
      personnel_type_id,
      personnel_status,
    } = this.state;
    const { errors } = this.props;
    // console.log(errors);
    return (
      <React.Fragment>
        <button
          type="button"
          className="btn btn-primary btn-sm"
          data-toggle="modal"
          data-target="#addNewPersonnel"
        >
          Add Personnel
        </button>
        <div
          className="modal fade"
          id="addNewPersonnel"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Add Personnel{" "}
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <form noValidate>
                <div className="modal-body">
                  <div className="container-fluid">
                    <InputFields
                      type="text"
                      name="personnel_onames"
                      onChange={this.onChange}
                      label="Personnel Last Name"
                      placeholder="Personnel Last Name"
                      value={personnel_onames}
                      error={errors.personnel_onames}
                    />
                    <InputFields
                      type="text"
                      name="personnel_fname"
                      value={personnel_fname}
                      onChange={this.onChange}
                      label="Personnel First Name"
                      placeholder="Personnel First Name"
                      error={errors.personnel_fname}
                    />
                    <InputFields
                      type="email"
                      name="personnel_email"
                      onChange={this.onChange}
                      label="Personnel Email"
                      placeholder="Personnel Email"
                      value={personnel_email}
                      error={errors.personnel_email}
                    />
                    <InputFields
                      type="text"
                      name="personnel_phone"
                      value={personnel_phone}
                      onChange={this.onChange}
                      label="Personnel Phone Number"
                      placeholder="Personnel Phone Number"
                      error={errors.phone}
                    />
                    <div className="form-group">
                      <label htmlFor="personnel_status">Personnel Status</label>
                      <select
                        name="personnel_status"
                        className={classnames("form-control", {
                          "is-invalid": errors.personnel_status,
                        })}
                        value={personnel_status}
                        onChange={this.onChange}
                      >
                        <option value="">--Select Personnel Status--</option>
                        <option value="1">Active</option>
                        <option value="2">Inactive</option>
                      </select>
                      {errors && (
                        <div className="invalid-feedback">
                          {errors.personnel_status}
                        </div>
                      )}
                    </div>
                    <div className="form-group">
                      <label htmlFor="personnel_type_id">Personnel Type</label>
                      <select
                        name="personnel_type_id"
                        className={classnames("form-control", {
                          "is-invalid": errors.personnel_type_id,
                        })}
                        value={personnel_type_id}
                        onChange={this.onChange}
                      >
                        <option value="">--Select Personnel Type--</option>
                        <option value="1">Admin</option>
                        <option value="2">Agent</option>
                        <option value="3">SMS</option>
                      </select>
                      {errors && (
                        <div className="invalid-feedback">
                          {errors.personnel_type_id}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                  <button
                    type="button"
                    onClick={this.submitNewPersonnel}
                    className="btn btn-success"
                    data-dismiss="modal"
                  >
                    Add Personnel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

Create.propTypes = {
  createPersonnel: PropTypes.func.isRequired,
  handlePreloaderStyle: PropTypes.func.isRequired,
};

export default Create;
