import React, { Component } from "react";
import Select from "react-select";

class EditAgent extends Component {
  state = {
    first_name: "",
    last_name: "",
    agent_phone: "",
    username: "",
    password: "",
    email: "",
    region: "",
    location: "",
  };

  componentDidMount() {
    const {
      first_name,
      last_name,
      agent_phone,
      username,
      password,
      email,
      region,
      location,
    } = this.props;
    // console.log(region);

    this.setState({
      first_name,
      last_name,
      agent_phone,
      username,
      password,
      email,
      region,
      location,
    });
  }

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onSelectOptionCounty = (value, name, e) => {
    console.log(name);
    // this.handlePreloaderStyle("d-block");
    this.setState({
      // county: value,
      [e.name]: { label: name, value },
    });
  };

  editAgent = () => {
    const {
      first_name,
      last_name,
      agent_phone,
      username,
      password,
      email,
      location,
    } = this.state;

    const { id } = this.props;

    let data = {
      first_name,
      last_name,
      cell: agent_phone,
      username,
      password,
      email,
      nas_id: location.value,
    };
    // console.log(data);
    this.props.editAgent(id, data);
  };

  render() {
    const { regions, editLocations, id } = this.props;
    const {
      first_name,
      last_name,
      agent_phone,
      username,
      password,
      email,
      region,
      location,
    } = this.state;

    let allLocations =
      editLocations instanceof Array
        ? region instanceof Object &&
          editLocations
            .filter((location) => region.value === location.description)
            .map((county) => {
              return { label: county.shortname, value: county.id };
            })
        : null;

    return (
      <div
        className="modal fade"
        id={"editAgent" + id}
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Edit Agent
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="">
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">First Name*</label>
                  <input
                    type="text"
                    className="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    name="first_name"
                    value={first_name}
                    onChange={this.onChange}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="exampleInputPassword1">Last Name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="exampleInputPassword1"
                    name="last_name"
                    value={last_name}
                    onChange={this.onChange}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="exampleInputPassword1">Phone Number*</label>
                  <input
                    type="text"
                    className="form-control"
                    id="exampleInputPassword1"
                    name="agent_phone"
                    value={agent_phone}
                    onChange={this.onChange}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="exampleInputPassword1">User Name*</label>
                  <input
                    type="text"
                    className="form-control"
                    id="exampleInputPassword1"
                    name="username"
                    value={username}
                    onChange={this.onChange}
                  />
                </div>
                {/* county */}
                <div className="form-group">
                  <label htmlFor="hostgroups">Region</label>
                  <Select
                    options={regions}
                    placeholder="Select Region"
                    name="region"
                    value={region}
                    onChange={(opt, e) =>
                      this.onSelectOptionCounty(opt.value, opt.label, e)
                    }
                  ></Select>
                </div>
                {/* Regions */}
                <div className="form-group">
                  <label htmlFor="hostgroups">Location</label>
                  <Select
                    options={allLocations}
                    placeholder="Select Location"
                    value={location}
                    name="location"
                    onChange={(opt, e) =>
                      this.onSelectOptionCounty(opt.value, opt.label, e)
                    }
                  ></Select>
                </div>
                <div className="form-group">
                  <label htmlFor="exampleInputPassword1">User Email</label>
                  <input
                    type="email"
                    className="form-control"
                    id="exampleInputPassword1"
                    name="email"
                    value={email}
                    onChange={this.onChange}
                  />
                </div>
                <div className="form-group d-none">
                  <label htmlFor="exampleInputPassword1">Password</label>
                  <input
                    type="text"
                    className="form-control"
                    id="exampleInputPassword1"
                    name="password"
                    value={password}
                    onChange={this.onChange}
                  />
                </div>
              </div>
            </div>
            <div className="modal-footer d-flex justify-content-between">
              <button
                type="button"
                className="btn btn-secondary btn-sm"
                data-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary btn-sm"
                onClick={this.editAgent}
              >
                Update Agent
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default EditAgent;
