import React from "react";
import Moment from "moment";

import { AuthAside, AddToAuthTable } from ".";

const CheckAuthList = ({
  page,
  limit,
  allRadCheck,
  incrementPage,
  decrementPage,
  onChangePage,
  searchHandler,
  errorMessageHandler,
  saveToAuth,
  accesscode,
  onChange
}) => {
  let customerCount = page * limit + 1;
  let ispAgents;

  let pages;
  let totalPages = 0;
  let assigned = 0;
  let inProgress = 0;
  let completed = 0;
  let deferred = 0;
  let count = allRadCheck.rows ? allRadCheck.rows : 0;

  if (count > 0) {
    totalPages = Math.ceil(count / limit);

    const allPages = Array.from(Array(totalPages).keys());
    if (allPages.length > 0) {
      pages = allPages.map((pg) => (
        <option key={pg} value={pg}>
          {pg + 1}
        </option>
      ));
    } else {
      pages = null;
    }
  } else {
    pages = null;
  }

  if (allRadCheck.items) {
    if (allRadCheck.items instanceof Array) {
      ispAgents = allRadCheck.items.map((agent) => (
        <tr key={agent.id}>
          <td>{customerCount++}</td>
          <td>{agent.username}</td>
          
          {/* <td>
            <Select
              customerName={agent.first_name + " " + agent.last_name}
              customer_id={agent.id}
              customer_status_id={2}
              handlePreloaderStyle={handlePreloaderStyle}
              onSubmit={fetchStatementHandler}
              buttonTitle="View Statement"
              buttonClass="btn-success"
            />
          </td> */}
         
        </tr>
      ));
    } else {
      ispAgents = null;
    }
  } else {
    ispAgents = null;
  }
  return (
    <div className="row">
      <div className="col-md-8">
        <div className="card">
          <div className="card-body">
            <div className="d-flex justify-content-between">
              <h5 className="card-title">Customers In Auth</h5>
              <button
                className="btn btn-success btn-sm mb-2"
                data-toggle="modal"
                data-target="#addAuth"
              >
                Add Customer To Auth
              </button>
              <AddToAuthTable accesscode={accesscode} onChange={onChange} saveToAuth={saveToAuth}/>
            </div>
            <div className="table-responsive">
              <table className="table table-bordered table-striped tabe-condensed">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Acess Code</th>                    
                  </tr>
                </thead>
                <tbody>{ispAgents}</tbody>
              </table>
            </div>
          </div>

          <div className="card-footer">
            <div className="d-flex flex-row-reverse">
              <form noValidate onSubmit={incrementPage}>
                <button className="btn btn-rounded" type="submit">
                  <i className="fas fa-chevron-right" />
                </button>
              </form>
              <form noValidate onSubmit={decrementPage}>
                <button className="btn btn-rounded mx-2" type="submit">
                  <i className="fas fa-chevron-left" />
                </button>
              </form>
              <span className="font-weight-normal">of {totalPages}</span>
              <select
                name="page"
                id="page"
                className="form-control mr-2"
                value={page}
                onChange={onChangePage}
              >
                {pages}
              </select>
              <span className="font-weight-normal mr-2">Page</span>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-4">
        <AuthAside
          searchHandler={searchHandler}
          errorMessageHandler={errorMessageHandler}
          assigned={assigned}
          inProgress={inProgress}
          deferred={deferred}
          completed={completed}
        />
      </div>
    </div>
  );
};

export default CheckAuthList;
