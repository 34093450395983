import React, { Component } from "react";
import { Preloader, LoginInput } from "../common";
import { withRouter, Link } from "react-router-dom";
import { logo } from "../../assets/img";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { resetPersonnelPassowrd } from "../../actions/authActions";

class ResetPassword_New extends Component {
  state = {
    preloaderStyle: "d-none",
    phone: "",
    password: "",
    confirmPassword: "",
    errors: {},
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  static getDerivedStateFromProps(props, state) {
    console.log(props.auth.reset);
    if (props.auth.reset) {
      props.history.push("/");
    }

    if (props.errors !== state.errors) {
      return {
        errors: props.errors,
      };
    }
    return null;
  }

  onSubmit = (e) => {
    e.preventDefault();
    const { password, confirmPassword, phone } = this.state;
    if (password !== confirmPassword) {
      this.setState({ errors: { password: "Passwords do not match" } });
    } else {
      const personnel = {
        phone,
        password,
      };

      this.props.resetPersonnelPassowrd(personnel, this.props.history);
    }
  };

  render() {
    const { preloaderStyle, confirmPassword, phone, password, errors } =
      this.state;
    return (
      <React.Fragment>
        <Preloader preloaderStyle={preloaderStyle} />
        <div className="login d-flex justify-content-center align-items-center">
          <div className="col-md-3">
            <div className="d-flex align-items-center justify-content-center mb-5">
              <img
                src={logo}
                alt="Mawingu"
                srcset=""
                className="img-responsive"
                height="77"
              />
            </div>
            <form autoComplete="off" onSubmit={this.onSubmit}>
              <LoginInput
                placeholder="Phone Number"
                name="phone"
                error={errors.phone}
                value={phone}
                onChange={this.onChange}
                icon="fa fa-phone text-white"
              />
              <LoginInput
                placeholder="Password"
                name="password"
                icon="fa fa-lock text-white"
                type="password"
                value={password}
                onChange={this.onChange}
                error={errors.password}
              />

              <LoginInput
                placeholder="Confirm password"
                name="confirmPassword"
                icon="fa fa-lock text-white"
                type="password"
                value={confirmPassword}
                onChange={this.onChange}
                error={errors.password}
              />

              <div className="d-grid gap-2 mt-4">
                <button
                  type="submit"
                  className=" mt-3 btn btn-light text-uppercase text-primary d-flex align-items-center justify-content-center btn-block"
                >
                  Reset Password
                </button>
              </div>
              <Link className="d-flex justify-content-end mt-2" to="/">
                <span className="text-light reset-text">Login Instead</span>
              </Link>
            </form>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

ResetPassword_New.propTypes = {
  resetPersonnelPassowrd: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    errors: state.auth.resetErrors,
  };
};

export default connect(mapStateToProps, { resetPersonnelPassowrd })(
  withRouter(ResetPassword_New)
);
