import React, { Component } from "react";
import PropTypes from "prop-types";

class CreateAnnouncement extends Component {
  constructor() {
    super();
    this.state = {
      text: "",
    };
  }

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  submitNewAnnouncement = (e) => {
    e.preventDefault();

    const { text } = this.state;

    const announcement = {
      text,
    };
    this.props.handlePreloaderStyle("d-block");
    this.props.handleCreateAnnouncement(announcement);
  };

  componentWillReceiveProps = () => {
    const { text } = this.props;
    this.setState({
      text,
    });
  };

  render() {
    const { text } = this.state;
    const { errors } = this.props;

    return (
      <React.Fragment>
        <div className="">
          <button
            type="button"
            className="btn btn-primary btn-sm"
            data-toggle="modal"
            data-target="#addNewAnnouncement"
          >
            Create Announcement
          </button>
        </div>

        <div
          className="modal fade"
          id="addNewAnnouncement"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Create Announcement{" "}
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <form noValidate>
                <div className="modal-body">
                  <div className="container-fluid">
                    <textarea
                      name="text"
                      value={text}
                      id=""
                      cols="60"
                      rows="7"
                      label="Announcement"
                      placeholder="Enter Announcement"
                      onChange={this.onChange}
                    ></textarea>
                    {errors !== "undefined" ? (
                      <p style={{ color: "red" }}>{errors}</p>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                  <button
                    type="button"
                    onClick={this.submitNewAnnouncement}
                    className="btn btn-success"
                  >
                    Create Announcement
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

CreateAnnouncement.propTypes = {
  handleCreateAnnouncement: PropTypes.func.isRequired,
  handlePreloaderStyle: PropTypes.func.isRequired,
};

export default CreateAnnouncement;
