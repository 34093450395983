import setAuthToken from "../utilities/setAuthToken";
import jwt_decode from "jwt-decode";
import {
  GET_ERRORS,
  SET_CURRENT_PERSONNEL,
  SET_PASSWORD_RESET,
  SET_CURRENT_AGENT,
  SET_AGENT_PASSWORD_RESET,
  REQUEST_PIN,
  GET_ERRORS_RESET,
} from "./types";

import { axios } from "../utilities";
import Swal from "sweetalert2";

// request pin
export const requestPin = (userData) => (dispatch) => {
  axios
    .post(`personnel/login`, userData)
    .then((response) => {
      dispatch({
        type: REQUEST_PIN,
        payload: response.data,
      });
    })
    .catch((err) => {
      if (err.response) {
        console.log(err.response);
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data.error,
        });
      } else {
        dispatch({
          type: GET_ERRORS,
          payload: "Oops something went wrong. Please try again.",
        });
      }
    });
};

//login - personnel
export const loginPersonnel = (userData, history) => (dispatch) => {
  axios
    .post("personnel/verifyLoginPin", userData)
    .then((res) => {
      //save to end point
      const { accessToken } = res.data;
      //set token to ls
      localStorage.setItem("jwtToken", accessToken);
      //set token to auth header
      setAuthToken(accessToken);
      //decode token to get user data
      const decoded = jwt_decode(accessToken);
      //set current user
      dispatch(setCurrentUser(decoded));

      if (decoded.personnel_type_id === 5) {
        history.push("/noc_report");
      } else {
        history.push("/customers");
      }
    })
    .catch((err) => {
      if (err.response) {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data.error,
        });
        if (err.response.data.error.pin) {
          Swal.fire("Error", err.response.data.error.pin, "error");
        }
      } else {
        dispatch({
          type: GET_ERRORS,
          payload: "Oops something went wrong. Please try again.",
        });
      }
    });
};

//log user out
export const logoutUser = (personnel_id) => (dispatch) => {
  //remove token from local storage
  localStorage.removeItem("jwtToken");
  localStorage.removeItem("agentJwtToken");
  //remove auth header for future requests
  setAuthToken(false);
  //set current user to {} which will set isAuthenticated to false
  dispatch(setCurrentUser({}));
  axios
    .post(`personnel/signout`, personnel_id)
    .then((response) => {
      console.log(response);
      if (response) {
      }
    })
    .catch((err) => console.log(err));
};

//reset personnel password
export const resetPersonnelPassowrd = (userData) => (dispatch) => {
  axios
    .patch("personnel/reset_password", userData)
    .then((res) => {
      dispatch(setPasswordReset());
    })
    .catch((err) => {
      if (err.response) {
        dispatch({
          type: GET_ERRORS_RESET,
          payload: err.response.data.error,
        });
      } else {
        dispatch({
          type: GET_ERRORS_RESET,
          payload: "Oops something went wrong. Please try again.",
        });
      }
    });
};

// set logged in user
export const setCurrentUser = (decoded) => {
  return {
    type: SET_CURRENT_PERSONNEL,
    payload: decoded,
  };
};

// Reset password
export const setPasswordReset = (decoded) => {
  return {
    type: SET_PASSWORD_RESET,
    payload: decoded,
  };
};

//login - Agent
export const loginMerchant = (userData) => (dispatch) => {
  axios
    .post("agents/login", userData)
    .then((res) => {
      //save to end point
      const { accessToken } = res.data;
      //set token to ls
      localStorage.setItem("agentJwtToken", accessToken);
      //set token to auth header
      setAuthToken(accessToken);
      //decode token to get user data
      const decoded = jwt_decode(accessToken);
      //set current user
      dispatch(setCurrentAgent(decoded));
    })
    .catch((err) => {
      if (err.response) {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data.error,
        });
      } else {
        dispatch({
          type: GET_ERRORS,
          payload: "Oops something went wrong. Please try again.",
        });
      }
    });
};

//reset Agent password
export const resetAgentPassowrd = (userData) => (dispatch) => {
  axios
    .patch("agents/reset_password", userData)
    .then((res) => {
      dispatch(setAgentPasswordReset());
    })
    .catch((err) => {
      if (err.response) {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data.error,
        });
      } else {
        dispatch({
          type: GET_ERRORS,
          payload: "Oops something went wrong. Please try again.",
        });
      }
    });
};

// set logged in Agent
export const setCurrentAgent = (decoded) => {
  return {
    type: SET_CURRENT_AGENT,
    payload: decoded,
  };
};

// Reset Agent password
export const setAgentPasswordReset = (decoded) => {
  return {
    type: SET_AGENT_PASSWORD_RESET,
    payload: decoded,
  };
};

//log Agent out
export const logoutAgent = () => (dispatch) => {
  //remove token from local storage
  localStorage.removeItem("agentJwtToken");
  //remove auth header for future requests
  setAuthToken(false);
  //set current user to {} which will set isAuthenticated to false
  dispatch(setCurrentAgent({}));
};
