import React, { Component } from "react";
import PropTypes from "prop-types";

import { TaskSummary } from "../charts";

class Filter_New extends Component {
  state = {
    accesscode: "",
    summaryDate: "",
    phone: "",
    status: "",
    assigned: 0,
    inProgress: 0,
    completed: 0,
    deferred: 0,
  };
  componentDidMount() {
    this.setState({
      assigned: this.props.assigned,
      inProgress: this.props.inProgress,
      completed: this.props.completed,
      deferred: this.props.deferred,
    });
  }
  componentWillReceiveProps(nextProps) {
    this.setState({
      assigned: nextProps.assigned,
      inProgress: nextProps.inProgress,
      completed: nextProps.completed,
      deferred: nextProps.deferred,
    });
  }

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  closeSearch = () => {
    this.setState({ phone: "", status: "", accesscode: "" });
    this.props.searchHandler("", "", "");
    this.props.toggleSearch();
  };

  onSubmit = (e) => {
    e.preventDefault();
    this.props.searchHandler(
      this.state.phone,
      this.state.status,
      this.state.accesscode
    );
    this.props.toggleSearch();
  };
  
  render() {
    const { assigned, inProgress, deferred, completed } = this.state;
    // console.log(completed);
    return (
      <React.Fragment>
        <div
          className="container bg-light col-md-12 col-12 d-flex align-items-center flex-column"
          style={{ width: "100vw" }}
        >
          <div className="d-flex justify-content-between py-3 col-md-12">
            <h5 className="">Search</h5>
            <button
              className="btn btn-success btn-sm px-3"
              onClick={this.closeSearch}
            >
              Close Search
            </button>
          </div>
          <div className="col-md-5">
            <form noValidate onSubmit={this.onSubmit}>
              <div className="form-group">
                Customer Phone
                <input
                  type="number"
                  className="form-control border-primary"
                  name="phone"
                  onChange={this.onChange}
                  value={this.state.phone}
                  placeholder="Phone number"
                />
              </div>
              <div className="form-group">
                Access Code
                <input
                  type="text"
                  className="form-control border-primary"
                  name="accesscode"
                  onChange={this.onChange}
                  placeholder="Access code"
                  value={this.state.accesscode}
                />
              </div>
              <div className="form-group">
                <label htmlFor="Task Status" className="control-label mb-10">
                  Status
                </label>
                <select
                  className="form-control border-primary"
                  onChange={this.onChange}
                  name="status"
                  placeholder="Status"
                  value={this.state.status}
                >
                  <option value="">--Select Status--</option>
                  <option value="Deferred">All</option>
                  <option value="Assigned">Online</option>
                  <option value="Completed">Offline</option>
                </select>
              </div>

              <div className="my-4">
                <button type="submit" className="btn btn-block btn-success">
                  <i className="fa fa-search mr-2" />
                  Search
                </button>
              </div>
            </form>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

Filter_New.propTypes = {
  searchHandler: PropTypes.func.isRequired,
  assigned: PropTypes.number.isRequired,
  inProgress: PropTypes.number.isRequired,
  completed: PropTypes.number.isRequired,
  deferred: PropTypes.number.isRequired,
};

export default Filter_New;
