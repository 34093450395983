import React, { Component } from "react";
import { Sidebar, Navbar } from "../common";
import { List_New, Statement, Filter_New } from "../customers";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Swal from "sweetalert2";
import {
  fetchCustomers,
  fetchCustomerStatement,
  disconnectCustomer,
  changeAccessCode,
} from "../../actions/customersActions";
import { Preloader } from "../common";

class Customers_New extends Component {
  state = {
    customers: {},
    radacct: [],
    payments: [],
    customer: {},
    disconnect: "",
    accessCodeChange: {},
    errors: "",
    page: 0,
    limit: 10,
    phone: "",
    status: "",
    accesscode: "",
    errorMessage: "",
    assigned: 0,
    inProgress: 0,
    completed: 0,
    deferred: 0,
    preloaderStyle: "d-none",
    currentPage: "list",
    changeAccessButtonVisibility: "d-block",
    showSearch: true,
  };

  handlePreloaderStyle = (newStyle) => {
    this.setState({ preloaderStyle: newStyle });
  };

  incrementPage = (e) => {
    e.preventDefault();
    const { limit, page, count, phone, status, accesscode } = this.state;
    const totalPages = Math.ceil(count / limit);

    const newPage = page + 1;
    if (newPage <= totalPages) {
      this.setState({ page: newPage });
      // this.fetchCustomers(newPage, limit, phone, status, accesscode);
    }
  };

  decrementPage = (e) => {
    e.preventDefault();
    const { page, limit, phone, status, accesscode } = this.state;

    const newPage = page - 1;
    if (newPage >= 0) {
      this.setState({ page: newPage });
      // this.fetchCustomers(newPage, limit, phone, status, accesscode);
    }
  };

  onChangePage = (e) => {
    const { limit, phone, status, accesscode } = this.state;
    const newPage = parseInt(e.target.value);
    this.setState({ [e.target.name]: newPage });
    // this.fetchCustomers(newPage, limit, phone, status, accesscode);
  };

  fetchCustomers = (page, limit, phone, status, accesscode) => {
    this.handlePreloaderStyle("d-block");
    this.props.fetchCustomers(page * limit, limit, phone, status, accesscode);
  };

  componentDidMount() {
    if (!this.props.auth.isAuthenticated) {
      this.props.history.push("/admin");
    }
    // this.fetchCustomers(
    //   this.state.page,
    //   this.state.limit,
    //   this.state.phone,
    //   this.state.status,
    //   this.state.accesscode
    // );
  }

  static getDerivedStateFromProps(nextProps, state) {
    if (nextProps.customers !== state.customers) {
      const newCustomers = nextProps.customers;
      return {
        customers: newCustomers,
        preloaderStyle: "d-none",
      };
    }
    if (nextProps.statement.customer !== state.customer) {
      return {
        radacct: nextProps.statement.radacct,
        payments: nextProps.statement.payments,
        customer: nextProps.statement.customer,
        preloaderStyle: "d-none",
      };
    }

    if (nextProps.disconnect !== state.disconnect) {
      if (typeof nextProps.disconnect.feedback !== "undefined") {
        Swal.fire("Disconnected", nextProps.disconnect.feedback, "success");
      } else {
        Swal.fire("Not found", nextProps.disconnect.feedback, "error");
      }
      return {
        // disconnect: nextProps.disconnect,
        preloaderStyle: "d-none",
      };
    }

    if (nextProps.accessCodeChange !== state.accessCodeChange) {
      if (typeof nextProps.accessCodeChange.error !== "undefined") {
        Swal.fire("Not found", nextProps.accessCodeChange.error, "error");
      } else if (typeof nextProps.accessCodeChange.message !== "undefined") {
        // Swal.fire("Changed", nextProps.accessCodeChange.message, "success");
      }
      return {
        // disconnect: nextProps.disconnect,
        preloaderStyle: "d-none",
        changeAccessButtonVisibility: "d-block",
      };
    }

    if (nextProps.errors.length > 0) {
      return { errorMessage: nextProps.errors, preloaderStyle: "d-none" };
    }

    return null;
  }

  fetchStatementHandler = (customer_id) => {
    this.props.fetchCustomerStatement(customer_id);
    this.setState({ currentPage: "statement", preloaderStyle: "d-block" });
  };

  disconnectCustomertHandler = (access_code) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Please confirm this disconnect request",
      showCancelButton: true,
      confirmButtonText: "Yes, disconnect",
      cancelButtonText: "No, I made a mistake",
    }).then((result) => {
      if (result.value) {
        this.props.disconnectCustomer(access_code);
        this.setState({ preloaderStyle: "d-block" });
      } else {
        this.setState({ preloaderStyle: "d-none" });
      }
    });
  };

  changeAccessCodeHandler = (access_code) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Please confirm this change of access code request",
      showCancelButton: true,
      confirmButtonText: "Yes, change access code",
      cancelButtonText: "No, I made a mistake",
    }).then((result) => {
      if (result.value) {
        this.handlePreloaderStyle("d-block");
        this.props.changeAccessCode(access_code);
        this.setState({
          preloaderStyle: "d-block",
          changeAccessButtonVisibility: "d-none",
        });
      } else {
        this.setState({ preloaderStyle: "d-none" });
      }
    });
  };

  customersHandler = (newCustomers) => {
    this.setState({
      customers: newCustomers,
      count: newCustomers.totalCustomers,
    });
  };

  handleBackToCustomers = () => {
    this.setState({ currentPage: "list", preloaderStyle: "d-none" });
  };

  errorMessageHandler = (newError) => {
    this.setState({ errorMessage: newError });
  };

  searchHandler = (phone, status, accesscode) => {
    this.setState({
      phone: phone,
      status: status,
      accesscode: accesscode,
      preloaderStyle: "d-block",
    });

    this.fetchCustomers(
      this.state.page,
      this.state.limit,
      phone,
      status,
      accesscode
    );
  };

  toggleSearch = () => {
    const { showSearch } = this.state;
    this.setState({
      showSearch: !showSearch,
    });
  };

  render() {
    const {
      customers,
      limit,
      page,
      errorMessage,
      preloaderStyle,
      currentPage,
      radacct,
      payments,
      customer,
      changeAccessButtonVisibility,
      showSearch,
    } = this.state;

    console.log(preloaderStyle);

    const display =
      currentPage === "statement" ? (
        <Statement
          radacct={radacct}
          payments={payments}
          customer={customer}
          handleBackToCustomers={this.handleBackToCustomers}
          handlePreloaderStyle={this.handlePreloaderStyle}
          disconnectCustomertHandler={this.disconnectCustomertHandler}
        />
      ) : (
        <List_New
          page={page}
          limit={limit}
          customers={customers}
          incrementPage={this.incrementPage}
          decrementPage={this.decrementPage}
          onChangePage={this.onChangePage}
          searchHandler={this.searchHandler}
          errorMessageHandler={this.errorMessageHandler}
          fetchStatementHandler={this.fetchStatementHandler}
          handlePreloaderStyle={this.handlePreloaderStyle}
          disconnectCustomertHandler={this.disconnectCustomertHandler}
          changeAccessCodeHandler={this.changeAccessCodeHandler}
          changeAccessButtonVisibility={changeAccessButtonVisibility}
        />
      );

    return (
      <React.Fragment>
        <Preloader preloaderStyle={preloaderStyle} />

        <Sidebar />
        <div className="content">
          <div className="mt-4">
            <Navbar title="Customers" toggleSearch={this.toggleSearch} />
          </div>
          <div className="mt-4">
            {showSearch ? (
              <Filter_New
                searchHandler={this.searchHandler}
                toggleSearch={this.toggleSearch}
              />
            ) : (
              display
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

Customers_New.propTypes = {
  fetchCustomers: PropTypes.func.isRequired,
  fetchCustomerStatement: PropTypes.func.isRequired,
  disconnectCustomer: PropTypes.func.isRequired,
  changeAccessCode: PropTypes.func.isRequired,
  customers: PropTypes.object.isRequired,
  statement: PropTypes.object.isRequired,
  // disconnect: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  customers: state.customers.ispcustomers,
  statement: state.customers.ispstatement,
  disconnect: state.customers.ispdisconnect,
  accessCodeChange: state.customers.accessCodeChange,
  errors: state.errors,
  auth: state.auth,
});

export default connect(mapStateToProps, {
  fetchCustomers,
  fetchCustomerStatement,
  disconnectCustomer,
  changeAccessCode,
})(Customers_New);
