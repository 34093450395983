import {
    FETCH_ASSIGNED_TASKS,
    FETCH_TASKS_SUMMARY,
    FETCH_ADMIN_TASK_SUMMARY,
    FETCH_ADMIN_TASK_REVENUE,
    FETCH_ADMIN_TIME_SUMMARY,
    REASSIGN_TASK,
    FETCH_TELESALES,
    ADD_PERSONNEL,
    EDIT_PERSONNEL,
    GET_PERSONNEL
} from '../actions/types';

const initialState = {
    tasksAssigned: {},
    tasksSummary: {},
    adminTaskSummary: [],
    taskRevenue: [],
    timeSummary: [],
    tasksToReassign: [],
    telesales: [],
    personnel: {},
    editPersonnel: {},
    onePersonnel: {}
}

export default function (state = initialState, action) {
    switch (action.type) {
        case FETCH_ASSIGNED_TASKS:
            return {
                ...state,
                tasksAssigned: action.payload
            }
            case FETCH_TASKS_SUMMARY:
                return {
                    ...state,
                    tasksSummary: action.payload
                }
                case FETCH_ADMIN_TASK_SUMMARY:
                    return {
                        ...state,
                        adminTaskSummary: action.payload
                    }
                    case FETCH_ADMIN_TASK_REVENUE:
                        return {
                            ...state,
                            taskRevenue: action.payload
                        }
                        case FETCH_ADMIN_TIME_SUMMARY:
                            return {
                                ...state,
                                timeSummary: action.payload
                            }
                            case REASSIGN_TASK:
                                return {
                                    ...state,
                                    tasksToReassign: action.payload
                                }
                                case FETCH_TELESALES:
                                    return {
                                        ...state,
                                        telesales: action.payload
                                    }
                                    case ADD_PERSONNEL:
                                        return {
                                            ...state,
                                            personnel: action.payload
                                        }
                                    case GET_PERSONNEL: 
                                        return {
                                            ...state,
                                            onePersonnel: action.payload
                                        }
                                    case EDIT_PERSONNEL:
                                        return {
                                            ...state,
                                            editPersonnel: action.payload
                                        }
                                        default:
                                            return state;
    }
}