import React, { Component } from "react";
import { Bar } from "react-chartjs-2";
import { fetchTaskRevenue } from "../../actions/tasksActions";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { monthRange } from "../../utilities/monthRange";
const moment = require("moment");

class RevenueChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      days: Array.from([...Array(31).keys()].slice(1)),
      dailyRevenue: new Array(31),
      //   sdate: moment(20190601, 'YYYY-MM-DD').format("YYYY-MM-DD"),
      //   edate: moment(20190630, 'YYYY-MM-DD').format("YYYY-MM-DD"),
    //   sdate: getStartDate(),
    //   edate: getEndDate(),
      tasks: {},
      errors: "",
      month: moment().format("MM"),
      range: {},
      backgroundColor: [
        "rgba(255, 99, 132, 0.2)",
        "rgba(255, 159, 64, 0.2)",
        "rgba(255, 205, 86, 0.2)",
        "rgba(75, 192, 192, 0.2)",
        "rgba(54, 162, 235, 0.2)",
        "rgba(153, 102, 255, 0.2)",
        "rgba(201, 203, 207, 0.2)",
        "rgba(255, 99, 132, 0.2)",
        "rgba(255, 159, 64, 0.2)",
        "rgba(255, 205, 86, 0.2)",
        "rgba(75, 192, 192, 0.2)",
        "rgba(54, 162, 235, 0.2)",
        "rgba(153, 102, 255, 0.2)",
        "rgba(201, 203, 207, 0.2)",
        "rgba(255, 99, 132, 0.2)",
        "rgba(255, 159, 64, 0.2)",
        "rgba(255, 205, 86, 0.2)",
        "rgba(75, 192, 192, 0.2)",
        "rgba(54, 162, 235, 0.2)",
        "rgba(153, 102, 255, 0.2)",
        "rgba(201, 203, 207, 0.2)",
        "rgba(255, 99, 132, 0.2)",
        "rgba(255, 159, 64, 0.2)",
        "rgba(255, 205, 86, 0.2)",
        "rgba(75, 192, 192, 0.2)",
        "rgba(54, 162, 235, 0.2)",
        "rgba(153, 102, 255, 0.2)",
        "rgba(201, 203, 207, 0.2)",
        "rgba(255, 99, 132, 0.2)",
        "rgba(255, 159, 64, 0.2)",
        "rgba(255, 205, 86, 0.2)",
        "rgba(75, 192, 192, 0.2)",
        "rgba(54, 162, 235, 0.2)",
        "rgba(153, 102, 255, 0.2)"
      ],
      borderColor: [
        "rgb(255, 99, 132)",
        "rgb(255, 159, 64)",
        "rgb(255, 205, 86)",
        "rgb(75, 192, 192)",
        "rgb(54, 162, 235)",
        "rgb(153, 102, 255)",
        "rgb(201, 203, 207)",
        "rgb(255, 99, 132)",
        "rgb(255, 159, 64)",
        "rgb(255, 205, 86)",
        "rgb(75, 192, 192)",
        "rgb(54, 162, 235)",
        "rgb(153, 102, 255)",
        "rgb(201, 203, 207)",
        "rgb(255, 99, 132)",
        "rgb(255, 159, 64)",
        "rgb(255, 205, 86)",
        "rgb(75, 192, 192)",
        "rgb(54, 162, 235)",
        "rgb(153, 102, 255)",
        "rgb(201, 203, 207)",
        "rgb(255, 99, 132)",
        "rgb(255, 159, 64)",
        "rgb(255, 205, 86)",
        "rgb(75, 192, 192)",
        "rgb(54, 162, 235)",
        "rgb(153, 102, 255)",
        "rgb(201, 203, 207)",
        "rgb(255, 99, 132)",
        "rgb(255, 159, 64)",
        "rgb(255, 205, 86)",
        "rgb(75, 192, 192)",
        "rgb(54, 162, 235)",
        "rgb(153, 102, 255)"
      ]
    };
  }

  handleMonthChange = e => {
    const newMonth = e.target.value;
    this.setState({ [e.target.name]: newMonth });
    const range = monthRange(newMonth);
    this.fetchRevenue(range);
  };

  fetchRevenue = range => {
    this.props.handlePreloaderStyle("d-block");
    this.props.fetchTaskRevenue(range);
  };

  componentDidMount() {
    const range = {
      sdate: moment()
        .startOf("month")
        .format("YYYY-MM-DD hh:mm:ss"),
      edate: moment()
        .endOf("month")
        .format("YYYY-MM-DD hh:mm:ss")
    };
    this.fetchRevenue(range);
  }

  fetchRevenue = (sdate, edate) => {
    if (typeof sdate !== "undefined") {
      sdate = moment(sdate, "YYYY-MM-DD").format("YYYY-MM-DD");
    } else {
      sdate = "";
    }
    if (typeof edate !== "undefined") {
      edate = moment(edate, "YYYY-MM-DD").format("YYYY-MM-DD");
    } else {
      edate = "";
    }
    this.props.fetchTaskRevenue(sdate, edate);
  };

  onChange = (e)=> {
    this.setState({
      [e.target.name]: e.target.value.split(",")
    });
    this.filterMonths();
  };

  filterMonths = () => {
    const { sdate, edate } = this.state;
    const date = { sdate, edate };
    this.fetchRevenue(date);
    console.log(date);
  };

  componentWillReceiveProps(nextProps) {
    this.props.handlePreloaderStyle("d-none");
    if (nextProps.tasks) {
      const allTransactions = nextProps.tasks;
      const totalTransactions = allTransactions.length;
      let dailyTransactions = [];
      let dayAmount = 0;
      for (let s = 0; s < 31; s++) {
        dayAmount = 0;
        for (let r = 0; r < totalTransactions; r++) {
          if (allTransactions[r].day === s + 1) {
            dayAmount = allTransactions[r].totalAmount;
            break;
          }
        }
        dailyTransactions.push(dayAmount);
      }
    //   const { sdate, edate } = this.state;
      this.setState({
        tasks: nextProps.tasks,
        // sdate: sdate,
        // edate: edate,
        dailyRevenue: dailyTransactions
      });
    }
  }
  render() {
    const {
      days,
      dailyRevenue,
      backgroundColor,
      borderColor,
      month
    } = this.state;
    const chartData = {
      labels: days,
      datasets: [
        {
          label: "Daily Revenue",
          data: dailyRevenue,
          fill: false,
          backgroundColor: backgroundColor,
          borderColor: borderColor,
          borderWidth: 1
        }
      ]
    };

    return (
      <div className="card">
        <div className="card-body">
          <div className="row">
            <div className="col-md-6">
              <h5 className="card-title">Revenue</h5>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <select
                  className="form-control"
                  name="month"
                  onChange={this.handleMonthChange}
                  value={month}
                >
                  <option value="01">January</option>
                  <option value="02">Febuary</option>
                  <option value="03">March</option>
                  <option value="04">April</option>
                  <option value="05">May</option>
                  <option value="06">June</option>
                  <option value="07">July</option>
                  <option value="08">August</option>
                  <option value="09">September</option>
                  <option value="10">October</option>
                  <option value="11">November</option>
                  <option value="12">December</option>
                </select>
              </div>
            </div>
          </div>
          <Bar data={chartData} />
        </div>
      </div>
    );
  }
}

RevenueChart.propTypes = {
  fetchTaskRevenue: PropTypes.func.isRequired,
  handlePreloaderStyle: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  tasks: state.tasks.taskRevenue,
  errors: state.errors
});

export default connect(
  mapStateToProps,
  { fetchTaskRevenue }
)(RevenueChart);
