import React, { Component } from "react";
import PropTypes from "prop-types";
let moment = require("moment");
const momentDurationFormatSetup = require("moment-duration-format");
momentDurationFormatSetup(moment);

class AverageTime extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tasks: [],
      errors: ""
    };
    this.formatTime = this.formatTime.bind(this);
  }

  componentDidMount() {
    this.setState({
      tasks: this.props.tasks
    });
  }

  componentWillReceiveProps(nextProps) {
    //   console.log(nextProps.tasks);
    if (nextProps.tasks) {
      this.setState({
        tasks: nextProps.tasks
      });
    }
  }

  formatTime(seconds) {
    const duration = moment.duration(seconds, "seconds");
    return duration.format("hh:mm:ss");
  }

  render() {
    const { tasks } = this.state;
    // console.log(tasks);
    let timeSummary;

    let sumOfDefferedTime = 0;
    let totalDeffered = 0;
    let sumOfCompletedTime = 0;
    let totalCompleted = 0;
    let sumOfBeginTime = 0;
    let totalBegin = 0;
    const totalTasks = tasks.length;

    if (totalTasks > 0) {
      for (let r = 0; r < tasks.length; r++) {
        const task = tasks[r];
        sumOfDefferedTime +=
          parseFloat(task.deferred_time) > 0
            ? parseFloat(task.deferred_time)
            : 0;
        totalDeffered += parseFloat(task.deferred_time) > 0 ? 1 : 0;
        sumOfCompletedTime +=
          parseFloat(task.completed_time) > 0
            ? parseFloat(task.completed_time)
            : 0;
        totalCompleted += parseFloat(task.completed_time) > 0 ? 1 : 0;
        sumOfBeginTime +=
          parseFloat(task.start_time) > 0 ? parseFloat(task.start_time) : 0;
        totalBegin += parseFloat(task.start_time) > 0 ? 1 : 0;
      }
      sumOfDefferedTime = sumOfDefferedTime / totalDeffered;
      sumOfCompletedTime = sumOfCompletedTime / totalCompleted;
      sumOfBeginTime = sumOfBeginTime / totalBegin;
    }

    if (totalTasks > 0) {
      // console.log(timeSummary);
      timeSummary = tasks.map(task => (
        <tr key={task.personnel_id}>
          <td>
            {task.personnel_first_name} {task.personnel_other_name}
          </td>
          <td>{this.formatTime(parseInt(task.completed_time))}</td>
          <td>{this.formatTime(parseInt(task.deferred_time))}</td>
          <td>{this.formatTime(parseInt(task.start_time))}</td>
        </tr>
      ));
    }
    return (
      <div className="card">
        <div className="card-body">
          <div className="row">
            <div className="col-md-12">
              <h5 className="card-title">Average Time</h5>
            </div>
          </div>
          <div className="table-responsive">
            <table className="table table-bordered table-striped table-condensed">
              <thead>
                <tr>
                  <th />
                  <th>Complete</th>
                  <th>Defer</th>
                  <th>Begin</th>
                </tr>
              </thead>
              <tbody>{timeSummary}</tbody>
              <tfoot>
                <tr>
                  <th />
                  <th>{this.formatTime(parseInt(sumOfCompletedTime))}</th>
                  <th>{this.formatTime(parseInt(sumOfDefferedTime))}</th>
                  <th>{this.formatTime(parseInt(sumOfBeginTime))}</th>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    );
  }
}

AverageTime.propTypes = {
  tasks: PropTypes.array.isRequired
};

export default AverageTime;
