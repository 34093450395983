import React, { Component } from "react";
import { Pie } from "react-chartjs-2";

class TaskSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pending: 40,
      completed: 0,
      width: 100
    };
  }

  render() {
    const completed = this.props.completed; 
    const pending = this.state.pending - completed;
    const pieData = {
      labels: ["Pending", "Completed"],
      datasets: [
        {
          label: "Daily Target",
          data: [pending, completed],
          backgroundColor: ["rgb(255, 99, 132)", "rgb(255, 205, 86)"]
        }
      ]
    };

    return <Pie data={pieData} />;
  }
}

export default TaskSummary;
