import React, { Component } from "react";
import { DateRangePicker } from "react-dates";
import PropTypes from "prop-types";

class DateRangePickerComponent extends Component {
  render() {
    const {
      startDate,
      endDate,
      focusedInput,
      startDateId,
      endDateId,
      isOutsideRange
    } = this.props;

    return (
      <DateRangePicker
        startDate={startDate}
        endDate={endDate}
        endDateId={endDateId}
        startDateId={startDateId}
        focusedInput={focusedInput}
        isOutsideRange={isOutsideRange}
        onDatesChange={this.props.handleDateChange}
        onFocusChange={this.props.handleFocusChange}
      />
    );
  }
}
DateRangePickerComponent.propTypes = {
  //   startDate: PropTypes.object.isRequired,
  //   endDate: PropTypes.object.isRequired,
  startDateId: PropTypes.string.isRequired,
  endDateId: PropTypes.string.isRequired,
  focusedInput: PropTypes.string,
  isOutsideRange: PropTypes.func.isRequired,
  handleDateChange: PropTypes.func.isRequired,
  handleFocusChange: PropTypes.func.isRequired
};
export default DateRangePickerComponent;
