import React, { Component } from "react";
import PropTypes from "prop-types";

class EditAnnouncement extends Component {
  constructor() {
    super();
    this.state = {
      id: 0,
      text: "",
      errors: "",
    };
  }

  componentDidMount() {
    const { id, text } = this.props;

    this.setState({
      id,
      text,
    });
  }

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onSubmitEditAnnouncement = (e) => {
    e.preventDefault();
    const { id, text } = this.state;

    const announcement = {
      text,
      post: this.props.post,
    };

    this.props.handlePreloaderStyle("d-block");
    this.props.updateAnnouncement(id, announcement);
  };

  render() {
    const { id, text } = this.state;
    return (
      <React.Fragment>
        <button
          type="button"
          className="btn btn-primary btn-sm"
          data-toggle="modal"
          data-target={"#editAnnouncement" + id}
        >
          <i className="fas fa-pencil-alt" />
        </button>
        <div
          className="modal fade"
          id={"editAnnouncement" + id}
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Edit Announcement
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <form noValidate>
                <div className="modal-body">
                  <textarea
                    name="text"
                    value={text}
                    id=""
                    cols="70"
                    rows="7"
                    label="Announcement"
                    placeholder="Enter Announcement"
                    onChange={this.onChange}
                  ></textarea>
                  {/* {errors !== "undefined" ? (
                    <p style={{ color: "red" }}>{errors}</p>
                  ) : (
                    ""
                  )} */}
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                  <button
                    type="button"
                    onClick={this.onSubmitEditAnnouncement}
                    className="btn btn-success"
                    // data-dismiss="modal"
                  >
                    Edit Announcement
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

EditAnnouncement.propTypes = {
  updateAnnouncement: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired,
  text: PropTypes.string.isRequired,

  // errors: PropTypes.object.isRequired
};

export default EditAnnouncement;
