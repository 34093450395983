import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

const InputFile = ({ name, onChange, error, label }) => {
  return (
    <React.Fragment>
      <div className="form-group">
        <label htmlFor="exampleInputPassword1">{label}</label>

        <div className="input-group">
          <div className="input-group-prepend">
            <span className="input-group-text" id="inputGroupFileAddon01">
              Upload
            </span>
          </div>
          <div className="custom-file">
            <input
              type="file"
              className={classnames("custom-file-input", {
                "is-invalid": error
              })}
              placeholder={label}
              id={name}
              name={name}
              aria-describedby="inputGroupFileAddon01"
              onChange={onChange}
            />
            <label className="custom-file-label" htmlFor={name}>
              Choose file
            </label>
          </div>
        </div>
        {error && <div className="invalid-feedback">{error}</div>}
      </div>
    </React.Fragment>
  );
};

InputFile.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired
};

export default InputFile;
