import React, { Component } from "react";
import PropTypes from "prop-types";
import AdminChartSummary from "../charts/AdminChartSummary";

class Target extends Component {
  constructor() {
    super();
    this.state = {
      sumOfCompleted: 0,
      totalDailyAssignedTarget: 0,
      sumOfAssigned: 0,
      sumOfDeffered: 0,
      totalPurchases: 0
    };
  }

  componentDidMount() {
    // this.setState({
    //   sumOfCompleted: this.props.sumOfCompleted,
    //   totalDailyAssignedTarget: this.props.totalDailyAssignedTarget,
    //   sumOfAssigned: this.props.sumOfAssigned,
    //   sumOfDeffered: this.props.sumOfDeffered,
    //   totalPurchases: this.props.totalPurchases
    // });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.sumOfCompleted) {
      this.setState({
        sumOfCompleted: nextProps.sumOfCompleted,
        totalDailyAssignedTarget: nextProps.totalDailyAssignedTarget,
        sumOfAssigned: nextProps.sumOfAssigned,
        sumOfDeffered: nextProps.sumOfDeffered,
        totalPurchases: nextProps.totalPurchases
      });
    }
  }
  render() {
    const {
      sumOfAssigned,
      sumOfCompleted,
      totalDailyAssignedTarget,
      sumOfDeffered,
      totalPurchases
    } = this.state;
    return (
      <div className="card">
        <div className="card-body">
          <div className="row">
            <div className="col-md-6">
              <h5 className="card-title">Daily Target</h5>
            </div>
            <div className="col-md-6">
              {/* caleder date picker goes here */}
            </div>
          </div>
          <AdminChartSummary
            completed={sumOfCompleted}
            totalDailyAssignedTarget={totalDailyAssignedTarget}
          />
          <div className="row task-status mt-5">
            <div className="col-sm-3 bg-primary text-center">
              <span>Assigned</span>
              <div>{sumOfAssigned}</div>
            </div>
            <div className="col-sm-3 bg-success text-center">
              <span>Completed</span>
              <div>{sumOfCompleted}</div>
            </div>
            <div className="col-sm-3 bg-warning text-center">
              <span>Purchased</span>
              <div>{totalPurchases}</div>
            </div>
            <div className="col-sm-3 bg-danger text-center">
              <span>Deferred</span>
              <div>{sumOfDeffered}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
Target.propTypes = {
  sumOfAssigned: PropTypes.number.isRequired,
  sumOfCompleted: PropTypes.number.isRequired,
  sumOfDeffered: PropTypes.number.isRequired,
  totalDailyAssignedTarget: PropTypes.number.isRequired,
  totalPurchases: PropTypes.number.isRequired
};

export default Target;
