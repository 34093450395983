import {
    FETCH_CAMPAIGN,
    CREATE_CAMPAIGN,
    GET_ERRORS
} from "./types";

import axios from "axios";

// axios.interceptors.request.use(request => {
//     console.log('Starting Request', request)
//     return request
// })

// axios.interceptors.response.use(response => {
//     console.log('Response:', response)
//     return response
// })

export const fetchCampaign = () => dispatch => {
    let url = `campaign`;
    axios
        .get(url)
        .then(peronnel => {
            // console.log(peronnel.data);
            dispatch({
                type: FETCH_CAMPAIGN,
                payload: peronnel.data
            });
            // dispatch(createCampaign);
        })
        .catch(err => {
            // console.log(err.response);
            if (err.response) {
                dispatch({
                    type: GET_ERRORS,
                    payload: err.response.data.error
                });
            } else {
                dispatch({
                    type: GET_ERRORS,
                    payload: "Oops something went wrong. Please try again."
                });
            }
        });
};

export const createCampaign = campaignDetails => dispatch => {
    let url = `campaign`;
    axios
        .post(url, campaignDetails)
        .then(campaign => {
            dispatch({
                type: CREATE_CAMPAIGN,
                payload: campaign.data
            });
        })
        .catch(err => {
            // console.log(err.response);
            if (err.response) {
                dispatch({
                    type: GET_ERRORS,
                    payload: err.response.data.error
                });
            } else {
                dispatch({
                    type: GET_ERRORS,
                    payload: "Oops something went wrong. Please try again."
                });
            }
        });
};