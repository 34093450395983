import React, { useState, useEffect } from "react";
import moment from "moment";
import newMoment from "moment-duration-format";

import { DataBalance } from "../charts";
import { Select } from ".";

import { bytesToMegabytes } from "../../utilities";

const displayPayment = (payment, s, count) => {
  return (
    <tr key={"payment" + s}>
      <td>{count}</td>
      <td>{moment(payment.confirmed_on).utc().format("DD/MM/YYYY HH:MM:SS")}<br/>
      Expiry Date: {moment(payment.expiry_date).utc().format("DD/MM/YYYY HH:MM:SS")}
      </td>
      <td>
        <ul className="list-group">
          <li className="list-group-item">
            <strong>{"Access Code"}:</strong> {payment.access_code}
          </li>
          <li className="list-group-item">
            <strong>{"Type"}:</strong> {payment.type}
          </li>
          <li className="list-group-item">
            <strong>{"Payment Status"}:</strong> {payment.status}
          </li>
          <li className="list-group-item">
            <strong>{"Package"}:</strong> {payment.package_name}
          </li>
          <li className="list-group-item">
            <strong>{"Status"}:</strong> {payment.is_active? <label class="label label-success">Active</label> : <label class="label label-success">Inactive</label> }
          </li>
          <li className="list-group-item">
            <strong>{"M-Pesa Referrence Number"}:</strong>{" "}
            {payment.mpesaReceiptNumber}
          </li>
        </ul>
      </td>
      {/* <td></td> */}
      <td>Credit: {payment.data_limit} <br/> Bal: {payment.balance}</td>

    </tr>
  );
};

const Statement = ({
  radacct,
  payments,
  customer,
  handleBackToCustomers,
  disconnectCustomertHandler,
  handlePreloaderStyle,
}) => {
  let accountStatement = [];
  let raddactData = [];
  let s = 0;
  let count = 0;
  let totalTransfer = 0;
  let totalTransfer2 = 0;
  let totalPayment = 0;
  let dataBalance = 0;
  let status = "offline";

  console.log(radacct);

  if (radacct instanceof Array) {
    if (radacct.length > 0) {
      if (
        parseInt(moment(radacct[0].acctstoptime).format("yyyy")) !==
        new Date().getFullYear()
      ) {
        status = "online";
      }
      for (let r = 0; r < radacct.length; r++) {
        // Look for dates greater than it
        const uploads = bytesToMegabytes(radacct[r].acctoutputoctets);
        const downloads = bytesToMegabytes(radacct[r].acctinputoctets);
        totalTransfer += uploads + downloads;

        if (payments instanceof Array) {
          while (s < payments.length) {
            if (
              moment(radacct[r].acctstarttime).isBefore(payments[s].created_at)
            ) {
              count++;
              let data_limit = payments[s].data_limit;
              let payment_status_id = payments[s].payment_status_id;
              totalPayment += payment_status_id == 6 ? data_limit : 0;
              accountStatement.push(displayPayment(payments[s], s, count));
              s++;
            } else {
              break;
            }
          }
        }
        count++;

        raddactData.push(
          <tr key={"data" + r}>
            {/* <td>{count}</td> */}
            <td>{radacct[r].acctstarttime}</td>
            <td>{radacct[r].acctstoptime}</td>
            <td>
              <ul className="list-group">
                <li className="list-group-item">
                  <strong>{"Uploads"}:</strong> {uploads.toFixed(2)}
                </li>
                <li className="list-group-item">
                  <strong>{"Downloads"}:</strong> {downloads.toFixed(2)}
                </li>
                <li className="list-group-item">
                  <strong>{"Router"}:</strong> {radacct[r].shortname}
                </li>
                <li className="list-group-item">
                  <strong>{"IP Address"}:</strong> {radacct[r].framedipaddress}
                </li>
                <li className="list-group-item">
                  <strong>{"MAC Address"}:</strong>{" "}
                  {radacct[r].callingstationid}
                </li>
              </ul>
            </td>
            <td>{(uploads + downloads).toFixed(2)}</td>
            {/* <td></td> */}
          </tr>
        );
      }
    }
  }

  

  if (payments instanceof Array) {   
    for (let r = s; r < payments.length; r++) {
      count++;
      let data_limit = payments[r].data_limit;
      let payment_status_id = payments[r].payment_status_id;
      let total_data = payments[r].total_data_used;
      let is_active = payments[r].is_active;
      totalPayment += payment_status_id == 6 && is_active == 1? data_limit : 0;
      totalTransfer2 += payment_status_id == 6 && is_active == 1? total_data : 0;      
      accountStatement.push(displayPayment(payments[r], r, count));
    }

    //const dataBalance = (totalPayment - totalTransfer2).toFixed(2);
  }
 

  const customerDetails =
    typeof customer !== "undefined" ? (
      <table className="table table-bordered table-striped tabe-condensed">
        <tbody>
          <tr>
            <th>First Name</th>
            <td>{customer.first_name}</td>
          </tr>
          <tr>
            <th>Last Name</th>
            <td>{customer.last_name}</td>
          </tr>
          <tr>
            <th>Phone</th>
            <td>{customer.phone}</td>
          </tr>
          <tr>
            <th>Access Code</th>
            <td>{customer.access_code}</td>
          </tr>
          <tr>
            <th>Status</th>
            <td
              className={status === "online" ? "table-success" : "table-danger"}
            >
              {status === "online" ? (
                <React.Fragment>
                  {status}
                  <Select
                    customerName={
                      customer.customer_first_name +
                      " " +
                      customer.customer_last_name
                    }
                    customer_id={customer.access_code}
                    customer_status_id={2}
                    handlePreloaderStyle={handlePreloaderStyle}
                    onSubmit={disconnectCustomertHandler}
                    buttonTitle="Disconnect"
                    buttonClass="btn-danger"
                  />
                </React.Fragment>
              ) : (
                status
              )}
            </td>
          </tr>
        </tbody>
      </table>
    ) : null;
  return (
    <React.Fragment>
      <div className="row">
        <div className="col-md-6">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">Data Balance (MB)</h5>
              <DataBalance
                dataLimit={totalPayment}
                dataTransfered={parseInt(totalTransfer2).toFixed(2)}
              />
              <div className="row task-status mt-5">
                <div className="col-sm-4 bg-primary text-center">
                  <span>Total Payment</span>
                  <div>{totalPayment}</div>
                </div>
                <div className="col-sm-4 bg-warning text-center">
                  <span>Total Transfer</span>
                  <div>{parseInt(totalTransfer2).toFixed(2)}</div>
                </div>
                <div className="col-sm-4 bg-success text-center">
                  <span>Data Balance</span>
                  <div>{ (parseInt(totalPayment).toFixed(2) - parseInt(totalTransfer2).toFixed(2))}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="my-3 d-flex justify-content-center">
            <button className="btn btn-primary" onClick={handleBackToCustomers}>
              Back to Customers
            </button>
          </div>
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">Customer Bio</h5>
              {customerDetails}
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-center my-3">
        <h6>Account Statement</h6>
      </div>
      <div className="row">
        <div className="col-md-6">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">Payments</h5>
              <div className="table-responsive">
                <table className="table table-bordered table-striped tabe-condensed statement">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Purchase Date</th>
                      <th>Description</th>
                      {/* <th>Transfer Amount (MB)</th> */}
                      <th>Purchase Amount (MB)</th>
                    </tr>
                  </thead>
                  <tbody>{accountStatement}</tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        {/* raddact */}
        <div className="col-md-6">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">Data Transfer</h5>
              <div className="table-responsive">
                <table className="table table-bordered table-striped tabe-condensed statement">
                  <thead>
                    <tr>
                      {/* <th>#</th> */}
                      <th>Start date</th>
                      <th>End date</th>
                      <th>Description</th>
                      <th>Transfer Amount (MB)</th>
                    </tr>
                  </thead>
                  <tbody>{raddactData}</tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Statement;
