import React, { Component } from "react";
import Navbar from "../layout/Navbar";
import { List } from ".";
import Select from "react-select";
import {
  getAllCustomers,
  getAllSpecialCustomers,
  fetchAllCustomers,
  changeSpecialStatus,
} from "../../actions/disconnectActions";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Swal from "sweetalert2";

class DisconnectMainLayout extends Component {
  state = {
    searchValue: "",
    options: [
      { label: "Customers To Disconnect", value: 1 },
      { label: "Special List", value: 2 },
      { label: "All Customers", value: 3 },
    ],
    allCustomers: [],
    allSpecialList: [],
    allCustomersDisconnect: [],
    activeList: 1,
  };

  onSelectOption = (value, e) => {
    console.log(value);
    this.setState({
      activeList: value,
    });
  };

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  componentDidMount() {
    if (!this.props.auth.isAuthenticated) {
      this.props.history.push("/admin");
    }
    this.props.getAllCustomers();
    this.props.getAllSpecialCustomers();
    this.props.fetchAllCustomers();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.allCustomers) {
      this.setState({
        allCustomers: nextProps.allCustomers,
      });
    }
    if (nextProps.allSpecialList) {
      this.setState({
        allSpecialList: nextProps.allSpecialList,
      });
    }
    if (nextProps.allCustomersDisconnect) {
      this.setState({
        allCustomersDisconnect: nextProps.allCustomersDisconnect,
      });
    }
  }

  changeSpecialStatus = (customer_number) => {
    const { activeList } = this.state;
    Swal.fire({
      title: "Are you sure you want to change ?" + customer_number,
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        if (activeList === 2) {
          let data = {
            special_status: 0,
            customer_number,
          };
          this.props.changeSpecialStatus(data);
          Swal.fire("Changed!", "Customer Status has changed.", "success");
        } else {
          let data = {
            special_status: 1,
            customer_number,
          };
          this.props.changeSpecialStatus(data);
          Swal.fire("Changed!", "Customer Status has changed.", "success");
        }
      }
    });
  };

  removeFromSpecial = (customer_number) => (
    <button
      className="btn btn-danger btn-sm"
      onClick={() => this.changeSpecialStatus(customer_number)}
    >
      <i className="fas fa-times"></i>
    </button>
  );

  addToSpecial = (customer_number) => (
    <button
      className="btn btn-info btn-sm"
      onClick={() => this.changeSpecialStatus(customer_number)}
    >
      <i className="fas fa-edit"></i>
    </button>
  );

  render() {
    const {
      options,
      allCustomers,
      activeList,
      allSpecialList,
      allCustomersDisconnect,
    } = this.state;

    let customerData;

    if (activeList === 1) {
      customerData = {
        columns: [
          {
            label: "Customer Number",
            field: "id",
            sort: "asc",
            width: 150,
          },
          {
            label: "Account Name",
            field: "customer_name",
            sort: "asc",
            width: 270,
          },
          {
            label: "Phone Number",
            field: "customer_phone",
            sort: "asc",
            width: 200,
          },
          {
            label: "Balance",
            field: "customer_balance",
            sort: "asc",
            width: 100,
          },
        ],
      };
    } else {
      customerData = {
        columns: [
          {
            label: "Customer Number",
            field: "id",
            sort: "asc",
            width: 150,
          },
          {
            label: "Account Name",
            field: "customer_name",
            sort: "asc",
            width: 270,
          },
          {
            label: "Phone Number",
            field: "customer_phone",
            sort: "asc",
            width: 200,
          },
          {
            label: "Balance",
            field: "customer_balance",
            sort: "asc",
            width: 100,
          },
          {
            label: "Action",
            field: "action",
            sort: "asc",
            width: 150,
          },
        ],
      };
    }

    if (activeList === 1) {
      customerData.rows =
        allCustomers instanceof Array
          ? allCustomers.map((cust) => {
              return {
                id: cust.id,
                customer_name: cust.customer_name,
                customer_phone: cust.customer_phone,
                customer_balance: cust.customer_balance,
              };
            })
          : null;
    } else if (activeList === 2) {
      customerData.rows =
        allSpecialList instanceof Array
          ? allSpecialList.map((cust) => {
              return {
                id: cust.id,
                customer_name: cust.customer_name,
                customer_phone: cust.customer_phone,
                customer_balance: cust.customer_balance,
                action: this.removeFromSpecial(cust.id),
              };
            })
          : null;
    } else if (activeList === 3) {
      customerData.rows =
        allCustomersDisconnect instanceof Array
          ? allCustomersDisconnect.map((cust) => {
              return {
                id: cust.id,
                customer_name: cust.customer_name,
                customer_phone: cust.customer_phone,
                customer_balance: cust.customer_balance,
                action: this.addToSpecial(cust.id),
              };
            })
          : null;
    }
    console.log(allCustomersDisconnect);

    return (
      <React.Fragment>
        <Navbar />
        <div className="container-fluid">
          <div className="d-flex justify-content-between mt-4 pl-0 pr-0">
            <div className="col-md-3 pl-0">
              <Select
                options={options}
                placeholder="Select Customers"
                onChange={(opt, e) => this.onSelectOption(opt.value, e)}
              />
            </div>
            <div className="">
              <button className="btn btn-success btn-sm">
                DISCONNECT CUSTOMERS
              </button>
            </div>
          </div>
          <div className="">
            <List data={customerData} />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

DisconnectMainLayout.propTypes = {
  getAllCustomers: PropTypes.func.isRequired,
  allCustomers: PropTypes.array.isRequired,
  allSpecialList: PropTypes.array.isRequired,
  getAllSpecialCustomers: PropTypes.func.isRequired,
  fetchAllCustomers: PropTypes.func.isRequired,
  changeSpecialStatus: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  allCustomers: state.disconnect.allCustomers,
  allSpecialList: state.disconnect.allSpecialList,
  allCustomersDisconnect: state.disconnect.allCustomersDisconnect,
  auth: state.auth,
});

export default connect(mapStateToProps, {
  getAllCustomers,
  getAllSpecialCustomers,
  fetchAllCustomers,
  changeSpecialStatus,
})(DisconnectMainLayout);
