import {
    POST_SMS,
    SINGLE_MESSAGE,
    GET_ERRORS,
    FETCH_SMS,
    FETCH_SMS_EXPORT
} from './types';

import {
    axios,
    createError
} from "../utilities";

// post bulk sms
export const postSMS = (smsDetails) => dispatch => {
    console.log(smsDetails);
    let url = `campaigns/upload`;

    axios
        .post(url, smsDetails)
        .then(response => {
            console.log(response.data);
            dispatch({
                type: POST_SMS,
                payload: response.data
            })
        })
        .catch(err => {
            console.log(err.response);
            dispatch(createError(err, GET_ERRORS));
        })
}

// post single sms
export const singleMessage = (smsDetails) => dispatch => {
    let url = `messages/singleMessage`;

    axios
        .post(url, smsDetails)
        .then(response => {
            dispatch({
                type: SINGLE_MESSAGE,
                payload: response.data
            })
        })
        .catch(err => {
            dispatch(createError(err, GET_ERRORS));
        })
}

// get all sms
export const fetchSMS = (page, limit, receiver, content, date) => dispatch => {
    let url = `messages`;
    url += `?page=${page}&limit=${limit}`;
    if (receiver !== "") {
        url += `&receiver=${receiver}`;
    }
    if (content !== "") {
        url += `&content=${content}`;
    }
    if (date !== "") {
        url += `&date=${date}`;
    }
    axios
        .get(url)
        .then(sms => {
            dispatch({
                type: FETCH_SMS,
                payload: sms.data
            })
        })
        .catch(err => {
            dispatch(createError(err, GET_ERRORS));
        })
}

// get all messages for export
export const fetchSMSForExport = (receiver, content, date) => dispatch => {
    let url = `messages/all`;
    url += `?receiver=${receiver}&content=${content}`;
    if (date !== "") {
        url += `&date=${date}`;
    }

    axios
        .get(url)
        .then(sms => {
            dispatch({
                type: FETCH_SMS_EXPORT,
                payload: sms.data
            })
        })
        .catch(err => {
            dispatch(createError(err, GET_ERRORS));
        })
}