import React from "react";
import Moment from "moment";
import { CSVLink } from "react-csv";

import { Aside } from ".";

const List_New = ({
  page,
  limit,
  agentPayments,
  incrementPage,
  decrementPage,
  onChangePage,
  searchHandler,
  errorMessageHandler,
  date,
  onChangeDate,
  agentPaymentsExport,
}) => {
  console.log(agentPaymentsExport);

  let customerCount = page * limit + 1;
  let allPayments;
  let allPaymentsExports;

  let pages;
  let totalPages = 0;
  let assigned = 0;
  let inProgress = 0;
  let completed = 0;
  let deferred = 0;
  let count = agentPayments.rows ? agentPayments.rows : 0;

  if (count > 0) {
    totalPages = Math.ceil(count / limit);

    const allPages = Array.from(Array(totalPages).keys());
    if (allPages.length > 0) {
      pages = allPages.map((pg) => (
        <option key={pg} value={pg}>
          {pg + 1}
        </option>
      ));
    } else {
      pages = null;
    }
  } else {
    pages = null;
  }

  if (agentPayments.items) {
    if (agentPayments.items instanceof Array) {
      allPayments = agentPayments.items.map((payment) => (
        <tr
          key={payment.id}
          className={
            payment.status === "Confirmed"
              ? "bg-success"
              : payment.status === "Created"
              ? "bg-warning"
              : "bg-danger"
          }
        >
          <td>{customerCount++}</td>
          <td>{payment.first_name + " " + payment.last_name}</td>
          <td>{payment.phone}</td>
          <td>{payment.agent_id}</td>
          <td>{payment.mpesaReceiptNumber}</td>
          <td>{payment.amount}</td>
          <td>{payment.status}</td>
          <td>{payment.type}</td>
          <td>{Moment(payment.confirmed_on).format("DD/MM/YYYY")}</td>
        </tr>
      ));
    } else {
      allPayments = null;
    }
  } else {
    allPayments = null;
  }

  return (
    <div className="row">
      <div className="col-md-12">
        <div className="card">
          <div className="card-body">
            <div className="d-flex justify-content-between">
              <h5 className="card-title">Float Payments</h5>

              <div className="form-group input-group-sm d-flex flex-row col-md-4">
                <span>Date to Export</span>
                <input
                  type="date"
                  className="form-control"
                  name="date"
                  onChange={onChangeDate}
                  value={date}
                />
              </div>
              {/* Export */}
              <div>
                {agentPaymentsExport instanceof Array && (
                  <CSVLink
                    data={agentPaymentsExport}
                    filename={"CreatedPayments.csv"}
                  >
                    <button className="btn btn-success btn-sm">
                      Download Created Payments
                    </button>
                  </CSVLink>
                )}
              </div>
            </div>
            <div className="table-responsive">
              <table className="table table-bordered table-striped tabe-condensed">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Agent Name</th>
                    <th>Phone No</th>
                    <th>Agent ID</th>
                    <th>Mpesa Receipt No</th>
                    <th>Amount</th>
                    <th>Status</th>
                    <th>Type</th>
                    <th>Date</th>
                  </tr>
                </thead>
                <tbody>{allPayments}</tbody>
              </table>
            </div>
          </div>

          <div className="card-footer">
            <div className="d-flex flex-row-reverse">
              <form noValidate onSubmit={incrementPage}>
                <button className="btn btn-rounded" type="submit">
                  <i className="fas fa-chevron-right" />
                </button>
              </form>
              <form noValidate onSubmit={decrementPage}>
                <button className="btn btn-rounded mx-2" type="submit">
                  <i className="fas fa-chevron-left" />
                </button>
              </form>
              <span className="font-weight-normal">of {totalPages}</span>
              <select
                name="page"
                id="page"
                className="form-control mr-2"
                value={page}
                onChange={onChangePage}
              >
                {pages}
              </select>
              <span className="font-weight-normal mr-2">Page</span>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="col-md-4">
        <Aside
          searchHandler={searchHandler}
          errorMessageHandler={errorMessageHandler}
          assigned={assigned}
          inProgress={inProgress}
          deferred={deferred}
          completed={completed}
        />
      </div> */}
    </div>
  );
};

export default List_New;
