import React, { Component, useEffect } from "react";
import { Sidebar } from "../common";
import { connect } from "react-redux";
import "bootstrap/dist/css/bootstrap.min.css";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";
import axios from "axios";

class MpesaReconciliation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
    };
  }

  componentDidMount() {
    if (!this.props.auth.isAuthenticated) {
      this.props.history.push("/admin");
    }
    
    const alltransUrl = process.env.REACT_APP_BACKEND + "/transactions/";

    axios
      .get(alltransUrl)
      .then((response) => {
        console.log(response.data.data);
        this.setState({ data: response.data.data }, () => {
          // After setting the data in state, initialize DataTable
          $(document).ready(function () {
            $('#transTb').DataTable();
          });
        });
      })
      .catch((error) => {
        console.log(error.response);
      });
  }

  render() {
    return (
      <React.Fragment>
        <Sidebar />
        <div className="content">
          <div className="mt-4">
            <h4>All Transactions</h4>
          </div>

          <div className="mt-4">
            <div className="container">
              <table id="transTb" className="display">
                <thead>
                  <tr>
                    <th>Receipt No.</th>
                    <th>Initiation Time</th>
                    <th>Completion Time</th>
                    <th>Paid Amount</th>
                    <th>Phone No</th>
                    <th>Balance</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.data.length > 0 ? (
                    this.state.data.map((trans) => (
                      <tr key={trans.mpesaReceiptNumber}>
                        <td>{trans.mpesaReceiptNumber}</td>
                        <td>{trans.created_at}</td>
                        <td>{trans.updated_at}</td>
                        <td>{trans.amount}</td>
                        <td>{trans.phone}</td>
                        <td>{trans.balance}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="6" className="text-center">
                        Loading...
                      </td>
                    </tr>
                  )}
                </tbody>
                <tfoot>
                  <tr>
                    <th>Receipt No.</th>
                    <th>Initiation Time</th>
                    <th>Completion Time</th>
                    <th>Paid Amount</th>
                    <th>Phone No</th>
                    <th>Balance</th>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  errors: state.errors,
  auth: state.auth,
});

export default connect(mapStateToProps)(MpesaReconciliation);

