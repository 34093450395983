import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { postSMS } from "../../actions/smsActions";
import Swal from "sweetalert2";
import jwt_decode from "jwt-decode";
import { Preloader, Sidebar, Navbar } from "../common";

class SMS extends Component {
  state = {
    fileUploaded: "",
    message: "",
    smsResponse: "",
    email: "",
    auth: {},
    preloaderStyle: "d-none",
    characterCount: 0,
    cost: 0,
  };

  handlePreloaderStyle = (newStyle) => {
    this.setState({
      preloaderStyle: newStyle,
    });
  };

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onChangeFile = (e) => {
    console.log(e.target.files[0]);
    this.setState({
      fileUploaded: e.target.files[0],
    });
  };

  componentDidMount() {
    const decoded = jwt_decode(localStorage.jwtToken);
    this.setState({
      email: decoded.personnel_email,
    });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.sms) {
      console.log(nextProps.sms);
      this.setState({
        fileUploaded: "",
        message: "",
      });
      JSON.stringify(Swal.fire("SMS sent Successful", "", "success"));
      nextProps.sms.message = "";
      this.handlePreloaderStyle("d-none");
    }
    if (nextProps.auth) {
      this.setState({
        auth: nextProps.auth,
        email: nextProps.auth.personnel.personnel_email,
      });
    }
  }

  postSMSDetails = (e) => {
    e.preventDefault();
    this.handlePreloaderStyle("d-block");
    const { fileUploaded, message, email } = this.state;
    const formData = new FormData();
    formData.append("file", fileUploaded);
    formData.append("message", message);
    formData.append("email", email);

    this.props.postSMS(formData);
  };

  count_up = (e) => {
    let characters = e.target.value.length;
    let price = 0.6;
    // let price = (characters / 180) * 0.6;
    // const rounded = Math.round(price * 100) / 100;
    if (characters > 0 && characters <= 180) {
      this.setState({
        characterCount: characters,
        cost: price,
      });
    } else {
      if (characters > 0 && characters <= 360) {
        this.setState({
          characterCount: characters,
          cost: price * 2,
        });
      } else {
        if (characters > 0 && characters <= 720) {
          this.setState({
            characterCount: characters,
            cost: price * 3,
          });
        }
      }
    }
  };

  render() {
    const { message, preloaderStyle, characterCount, cost } = this.state;
    // console.log(fileUploaded, message);

    return (
      <React.Fragment>
        <Preloader preloaderStyle={preloaderStyle} />
        <Sidebar />
        <div className="content">
          <div className="mt-4">
            <Navbar title="Bulk SMS" toggleSearch={this.toggleSearch} />
          </div>
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">Bulk SMS</h5>
              <form className="md-form mt-5">
                <div className="form-group">
                  <label className="control-label mb-10 text-left">
                    Upload Recepients
                  </label>
                  <input
                    type="file"
                    className="form-control"
                    name="file"
                    onChange={this.onChangeFile}
                  />
                </div>
                <div className="form-group mt-3">
                  <label className="control-label mb-10 text-left">
                    Message Template
                  </label>
                  <textarea
                    className="form-control"
                    aria-label="With textarea"
                    name="message"
                    value={message}
                    onChange={this.onChange}
                    onKeyUp={this.count_up}
                  ></textarea>
                  <span className="text-muted pull-right">
                    {characterCount + " characters at Ksh." + cost}
                  </span>
                </div>
                <button
                  className="btn btn-primary"
                  onClick={this.postSMSDetails}
                  style={{ display: "none" }}
                >
                  SUBMIT
                </button>
              </form>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

SMS.propTypes = {
  postSMS: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  sms: state.sms.sms,
  errors: state.sms.error,
  auth: state.auth,
});

export default connect(mapStateToProps, { postSMS })(SMS);
