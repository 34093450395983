import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import jwt_decode from "jwt-decode";
import setAuthToken from "./utilities/setAuthToken";
import { setCurrentUser, setCurrentAgent } from "./actions/authActions";
import { logoutUser, logoutAgent } from "./actions/authActions";

import { Provider } from "react-redux";
import store from "./store";

import "@fortawesome/fontawesome-free/css/all.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "bootstrap-datepicker";
import "react-dates/lib/css/_datepicker.css";
import "react-dates/initialize";

import {
  Login,
  ResetPassword,
  MerchantLogin,
  MerchantResetPassword,
  Login_New,
  ResetPassword_New,
} from "./components/auth";
import {
  Tasks,
  Dashboard,
  Locks,
  Commissions,
  Personnel,
  Campaign,
  Customers,
  Agents,
  CheckAuth,
  RadUser,
  Payments,
  AgentPayments,
  Customers_New,
  Personnel_New,
  Agents_New,
  AgentPayments_New,
  MpesaTransactions,
  MpesaReconciliation
} from "./components/layout";

// import MpesaTransactions from "./components/layout/MpesaTransactions";

import AnnouncementsLayout from "./components/announcements/AnnouncementsLayout";
import SMS from "./components/sms/SMS";
import SingleSms from "./components/sms/SingleSms";
import Messages from "./components/sms/Messages";
import NotFound from "./components/common/NotFound";
import Zabbix from "./components/CST_NOC/Zabbix";
import Automate from "./components/automate/reconnect";
import MainLayout from "./components/NOC_REPORTS/MainLayout";
import { DisconnectMainLayout } from "./components/disconnect";

//check for token
if (localStorage.jwtToken) {
  //set auth token header auth
  setAuthToken(localStorage.jwtToken);
  //decode token and user info and exp
  const decoded = jwt_decode(localStorage.jwtToken);
  //set user and isAuthenticated
  store.dispatch(setCurrentUser(decoded));
  //check for expired token
  const currentTime = Date.now() / 1000;
  if (decoded.exp < currentTime) {
    //Logout user
    store.dispatch(logoutUser());
    //TODO: Clear current user
    //Redirect to admin login page
    // window.location.href = "/admin";
    // window.location.reload('/admin');
  }
}

//check for token
if (localStorage.agentJwtToken) {
  //set auth token header auth
  setAuthToken(localStorage.agentJwtToken);
  //decode token and user info and exp
  const decoded = jwt_decode(localStorage.agentJwtToken);
  //set user and isAuthenticated
  store.dispatch(setCurrentAgent(decoded));

  //check for expired token
  const currentTime = Date.now() / 1000;
  if (decoded.exp < currentTime) {
    //Logout user
    store.dispatch(logoutAgent());
    //TODO: Clear current user

    //Redirect to admin login page
    window.location.href = "/";
    // window.location.reload('/admin');
  }
}
class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <Router>
          <Switch>
            {/* <Route exact path="/" component={MerchantLogin} /> */}
            <Route exact path="/" component={Login_New} />
            <Route exact path="/merchant" component={MerchantLogin} />
            <Route
              exact
              path="/merchantResetPassword"
              component={MerchantResetPassword}
            />
            <Route exact path="/admin" component={Login_New} />
            <Route exact path="/resetPassword" component={ResetPassword_New} />
            <Route exact path="/tasks" component={Tasks} />
            <Route exact path="/dashboard" component={Dashboard} />
            <Route exact path="/locks" component={Locks} />
            <Route exact path="/commissions" component={Commissions} />
            <Route exact path="/personnel" component={Personnel_New} />
            <Route exact path="/campaigns" component={Campaign} />
            <Route exact path="/customers" component={Customers_New} />
            <Route exact path="/agents" component={Agents_New} />
            <Route exact path="/transactions" component={MpesaTransactions} />
            <Route exact path="/reconciliations" component={MpesaReconciliation} />

            <Route
              exact
              path="/announcements"
              component={AnnouncementsLayout}
            />
            <Route exact path="/sms" component={SMS} />
            <Route exact path="/singleSms" component={SingleSms} />
            <Route exact path="/messages" component={Messages} />
            <Route exact path="/cst_noc" component={Zabbix} />
            <Route exact path="/noc_report" component={MainLayout} />
            <Route exact path="/disconnect" component={DisconnectMainLayout} />
            <Route exact path="/checkAuth" component={CheckAuth} />
            <Route exact path="/radUser" component={RadUser} />
            <Route exact path="/payments" component={Payments} />
            <Route exact path="/agentsPayments" component={AgentPayments_New} />
            <Route component={NotFound} />
          </Switch>
        </Router>
      </Provider>
    );
  }
}

export default App;
