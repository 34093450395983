import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { loginPersonnel, requestPin } from "../../actions/authActions";

import { LoginInput, Preloader } from "../common";
import { logo } from "../../assets/img";

class Login_New extends Component {
  state = {
    phone: "",
    password: "",
    errors: {},
    preloaderStyle: "d-none",
    signInDetails: {},
    loginUrl: process.env.REACT_APP_BACKEND + "/auth/signin",
    pin: "",
    showPin: false,
    isAuthenticated: false,
  };

  handlePreloaderStyle = (newStyle) => {
    this.setState({ preloaderStyle: newStyle });
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value, errors: {} });
  };

  static getDerivedStateFromProps(props, state) {
    // handlePreloaderStyle("d-none");
    console.log(props.errors);
    if (props.pinDetails) {
      if (props.pinDetails.message === "Success") {
        return {
          showPin: true,
          errors: {},
          preloaderStyle: "d-none",
        };
      }
    }

    if (props.errors !== state.errors) {
      return {
        errors: props.errors,
        preloaderStyle: "d-none",
      };
    }

    return null;
  }

  componentWillUnmount() {
    this.setState({
      showPin: false,
      errors: {},
    });
  }

  onSubmit = (e) => {
    e.preventDefault();
    const { showPin, personnel_type_id } = this.state;

    this.handlePreloaderStyle("d-block");

    const personnel = {
      phone: this.state.phone,
      password: this.state.password,
    };

    let pinData = {
      phone: this.state.phone,
      pin: this.state.pin,
    };

    if (showPin) {
      this.props.loginPersonnel(pinData, this.props.history);
    } else {
      this.props.requestPin(personnel);
    }
  };

  render() {
    const { preloaderStyle, loginUrl, errors, pin, showPin } = this.state;
    return (
      <React.Fragment>
        <Preloader preloaderStyle={preloaderStyle} />
        <div className="login d-flex justify-content-center align-items-center col-md-12">
          <div className="col-md-3">
            <div className="d-flex align-items-center justify-content-center mb-5">
              <img
                src={logo}
                alt="Mawingu"
                srcset=""
                className="img-responsive"
                height="77"
              />
            </div>
            <form autoComplete="off" onSubmit={this.onSubmit}>
              <LoginInput
                placeholder="Phone Number"
                name="phone"
                error={errors.phone}
                value={this.state.phone}
                onChange={this.onChange}
                icon="fa fa-phone text-white"
              />
              <LoginInput
                placeholder="Password"
                name="password"
                icon="fa fa-lock text-white"
                type="password"
                value={this.state.password}
                onChange={this.onChange}
                error={!showPin && errors.password}
              />
              {showPin && (
                <LoginInput
                  placeholder="Enter Pin"
                  name="pin"
                  icon="fa fa-lock text-white"
                  type="password"
                  value={pin}
                  onChange={this.onChange}
                  error={errors.pin}
                />
              )}
              <div className="d-grid gap-2 mt-4">
                <button
                  type="submit"
                  className=" mt-3 btn btn-light text-uppercase text-primary d-flex align-items-center justify-content-center btn-block"
                >
                  Login
                </button>
              </div>
              <Link
                className="d-flex justify-content-end mt-2"
                to="/resetPassword"
              >
                <span className="text-light reset-text">Reset Password</span>
              </Link>
            </form>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

Login_New.propTypes = {
  loginPersonnel: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    errors: state.errors,
    pinDetails: state.auth.requestPin,
  };
};

export default connect(mapStateToProps, { loginPersonnel, requestPin })(
  withRouter(Login_New)
);
