import React, { Component } from "react";
import Select from "react-select";

class Filter extends Component {
  state = {};
  render() {
    const {
      servers,
      showHostGroups,
      showApplications,
      start_date,
      end_date,
      application,
    } = this.props;
    return (
      <React.Fragment>
        {/* server */}
        <div className="form-field mb-3 col-md-4">
          <label htmlFor="hostgroups">Server</label>
          <Select
            options={servers}
            name="server"
            onChange={(opt, e) => this.props.onSelectOptionServer(opt.value, e)}
          ></Select>
        </div>
        {/* hostgroup */}
        <div className="form-field mb-3 col-md-4">
          <label htmlFor="hostgroups">Host Group</label>
          <Select
            options={showHostGroups}
            name="hostGroup"
            onChange={(opt, e) =>
              this.props.onSelectOptionHostGroup(opt.value, e)
            }
          ></Select>
        </div>
        {/* Applications */}
        <div className="form-field mb-3 col-md-4">
          <label htmlFor="hostgroups">Application</label>
          <Select
            options={showApplications}
            name="host"
            value={application}
            onChange={this.props.onSelectOptionApplication}
          ></Select>
        </div>

        {/* filter by date */}
        {/* start */}
        <div className="form-group col-md-4">
          <label htmlFor="start">Start</label>
          <input
            type="datetime-local"
            name="start_date"
            className="form-control"
            placeholder="Select date"
            value={start_date}
            onChange={this.props.onChange}
          />
        </div>
        {/* end */}
        <div className="form-group col-md-3">
          <label htmlFor="end">End</label>
          <input
            type="datetime-local"
            className="form-control"
            placeholder="Receiver's phone"
            aria-label="Receiver's phone with two button addons"
            aria-describedby="button-addon4"
            name="end_date"
            value={end_date}
            onChange={this.props.onChange}
          />
        </div>

        {/* submit */}
        <div className="flex col-md-2 justify-content-center">
          <button
            className="btn btn-primary"
            onClick={this.props.handleSearchGraph}
          >
            Apply
          </button>
        </div>
      </React.Fragment>
    );
  }
}

export default Filter;
