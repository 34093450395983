import React, { Component } from "react";

class Table extends Component {
  state = {
    historyCount: 0,
  };
  render() {
    const {
      showGraph,
      graphName,
      uniqueItemNames,
      hostItemsHistory,
    } = this.props;

    // let itemNames = props.itemApplications.result.map((itm) => {
    //   return itm.name + "(" + (itm.units === "bps" ? "Mbps" : itm.units) + ")";
    // });
    console.log(hostItemsHistory);

    let tableColumnName =
      uniqueItemNames instanceof Array
        ? uniqueItemNames.map((name) => <th>{name}</th>)
        : null;

    const allHotsReports =
      hostItemsHistory instanceof Array
        ? hostItemsHistory.map((host, index) => (
            <tr key={index}>
              <td key={index + "first"}>{index + 1}</td>
              <td key={index + "second"}>{host.host.name}</td>
              {uniqueItemNames instanceof Array
                ? uniqueItemNames.map((name) => (
                    <td key={name}>
                      {host.items instanceof Array
                        ? host.items
                            .filter(
                              (item) =>
                                item.name +
                                  "(" +
                                  (item.units === "bps" ? "Mbps" : item.units) +
                                  ")" ===
                                name
                            )
                            .map((item) => {
                              let count = 0;
                              return host.history instanceof Array &&
                                host.history.length !== 0
                                ? item.name === "StationCount"
                                  ? (Math.round(
                                      Number(
                                        host.history
                                          .filter(
                                            (appHist) =>
                                              appHist.itemid === item.itemid
                                          )
                                          .map((a) => {
                                            count++;
                                            return a;
                                          })
                                          .reduce(
                                            (a, b) =>
                                              Number(a) +
                                              (Number(b.value) || 0),
                                            0
                                          )
                                      ) / count
                                    ) *
                                      100) /
                                    100
                                  : Math.round(
                                      (Number(
                                        host.history
                                          .filter(
                                            (appHist) =>
                                              appHist.itemid === item.itemid
                                          )
                                          .map((a) => {
                                            count++;
                                            return a;
                                          })
                                          .reduce(
                                            (a, b) =>
                                              item.units === "bps"
                                                ? Number(a) +
                                                  (Number(b.value) / 1000000 ||
                                                    0)
                                                : item.name === "ICMP ping"
                                                ? Number(a) +
                                                  (Number(b.value) * 100 || 0)
                                                : Number(a) +
                                                  (Number(b.value) || 0),
                                            0
                                          )
                                      ) /
                                        count) *
                                        100
                                    ) / 100
                                : "-";
                            })
                        : null}
                    </td>
                  ))
                : null}
            </tr>
          ))
        : null;
    return (
      <React.Fragment>
        <div className="card">
          {showGraph && (
            <React.Fragment>
              {graphName}
              <div className="table-responsive">
                <table
                  className="table table-bordered table-striped table-sm"
                  id="table-to-xls"
                >
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Host</th>
                      {tableColumnName}
                      {/* <th>Bits Received</th> */}
                    </tr>
                  </thead>
                  <tbody>{allHotsReports}</tbody>
                </table>
              </div>
            </React.Fragment>
          )}
        </div>
      </React.Fragment>
    );
  }
}

export default Table;
