import React, { Component } from "react";
import {connect} from "react-redux";

import Navbar from "./Navbar";

import { Preloader } from "../common";

import { Commissions } from "../commissions";

class Locks extends Component {
  constructor(props) {
    super(props);
    this.state = {
      preloaderStyle: "d-none"
    };
  }

//   componentDidMount(){
//     if(!this.props.auth.isAuthenticated){
//         this.props.history.push("/admin");
//     }
//   }
  handlePreloaderStyle = newStyle => {
    this.setState({
      preloaderStyle: newStyle
    });
  };

  render() {
    const { preloaderStyle } = this.state;
    return (
      <React.Fragment>
        <Preloader preloaderStyle={preloaderStyle} />
        <Navbar />
        <div className="container-fluid">
          <Commissions handlePreloaderStyle={this.handlePreloaderStyle} />
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
    auth: state.auth
})

export default connect(mapStateToProps)(Locks);
