import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

class Update extends Component {
  constructor() {
    super();
    this.state = {
      customerName: "",
      task_id: "",
      task_status_id: "",
      customerPhone: "",
      onSubmit: {},
      gender: "",
      location: "",
      age: "",
      access_code: "",
      splash_page: "",
      autoplay: "",
      mpesa: "",
      comments: ""
    };
    this.completeTask = this.completeTask.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  componentDidMount() {
    this.setState({
      customerName: this.props.customerName,
      task_id: this.props.task_id,
      task_status_id: this.props.task_status_id,
      customerPhone: this.props.customerPhone,
      onSubmit: this.props.onSubmit
    });
  }

  completeTask(e) {
    e.preventDefault();
    this.props.handlePreloaderStyle("d-block");
    const task = {
      task_id: this.state.task_id,
      task_status_id: this.state.task_status_id,
      gender: this.state.gender,
      location: this.state.location,
      age: this.state.age,
      access_code: this.state.access_code,
      splash_page: this.state.splash_page,
      autoplay: this.state.autoplay,
      mpesa: this.state.mpesa,
      comments: this.state.comments
    };

    this.props.onSubmit(task, this.props.history);
  }

  render() {
    const { task_id, customerName, customerPhone } = this.state;
    return (
      <React.Fragment>
        <button
          type="button"
          className="btn btn-success btn-sm"
          data-toggle="modal"
          data-target={"#updateModal" + task_id}
        >
          Update
        </button>
        <div
          className="modal fade"
          id={"updateModal" + task_id}
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Update {customerName}
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <form noValidate onSubmit={this.completeTask}>
                <div className="modal-body">
                  <p>
                    <strong>Customer Phone:</strong> {customerPhone}
                  </p>
                  <label>Gender</label>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="gender"
                      id={"gender" + task_id}
                      value="Male"
                      onChange={this.onChange}
                    />
                    <label
                      className="form-check-label"
                      htmlFor={"gender1" + task_id}
                    >
                      Male
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="gender"
                      id={"gender2" + task_id}
                      value="Female"
                      onChange={this.onChange}
                    />
                    <label
                      className="form-check-label"
                      htmlFor={"gender2" + task_id}
                    >
                      Female
                    </label>
                  </div>
                  <div className="form-group">
                    <label htmlFor="location">Location</label>
                    <input
                      type="text"
                      className="form-control"
                      name="location"
                      id={"location" + task_id}
                      placeholder="Location"
                      onChange={this.onChange}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="age">Age</label>
                    <input
                      type="number"
                      className="form-control"
                      name="age"
                      id={"age" + task_id}
                      placeholder="Age"
                      onChange={this.onChange}
                    />
                  </div>
                  <label>Customer Education</label>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="1"
                      name="access_code"
                      id={"access_code" + task_id}
                      onChange={this.onChange}
                    />
                    <label
                      className="form-check-label"
                      htmlFor={"access_code" + task_id}
                    >
                      Access Code
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="1"
                      name="splash_page"
                      id={"splash_page" + task_id}
                      onChange={this.onChange}
                    />
                    <label
                      className="form-check-label"
                      htmlFor={"splash_page" + task_id}
                    >
                      Splash Page
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="1"
                      name="autoplay"
                      id={"autoplay" + task_id}
                      onChange={this.onChange}
                    />
                    <label
                      className="form-check-label"
                      htmlFor={"autoplay" + task_id}
                    >
                      Turn off autoplay
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="1"
                      name="mpesa"
                      id={"mpesa" + task_id}
                      onChange={this.onChange}
                    />
                    <label
                      className="form-check-label"
                      htmlFor={"mpesa" + task_id}
                    >
                      M-Pesa training
                    </label>
                  </div>
                  <div className="form-group">
                    <label htmlFor="comments">Comments</label>
                    <textarea
                      className="form-control"
                      name="comments"
                      id={"comments" + task_id}
                      rows="3"
                      onChange={this.onChange}
                    />
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                  <button
                    type="button"
                    onClick={this.completeTask}
                    className="btn btn-success"
                    data-dismiss="modal"
                  >
                    Update Customer
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

Update.propTypes = {
  customerName: PropTypes.string.isRequired,
  task_id: PropTypes.number.isRequired,
  task_status_id: PropTypes.number.isRequired,
  onSubmit: PropTypes.func.isRequired,
  handlePreloaderStyle: PropTypes.func.isRequired
};

export default connect(
  null,
  {}
)(withRouter(Update));
