import React from "react";
import { MDBDataTable } from 'mdbreact';

const List = ({ tableBody, data }) => {
  return (
    <React.Fragment>
      <div className="table-responsive">
        {/* <table className="table table-sm table-bordered table-striped tabe-condensed">
          <thead>
            <tr>
              <th>Customer Number</th>
              <th>Account Name</th>
              <th>Balance Due</th>
              <th>Phone Number</th>
              <th>Email</th>
              <th>Special List?</th>
              <th colSpan="1">Actions</th>
            </tr>
          </thead>
        </table> */}
        <MDBDataTable
          striped
          bordered
          small
          data={data}
        />
      </div>
    </React.Fragment>
  );
};

export default List;
