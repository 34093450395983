import React, { Component } from "react";
import PropTypes from "prop-types";
import { CSVLink } from "react-csv";

class SummaryList extends Component {
  render() {
    const { payments, page, limit, paymentsExport, exportTitle } = this.props;
    let exportCommissions;
    if (localStorage.jwtToken) {
      if (paymentsExport.length > 0) {
        exportCommissions = (
          <React.Fragment>
            <CSVLink
              data={paymentsExport}
              filename={exportTitle}
              className="btn btn-sm btn-success float-right"
              target="_blank"
            >
              Export
            </CSVLink>
          </React.Fragment>
        );
      } else {
        exportCommissions = null;
      }
    } else {
      exportCommissions = null;
    }
    let count = page * limit + 1;
    let allPayments;

    if (payments.length > 0) {
      // console.log(payments);
      allPayments = payments.map(payment => (
        <tr key={payment.payment_id}>
          <td>{count++}</td>
          <td>{payment.customer_name}</td>
          <td>{payment.customer_phone}</td>
          <td>{payment.agent_name}</td>
          <td>{payment.agent_phone}</td>
          <td>{payment.transaction_date}</td>
          <td>{payment.lock_date}</td>
          <td>{payment.transaction_amount}</td>
          <td>{payment.commission_amount}</td>
          <td>{payment.mpesa_ref}</td>
          <td>{payment.payment_type === 1 ? "First Day" : "Legacy"}</td>
        </tr>
      ));
    } else {
      allPayments = null;
    }
    return (
      <React.Fragment>
        <div className="card">
          <div className="card-body">
            {exportCommissions}
            <h5 className="card-title">Commissions</h5>
            <div className="table-responsive">
              <table className="table table-sm table-bordered table-striped table-condensed">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Customer Name</th>
                    <th>Customer Phone</th>
                    <th>Agent Name</th>
                    <th>Agent Phone</th>
                    <th>Transaction Date</th>
                    <th>Locking Date</th>
                    <th>Transaction Amount</th>
                    <th>Commision Amount</th>
                    <th>Mpesa Ref.</th>
                    <th>Commision Type</th>
                  </tr>
                </thead>
                <tbody>{allPayments}</tbody>
              </table>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
SummaryList.propTypes = {
  payments: PropTypes.array.isRequired,
  page: PropTypes.number.isRequired,
  limit: PropTypes.number.isRequired
};
export default SummaryList;
