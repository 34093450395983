import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { reassignTask, fetchTelesales } from "../../actions/tasksActions";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Preloader from "../common/Preloader";

class BulkAssign extends Component {
  constructor() {
    super();
    this.state = {
      assignFrom: "",
      assignTo: "",
      numberOfTasks: "",
      tasksToReassign: [],
      telesales: [],
      preloaderStyle: "d-none",
      successMessage: ""
    };
  }

  handlePreloaderStyle = newStyle => {
    this.setState({
      preloaderStyle: newStyle
    });
  };

  componentDidMount() {
    this.props.fetchTelesales();
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ preloaderStyle: "d-none" });
    if (nextProps.tasksToReassign) {
      // this.setState({ successMessage: "" });
      const newTasksToReassign = nextProps.tasksToReassign;
      this.setState({
        tasksToReassign: newTasksToReassign
      });
    }
    if (nextProps.telesales) {
      //   console.log(nextProps.telesales);
      const newTelesales = nextProps.telesales;
      this.setState({
        telesales: newTelesales
      });
    }
  }

  onChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  reassign = e => {
    e.preventDefault();

    this.handlePreloaderStyle("d-block");

    const tasks = {
      assignFrom: this.state.assignFrom,
      assignTo: this.state.assignTo,
      numberOfTasks: this.state.numberOfTasks
    };

    this.props.reassignTask(tasks);
    // this.onClose();
    this.successMessage();
  };

  successMessage = () => {
    //   const successMessage = this.props.successMessage;
    this.setState({
      successMessage: "You have successfully reassign tasks"
    });
  };

  onClose = e => {
    this.props.onClose && this.props.onClose(e);
    this.setState({
      assignFrom: "",
      assignTo: "",
      numberOfTasks: "",
      successMessage: "",
      preloaderStyle: "d-none"
    });
  };
  render() {
    const {
      numberOfTasks,
      telesales,
      preloaderStyle,
      successMessage
    } = this.state;
    // console.log(telesales);
    const telesalePerson = telesales.map(telesale => (
      <React.Fragment key={telesale.personnel_id}>
        <option value={telesale.personnel_id}>
          {telesale.personnel_fname} {telesale.personnel_onames}{" "}
          {telesale.personnel_phone}
        </option>
      </React.Fragment>
    ));
    return (
      <React.Fragment>
        <div
          className="modal fade"
          id="reassignTasks"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Reassign Tasks
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <form noValidate>
                <div className="modal-body">
                  {successMessage === "" ? null : (
                    <div className="alert alert-success" role="alert">
                      {successMessage}
                    </div>
                  )}

                  <div className="container-fluid">
                    <Preloader preloaderStyle={preloaderStyle} />
                    <div className="form-group">
                      <label>Assign From</label>
                      <select
                        name="assignFrom"
                        tabIndex="-1"
                        aria-hidden="true"
                        onChange={this.onChange}
                        className="form-control select2 select2-hidden-accessible"
                      >
                        <option value="">---Select From---</option>
                        {telesalePerson}
                      </select>
                      <span
                        className="select2 select2-container select2-container--default select2-container--below"
                        dir="ltr"
                        style={{ width: "100px" }}
                      >
                        <span className="selection">
                          <span
                            className="select2-selection select2-selection--single"
                            // role="combobox"
                            // aria-controls="false"
                            aria-haspopup="true"
                            aria-expanded="false"
                            tabIndex="0"
                            aria-labelledby="select2-assign_from-x9-container"
                          >
                            <span
                              className="select2-selection__rendered"
                              id="select2-assign_from-x9-container"
                              title="---Select From---"
                            />
                            <span
                              className="select2-selection__arrow"
                              role="presentation"
                            >
                              <b role="presentation" />
                            </span>
                          </span>
                        </span>
                        <span className="dropdown-wrapper" aria-hidden="true" />
                      </span>
                    </div>
                    <div className="form-group">
                      <label>Assign To</label>
                      <select
                        name="assignTo"
                        onChange={this.onChange}
                        className="form-control select2 select2-hidden-accessible"
                        tabIndex="-1"
                        aria-hidden="true"
                      >
                        <option value="">---Select To---</option>
                        {telesalePerson}
                      </select>
                      <span
                        className="select2 select2-container select2-container--default select2-container--below"
                        dir="ltr"
                        style={{ width: "100px" }}
                      >
                        <span className="selection">
                          <span
                            className="select2-selection select2-selection--single"
                            // role="combobox"
                            aria-haspopup="true"
                            aria-expanded="false"
                            tabIndex="0"
                            aria-labelledby="select2-assign_to-2m-container"
                          >
                            <span
                              className="select2-selection__rendered"
                              id="select2-assign_to-2m-container"
                              title="---Select To---"
                            />
                            <span
                              className="select2-selection__arrow"
                              role="presentation"
                            >
                              <b role="presentation" />
                            </span>
                          </span>
                        </span>
                        <span className="dropdown-wrapper" aria-hidden="true" />
                      </span>
                    </div>
                    <div className="form-group">
                      <label>Number of Tasks</label>
                      <input
                        type="text"
                        className="form-control"
                        name="numberOfTasks"
                        onChange={this.onChange}
                        placeholder="Number of Tasks"
                        value={numberOfTasks}
                        pattern="[0-9]*"
                      />
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                  <button
                    type="button"
                    onClick={this.reassign}
                    className="btn btn-success"
                    data-dismiss="modal"
                  >
                    Bulk Reassign
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

BulkAssign.propTypes = {
  reassignTask: PropTypes.func.isRequired,
  //   tasksToReassign: PropTypes.array.isRequired,
  fetchTelesales: PropTypes.func.isRequired,
  telesales: PropTypes.array.isRequired
};

const mapStateToProps = state => ({
  //   tasksToReassign: state.tasksReassign.tasksToReassign,
  telesales: state.tasks.telesales
  // errors: state.errors
});
export default connect(
  mapStateToProps,
  { reassignTask, fetchTelesales }
)(withRouter(BulkAssign));
