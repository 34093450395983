import React, { Component } from "react";
import PropTypes from "prop-types";

class CreateNotification extends Component {
  constructor() {
    super();
    this.state = {
      title: "",
      body: ""
    };
  }

  onChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  submitNewAnnouncement = e => {
    e.preventDefault();

    const { title, body } = this.state;

    const announcement = {
      title,
      body
    };
    this.props.handlePreloaderStyle("d-block");
    this.props.handleCreateNotification(announcement);
  };

  componentWillReceiveProps = () => {
    const { title, body } = this.props;
    this.setState({
      title, body
    });
  };

  render() {
    const { title, body } = this.state;
    const { errors } = this.props;

    return (
      <React.Fragment>
        <button
          type="button"
          className="btn btn-primary btn-sm"
          data-toggle="modal"
          data-target="#addNewNotification"
        >
          Create Notification
        </button>
        <div
          className="modal fade"
          id="addNewNotification"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Create Notification{" "}
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <form noValidate>
                <div className="modal-body">
                  <div className="container-fluid">
                    <div className="form-group">
                      <label htmlFor="exampleInputPassword1">
                        Notification Title
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Notification Title"
                        onChange={this.onChange}
                        name="title"
                        value={title}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="exampleInputPassword1">
                        Notification Body
                      </label>
                      <textarea
                        name="body"
                        value={body}
                        id=""
                        cols="60"
                        rows="7"
                        label="Announcement"
                        placeholder="Enter Notification"
                        onChange={this.onChange}
                      ></textarea>
                    </div>

                    {errors !== "undefined" ? (
                      <p style={{ color: "red" }}>{errors}</p>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                  <button
                    type="button"
                    onClick={this.submitNewAnnouncement}
                    className="btn btn-success"
                  >
                    Create Notification
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

CreateNotification.propTypes = {
  handleCreateNotification: PropTypes.func.isRequired,
  handlePreloaderStyle: PropTypes.func.isRequired
};

export default CreateNotification;
