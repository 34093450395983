import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Swal from "sweetalert2";

import { List_New, Filter_New } from "../agentPayments";

import {
  fetchAgentsPayments,
  fetchAgentsPaymentsExport,
} from "../../actions/agentsActions";

import { Error, Preloader, Navbar, Sidebar } from "../common";

class AgentPayments_New extends Component {
  constructor() {
    super();
    this.state = {
      agentPayments: {},
      mpesaReceipt: "",
      errors: "",
      page: 0,
      limit: 10,
      phone: "",
      preloaderStyle: "d-none",
      count: 0,
      date: "",
      agentPaymentsExport: [],
      showSearch: false,
    };
  }

  toggleSearch = () => {
    const { showSearch } = this.state;
    this.setState({
      showSearch: !showSearch,
    });
  };

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handlePreloaderStyle = (newStyle) => {
    this.setState({ preloaderStyle: newStyle });
  };

  incrementPage = (e) => {
    e.preventDefault();
    const { limit, page, count, phone, mpesaReceipt } = this.state;
    const totalPages = Math.ceil(count / limit);

    const newPage = page + 1;
    if (newPage <= totalPages) {
      this.setState({ page: newPage });
      this.fetchAgentsPayments(newPage, limit, phone, mpesaReceipt);
    }
  };

  decrementPage = (e) => {
    e.preventDefault();
    const { page, limit, phone, mpesaReceipt } = this.state;

    const newPage = page - 1;
    if (newPage >= 0) {
      this.setState({ page: newPage });
      this.fetchAgentsPayments(newPage, limit, phone, mpesaReceipt);
    }
  };

  onChangePage = (e) => {
    const { limit, phone, mpesaReceipt } = this.state;
    const newPage = parseInt(e.target.value);
    this.setState({ [e.target.name]: newPage });

    this.fetchAgentsPayments(newPage, limit, phone, mpesaReceipt);
  };

  fetchAgentsPayments = (page, limit, phone, mpesaReceipt) => {
    this.handlePreloaderStyle("d-block");
    this.props.fetchAgentsPayments(page * limit, limit, phone, mpesaReceipt);
  };

  componentDidMount() {
    if (!this.props.auth.isAuthenticated) {
      this.props.history.push("/admin");
    }
    this.fetchAgentsPayments(
      this.state.page,
      this.state.limit,
      this.state.phone,
      this.state.mpesaReceipt
    );
  }

  onChangeDate = (e) => {
    this.handlePreloaderStyle("d-block");

    e.preventDefault();
    this.setState({
      [e.target.name]: e.target.value,
    });
    this.props.fetchAgentsPaymentsExport(e.target.value);
  };

  static getDerivedStateFromProps(nextProps, state) {
    // console.log({ nextProps, state });
    if (nextProps.agentPayments !== state.agentPayments) {
      const newCustomers = nextProps.agentPayments;
      return {
        agentPayments: newCustomers,
        preloaderStyle: "d-none",
      };
    }
    if (nextProps.agentPaymentsExport !== state.agentPaymentsExport) {
      const newCustomers = nextProps.agentPaymentsExport;
      return {
        agentPaymentsExport: newCustomers,
        preloaderStyle: "d-none",
      };
    }

    if (nextProps.errors.length > 0) {
      return { errorMessage: nextProps.errors };
    }
    return null;
  }

  errorMessageHandler = (newError) => {
    this.setState({ errorMessage: newError });
  };

  searchHandler = (phone, mpesaReceipt) => {
    this.handlePreloaderStyle("d-block");
    this.setState({
      phone: phone,
      mpesaReceipt: mpesaReceipt,
    });

    this.fetchAgentsPayments(
      this.state.page,
      this.state.limit,
      phone,
      mpesaReceipt
    );
  };

  render() {
    const {
      limit,
      page,
      errorMessage,
      preloaderStyle,
      agentPayments,
      date,
      agentPaymentsExport,
      showSearch,
    } = this.state;

    return (
      <React.Fragment>
        <Preloader preloaderStyle={preloaderStyle} />
        <Sidebar />
        <div className="content">
          {/* <Error message={errorMessage} /> */}
          <div className="mt-4">
            <Navbar title="Agents Payments" toggleSearch={this.toggleSearch} />
          </div>
          <div className="mt-4">
            {showSearch ? (
              <Filter_New
                searchHandler={this.searchHandler}
                toggleSearch={this.toggleSearch}
              />
            ) : (
              <List_New
                page={page}
                limit={limit}
                incrementPage={this.incrementPage}
                decrementPage={this.decrementPage}
                onChangePage={this.onChangePage}
                searchHandler={this.searchHandler}
                errorMessageHandler={this.errorMessageHandler}
                handlePreloaderStyle={this.handlePreloaderStyle}
                onChange={this.onChange}
                agentPayments={agentPayments}
                date={date}
                onChangeDate={this.onChangeDate}
                agentPaymentsExport={agentPaymentsExport}
              />
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

AgentPayments_New.propTypes = {
  fetchAgentsPayments: PropTypes.func.isRequired,
  fetchAgentsPaymentsExport: PropTypes.func.isRequired,
  agentPaymentsExport: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  agentPayments: state.commissions.agentPayments,
  errors: state.errors,
  auth: state.auth,
  agentPaymentsExport: state.commissions.agentPaymentsExport,
});

export default connect(mapStateToProps, {
  fetchAgentsPayments,
  fetchAgentsPaymentsExport,
})(AgentPayments_New);
