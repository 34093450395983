import React, { Component } from "react";
import moment from "moment";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import {
  fetchAdminTaskSummary,
  fetchTimeSummary,
  addPersonnel
} from "../../actions/tasksActions";
import { fetchPersonnelTransactions } from "../../actions/transactionsActions";

import Navbar from "./Navbar";
import { Target, PersonnelSummary, AverageTime, BulkAssign } from "../tasks";
import { RevenueChart } from "../charts";
import { Error, DatePicker, Preloader } from "../common";

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      adminTaskSummary: [],
      errors: "",
      personnelTransactions: [],
      personnelDailyTarget: 40,
      successMessage: "",
      date: moment(),
      focused: false,
      dateId: "summaryDate",
      reportDate: moment().format("YYYY-MM-DD"),
      tasks: [],
      preloaderStyle: "d-none"
    };

    this.errorHandler = this.errorHandler.bind(this);
  }

  handlePreloaderStyle = newStyle => {
    this.setState({ preloaderStyle: newStyle });
  };

  fetchDashboard(date) {
    this.handlePreloaderStyle("d-block");
    this.props.fetchAdminTaskSummary(date);
    this.props.fetchPersonnelTransactions(date);
    this.props.fetchTimeSummary(date);
  }

  handleDateChange = date => {
    const newDate = moment(date).format("YYYY-MM-DD");
    this.setState({ date });
    this.setState({ reportDate: newDate });
    this.fetchDashboard(newDate);
  };

  handleFocusChange = ({ focused }) => this.setState({ focused });

  componentDidMount() {
    if (!this.props.auth.isAuthenticated) {
      this.props.history.push("/admin");
    }
    // console.log(this.props.auth.personnel.given_name);
    const { reportDate } = this.state;
    this.fetchDashboard(reportDate);
  }

  componentWillReceiveProps(nextProps) {
    this.handlePreloaderStyle("d-none");
    if (nextProps.adminTaskSummary) {
      const newTasks = nextProps.adminTaskSummary;
      this.setState({
        adminTaskSummary: newTasks
      });
    }

    if (nextProps.personnelTransactions) {
      // console.log(nextProps.personnelTransactions);
      const newTransactions = nextProps.personnelTransactions;
      this.setState({
        personnelTransactions: newTransactions
      });
    }

    if (nextProps.tasks) {
      this.setState({
        tasks: nextProps.tasks
      });
    }

    if (nextProps.errors.length > 0) {
      this.setState({
        errors: nextProps.errors
      });
    }
  }

  errorHandler(newError) {
    this.state({ errors: newError });
  }

  render() {
    const {
      adminTaskSummary,
      errors,
      personnelTransactions,
      personnelDailyTarget,
      date,
      focused,
      preloaderStyle,
      dateId,
      tasks
    } = this.state;

    let totalPurchases = 0;
    let sumOfAssigned = 0;
    let sumOfDeffered = 0;
    let sumOfCompleted = 0;
    const totalDailyAssignedTarget =
      adminTaskSummary.length * personnelDailyTarget;

    if (adminTaskSummary.length > 0) {
      for (let r = 0; r < adminTaskSummary.length; r++) {
        totalPurchases +=
          adminTaskSummary[r].purchased > 0 ? adminTaskSummary[r].purchased : 0;
        sumOfAssigned += adminTaskSummary[r].assigned;
        sumOfDeffered += adminTaskSummary[r].deferred;
        sumOfCompleted += adminTaskSummary[r].completed;
      }
    }

    return (
      <React.Fragment>
        <Preloader preloaderStyle={preloaderStyle} />
        <Navbar />
        <div className="container-fluid">
          <Error message={errors} />

          <div className="row">
            <div className="col-md-4">
              <div className="card">
                <div className="card-body">
                  <DatePicker
                    date={date}
                    dateId={dateId}
                    focused={focused}
                    handleDateChange={this.handleDateChange}
                    handleFocusChange={this.handleFocusChange}
                  />
                </div>
              </div>
              <Target
                sumOfCompleted={sumOfCompleted}
                totalDailyAssignedTarget={totalDailyAssignedTarget}
                sumOfAssigned={sumOfAssigned}
                sumOfDeffered={sumOfDeffered}
                totalPurchases={totalPurchases}
              />
            </div>
            <div className="col-md-8">
              <RevenueChart handlePreloaderStyle={this.handlePreloaderStyle} />
            </div>
          </div>
          <div className="row">
            <div className="col-md-8">
              <PersonnelSummary
                adminTaskSummary={adminTaskSummary}
                personnelTransactions={personnelTransactions}
              />
            </div>
            <div className="col-md-4">
              <AverageTime tasks={tasks} />
            </div>
          </div>
        </div>
        <BulkAssign />
      </React.Fragment>
    );
  }
}

Dashboard.propTypes = {
  fetchTimeSummary: PropTypes.func.isRequired,
  tasks: PropTypes.array.isRequired,
  fetchAdminTaskSummary: PropTypes.func.isRequired,
  fetchPersonnelTransactions: PropTypes.func.isRequired,
  adminTaskSummary: PropTypes.array.isRequired
};
const mapStateToProps = state => ({
  adminTaskSummary: state.tasks.adminTaskSummary,
  errors: state.errors,
  personnelTransactions: state.transactions.transactions,
  tasks: state.tasks.timeSummary,
  auth: state.auth
});

export default connect(
  mapStateToProps,
  {
    fetchAdminTaskSummary,
    fetchPersonnelTransactions,
    fetchTimeSummary,
    addPersonnel
  }
)(Dashboard);
