import {
  FETCH_PERSONNEL_TRANSACTIONS,
  GET_ERRORS
} from './types';

import {
  axios,
  createError
} from '../utilities';

export const fetchPersonnelTransactions = (date) => dispatch => {

  let url = `/customer-credit/tasks/completed/personnel`;
  if (date !== '') {
    url += `?date=${date}`;
  }

  axios
    .get(url)
    .then(transactions => {
      // console.log(transactions.data);
      dispatch({
        type: FETCH_PERSONNEL_TRANSACTIONS,
        payload: transactions.data
      })
    })
    .catch(err => {
      dispatch(createError(err, GET_ERRORS));
    })
}