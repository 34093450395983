import {
    LOGIN_ZABBIX,
    GET_ERRORS,
    FETCH_HOSTGROUPS,
    FETCH_HOSTS,
    FETCH_GRAPHS,
    FETCH_ITEMS,
    FETCH_HISTORY,
    FETCH_HOSTS_HISTORY,
    FETCH_APPLICATIONS,
    FETCH_ITEM_APPLICATION,
    FETCH_HOSTS_HOSTGROUP,
} from "./types";
// import axios from 'axios';
import {
    createError
} from "../utilities";
import axios from "axios";

const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
};

// LOGIN
export const loginZabbix = (base_url) => (dispatch) => {

    let url = `api_jsonrpc.php`;

    let data = {
        jsonrpc: "2.0",
        method: "user.login",
        params: {
            user: "api",
            password: "1yNCCzuTg",
        },
        id: 1,
    };

    axios({
            transformRequest: [
                (data, headers) => {
                    delete headers.common.Authorization
                    return data;
                },
            ],
            method: "post",
            baseURL: base_url,
            url,
            data: JSON.stringify(data),
            headers,
        })
        .then((response) => {
            dispatch({
                type: LOGIN_ZABBIX,
                payload: response.data,
            });
        })
        .catch((err) => {
            dispatch(createError(err, GET_ERRORS));
        });
};

// FETCH HOST GROUPS
export const fetchHostGroups = (token, base_url) => (dispatch) => {
    let url = `api_jsonrpc.php`;

    let data = {
        jsonrpc: "2.0",
        method: "hostgroup.get",
        params: {},
        id: 1,
        auth: token,
    };

    axios({
            transformRequest: [
                (data, headers) => {
                    delete headers.common.Authorization
                    return data;
                },
            ],
            method: "post",
            baseURL: base_url,
            url,
            data: JSON.stringify(data),
            headers,
        })
        .then((response) => {
            dispatch({
                type: FETCH_HOSTGROUPS,
                payload: response.data,
            });
        })
        .catch((err) => {
            dispatch(createError(err, GET_ERRORS));
        });
};

// FETCH HOSTS FOR A SPECIFIC HOSTGROUP
export const fetchHostsForHostGroup = (hostGroupIds, token, base_url) => (
    dispatch
) => {
    let hostID = parseInt(hostGroupIds);

    let url = `api_jsonrpc.php`;

    let data = {
        jsonrpc: "2.0",
        method: "host.get",
        params: {
            groupids: hostID,
        },
        id: 1,
        auth: token,
    };

    axios({
            transformRequest: [
                (data, headers) => {
                    delete headers.common.Authorization
                    return data;
                },
            ],
            method: "post",
            baseURL: base_url,
            url,
            data: JSON.stringify(data),
            headers,
        })
        .then((response) => {
            dispatch({
                type: FETCH_HOSTS_HOSTGROUP,
                payload: response.data,
            });
        })
        .catch((err) => {
            dispatch(createError(err, GET_ERRORS));
        });
};

// FETCH HOSTS
export const fetchHosts = (hostGroupIds, token, base_url) => (dispatch) => {
    let url = `api_jsonrpc.php`;
    let data;
    if (hostGroupIds === 0) {
        data = {
            jsonrpc: "2.0",
            method: "host.get",
            params: {},
            id: 1,
            auth: token,
        };
    } else {
        data = {
            jsonrpc: "2.0",
            method: "host.get",
            params: {
                hostids: hostGroupIds,
            },
            id: 1,
            auth: token,
        };
    }
    // let data = {
    //     "jsonrpc": "2.0",
    //     "method": "host.get",
    //     "params": {
    //         "hostids": hostGroupIds
    //     },
    //     "id": 1,
    //     "auth": token
    // }

    axios({
            transformRequest: [
                (data, headers) => {
                    delete headers.common.Authorization
                    return data;
                },
            ],
            method: "post",
            baseURL: base_url,
            url,
            data: JSON.stringify(data),
            headers,
        })
        .then((response) => {
            dispatch({
                type: FETCH_HOSTS,
                payload: response.data,
            });
        })
        .catch((err) => {
            dispatch(createError(err, GET_ERRORS));
        });
};

// FETCH HOSTS FROM GROUP IDs
export const fetchGroupHosts = (hostGroupIds, token, base_url) => (dispatch) => {
    let url = `api_jsonrpc.php`;
    let data;
    if (hostGroupIds === 0) {
        data = {
            jsonrpc: "2.0",
            method: "host.get",
            params: {},
            id: 1,
            auth: token,
        };
    } else {
        data = {
            jsonrpc: "2.0",
            method: "host.get",
            params: {
                groupids: hostGroupIds,
            },
            id: 1,
            auth: token,
        };
    }

    axios({
            transformRequest: [
                (data, headers) => {
                    delete headers.common.Authorization
                    return data;
                },
            ],
            method: "post",
            baseURL: base_url,
            url,
            data: JSON.stringify(data),
            headers,
        })
        .then((response) => {
            dispatch({
                type: FETCH_HOSTS,
                payload: response.data,
            });
        })
        .catch((err) => {
            dispatch(createError(err, GET_ERRORS));
        });
};

// FETCH GRAPH
export const fetchGraph = (hostId, token, base_url) => (dispatch) => {
    let url = `api_jsonrpc.php`;

    let data = {
        jsonrpc: "2.0",
        method: "graph.get",
        params: {
            hostids: [hostId],
        },
        id: 1,
        auth: token,
    };

    axios({
            transformRequest: [
                (data, headers) => {
                    delete headers.common.Authorization
                    return data;
                },
            ],
            method: "post",
            baseURL: base_url,
            url,
            data: JSON.stringify(data),
            headers,
        })
        .then((response) => {
            dispatch({
                type: FETCH_GRAPHS,
                payload: response.data,
            });
        })
        .catch((err) => {
            dispatch(createError(err, GET_ERRORS));
        });
};

// FETCH ITEM
export const fetchItems = (graphId, token, base_url) => (dispatch) => {
    let url = `api_jsonrpc.php`;

    let data = {
        jsonrpc: "2.0",
        method: "item.get",
        params: {
            graphids: [graphId],
        },
        id: 1,
        auth: token,
    };

    axios({
            transformRequest: [
                (data, headers) => {
                    delete headers.common.Authorization
                    return data;
                },
            ],
            method: "post",
            baseURL: base_url,
            url,
            data: JSON.stringify(data),
            headers,
        })
        .then((response) => {
            dispatch({
                type: FETCH_ITEMS,
                payload: response.data,
            });
        })
        .catch((err) => {
            dispatch(createError(err, GET_ERRORS));
        });
};

// FETCH HISTORY
export const fetchHistory = (
    itemIds,
    start_date,
    end_date,
    token,
    base_url,
    graph
) => (dispatch) => {
    console.log('start_date', start_date)
    console.log('end_date', end_date)
    console.log(itemIds);

    let startDate = parseInt(start_date);
    let endDate = parseInt(end_date);
    let url = `api_jsonrpc.php`;
    let data;
    if (graph === 'ICMP Ping Response Time') {
        data = {
            jsonrpc: "2.0",
            method: "history.get",
            params: {
                itemids: itemIds,
                time_from: startDate,
                time_till: endDate,
                history: 0
            },
            id: 1,
            auth: token,
        };
    } else {
        data = {
            jsonrpc: "2.0",
            method: "history.get",
            params: {
                itemids: itemIds,
                time_from: startDate,
                time_till: endDate,
            },
            id: 1,
            auth: token,
        };
    }


    axios({
            transformRequest: [
                (data, headers) => {
                    delete headers.common.Authorization
                    return data;
                },
            ],
            method: "post",
            baseURL: base_url,
            url,
            data: JSON.stringify(data),
            headers,
        })
        .then((response) => {
            dispatch({
                type: FETCH_HISTORY,
                payload: response.data,
            });
        })
        .catch((err) => {
            dispatch(createError(err, GET_ERRORS));
        });
};

// fetch history for all hosts
export const fetchHostsHistory = (
    hostItemsHistory,
    hostIds,
    start_date,
    end_date,
    token,
    base_url
) => (dispatch) => {
    let startDate = parseInt(start_date);
    let endDate = parseInt(end_date);

    let url = `api_jsonrpc.php`;
    let promises = [];

    for (let r = 0; r < hostItemsHistory.length; r++) {
        const currentItem = hostItemsHistory[r];
        const items = currentItem.items;
        const hostIds = currentItem.host.hostid;

        for (let s = 0; s < items.length; s++) {
            let itemIds = items[s].itemid;
            let data = {
                jsonrpc: "2.0",
                method: "history.get",
                params: {
                    itemids: [itemIds],
                    hostids: [hostIds],
                    time_from: startDate,
                    time_till: endDate,
                    history: items[s].value_type
                },
                id: 1,
                auth: token,
            };
            promises.push(
                axios({
                    transformRequest: [
                        (data, headers) => {
                            delete headers.common.Authorization
                            return data;
                        },
                    ],
                    method: "post",
                    baseURL: base_url,
                    url,
                    data: JSON.stringify(data),
                    headers,
                })
                .then((response) => {
                    dispatch({
                        type: FETCH_HOSTS_HISTORY,
                        payload: {
                            history: response.data,
                            index: r
                        },
                    });
                })
                .catch((err) => {
                    dispatch(createError(err, GET_ERRORS));
                })
            );
        }
        // let itemIds = currentItem.items.map(item => {
        //     return item.itemid
        // });
        // let hostIds = currentItem.host.hostid;
        // let data = {
        //     jsonrpc: "2.0",
        //     method: "history.get",
        //     params: {
        //         itemids: itemIds,
        //         hostids: [hostIds],
        //         time_from: startDate,
        //         time_till: endDate,
        //         // history: 
        //     },
        //     id: 1,
        //     auth: token,
        // };
        // promises.push(
        //     axios({
        //         transformRequest: [
        //             (data, headers) => {
        //                 delete headers.common.Authorization
        //                 return data;
        //             },
        //         ],
        //         method: "post",
        //         baseURL: base_url,
        //         url,
        //         data: JSON.stringify(data),
        //         headers,
        //     })
        //     .then((response) => {
        //         dispatch({
        //             type: FETCH_HOSTS_HISTORY,
        //             payload: {
        //                 history: response.data,
        //                 index: r
        //             },
        //         });
        //     })
        //     .catch((err) => {
        //         dispatch(createError(err, GET_ERRORS));
        //     })
        // );
    }

    Promise.all(promises)
        .then(() => {
            // dispatch({
            //     type: FETCH_HOSTS_HISTORY,
            //     payload: {response.data},
            // });
        })
        .catch((err) => {
            dispatch(createError(err, GET_ERRORS));
        })
};

// fetch history for all hosts
export const fetchHostsHistory2 = (
    itemIds,
    hostIds,
    start_date,
    end_date,
    token,
    base_url
) => (dispatch) => {
    let startDate = parseInt(start_date);
    let endDate = parseInt(end_date);

    let url = `api_jsonrpc.php`;

    let data = {
        jsonrpc: "2.0",
        method: "history.get",
        params: {
            itemids: itemIds,
            hostids: hostIds,
            time_from: startDate,
            time_till: endDate,
        },
        id: 1,
        auth: token,
    };
    axios({
            transformRequest: [
                (data, headers) => {
                    delete headers.common.Authorization
                    return data;
                },
            ],
            method: "post",
            baseURL: base_url,
            url,
            data: JSON.stringify(data),
            headers,
        })
        .then((response) => {
            dispatch({
                type: FETCH_HOSTS_HISTORY,
                payload: response.data,
            });
        })
        .catch((err) => {
            dispatch(createError(err, GET_ERRORS));
        });
};

// FETCH APPLICATIONS OF SELECTED HOSTGROUP
export const fetchApplications = (groupIds, token, base_url) => (dispatch) => {
    let url = `api_jsonrpc.php`;

    let data;
    if (groupIds === 0) {
        data = {
            jsonrpc: "2.0",
            method: "application.get",
            params: {},
            id: 1,
            auth: token,
        };
    } else {
        data = {
            jsonrpc: "2.0",
            method: "application.get",
            params: {
                groupids: [groupIds],
            },
            id: 1,
            auth: token,
        };
    }

    axios({
            transformRequest: [
                (data, headers) => {
                    delete headers.common.Authorization
                    return data;
                },
            ],
            method: "post",
            baseURL: base_url,
            url,
            data: JSON.stringify(data),
            headers,
        })
        .then((response) => {
            dispatch({
                type: FETCH_APPLICATIONS,
                payload: response.data,
            });
        })
        .catch((err) => {
            dispatch(createError(err, GET_ERRORS));
        });
};

// FETCH ITEMS CONTAINING APPLICATIOM NAME AND GROUP ID SELECTED
export const fetchItemsOfApplication = (
    groupIds,
    applicationName,
    token,
    base_url
) => (dispatch) => {
    let url = `api_jsonrpc.php`;

    let data;
    if (groupIds === 0) {
        data = {
            jsonrpc: "2.0",
            method: "item.get",
            params: {
                application: applicationName,
            },
            id: 1,
            auth: token,
        };
    } else {
        data = {
            jsonrpc: "2.0",
            method: "item.get",
            params: {
                application: applicationName,
                groupids: [groupIds],
            },
            id: 1,
            auth: token,
        };
    }
    axios({
            transformRequest: [
                (data, headers) => {
                    delete headers.common.Authorization
                    return data;
                },
            ],
            method: "post",
            baseURL: base_url,
            url,
            data: JSON.stringify(data),
            headers,
        })
        .then((response) => {
            dispatch({
                type: FETCH_ITEM_APPLICATION,
                payload: response.data,
            });
        })
        .catch((err) => {
            dispatch(createError(err, GET_ERRORS));
        });
};