import React, { Component } from "react";
import PropTypes from "prop-types";


class Aside extends Component {
  constructor(props) {
    super(props);
    this.state = {
      accesscode: "",
      summaryDate: "",
      phone: "",
      status: "",
      assigned: 0,
      inProgress: 0,
      completed: 0,
      deferred: 0,
      mpesaReceipt: ''
    };
  }
  componentDidMount() {
    this.setState({
      assigned: this.props.assigned,
      inProgress: this.props.inProgress,
      completed: this.props.completed,
      deferred: this.props.deferred,
    });
  }
  componentWillReceiveProps(nextProps) {
    this.setState({
      assigned: nextProps.assigned,
      inProgress: nextProps.inProgress,
      completed: nextProps.completed,
      deferred: nextProps.deferred,
    });
  }

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  closeSearch = () => {
    this.setState({ phone: "", mpesaReceipt: ""});
    this.props.searchHandler("", "");
  };

  onSubmit = (e) => {
    e.preventDefault();
    this.props.searchHandler(
      this.state.phone,
      this.state.mpesaReceipt,
    );
  };
  render() {
    const { assigned, inProgress, deferred, completed } = this.state;
    // console.log(completed);
    return (
      <React.Fragment>
        <div className="card">
          <div className="card-body">
            <h5 className="card-title">Search</h5>
            <form noValidate onSubmit={this.onSubmit}>
              <div className="form-group">
                Agent Phone
                <input
                  type="number"
                  className="form-control"
                  name="phone"
                  onChange={this.onChange}
                  value={this.state.phone}
                />
              </div>
              <div className="form-group">
                Mpesa Receipt Number
                <input
                  type="text"
                  className="form-control"
                  name="mpesaReceipt"
                  onChange={this.onChange}
                  value={this.state.mpesaReceipt}
                />
              </div>
              {/* <div className="form-group">
                Status
                <input
                  type="text"
                  className="form-control"
                  name="accesscode"
                  onChange={this.onChange}
                  value={this.state.accesscode}
                />
              </div>        */}
              <div className="mt-3">
                <button
                  onClick={this.closeSearch}
                  className="btn btn-default btn-sm"
                >
                  <i className="fa fa-times" /> Close Search
                </button>
                <button type="submit" className="btn btn-warning btn-sm">
                  <i className="fa fa-search" />
                  Search
                </button>
              </div>
            </form>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

Aside.propTypes = {
  searchHandler: PropTypes.func.isRequired,
  assigned: PropTypes.number.isRequired,
  inProgress: PropTypes.number.isRequired,
  completed: PropTypes.number.isRequired,
  deferred: PropTypes.number.isRequired,
};

export default Aside;
