import React, { Component } from "react";
import { connect } from "react-redux";
import Moment from "moment";
import PropTypes from "prop-types";

import Navbar from "./Navbar";

import {
  fetchAssignedTasks,
  selectTask,
  deferTask,
  completeTask
} from "../../actions/tasksActions";

import { Error, Preloader } from "../common";
import { Aside, Select, Complete, Defer, View } from "../tasks";

class Tasks extends Component {
  constructor() {
    super();
    this.state = {
      tasks: {},
      errors: "",
      page: 0,
      limit: 10,
      count: 0,
      phone: "",
      status: "",
      date: "",
      errorMessage: "",
      assigned: 0,
      inProgress: 0,
      completed: 0,
      deferred: 0,
      preloaderStyle: "d-none"
    };
  }

  handlePreloaderStyle = newStyle => {
    this.setState({ preloaderStyle: newStyle });
  };

  incrementPage = e => {
    e.preventDefault();
    const { limit, page, count, phone, status, date } = this.state;
    const totalPages = Math.ceil(count / limit);

    console.log(this.state);

    const newPage = page + 1;
    if (newPage <= totalPages) {
      this.setState({ page: newPage });
      this.fetchTasks(newPage, limit, phone, status, date);
    }
  };

  decrementPage = e => {
    e.preventDefault();
    const { page, limit, phone, status, date } = this.state;

    const newPage = page - 1;
    if (newPage >= 0) {
      this.setState({ page: newPage });
      this.fetchTasks(newPage, limit, phone, status, date);
    }
  };

  onChangePage = e => {
    const { limit, phone, status, date } = this.state;
    const newPage = parseInt(e.target.value);
    this.setState({ [e.target.name]: newPage });

    this.fetchTasks(newPage, limit, phone, status, date);
  };

  fetchTasks = (page, limit, phone, status, date) => {
    this.handlePreloaderStyle("d-none");
    this.props.fetchAssignedTasks(page * limit, limit, phone, status, date);
  };

  componentDidMount() {
    if(!this.props.auth.isAuthenticated){
        this.props.history.push("/admin");
    }
    this.fetchTasks(
      this.state.page,
      this.state.limit,
      this.state.phone,
      this.state.status,
      this.state.date
    );
  }

  componentWillReceiveProps(nextProps) {
    this.handlePreloaderStyle("d-none");

    if (nextProps.tasks) {
      const newTasks = nextProps.tasks;
      this.setState({
        tasks: newTasks,
        count: newTasks.totalTasks,
        assigned: newTasks.summary.assigned,
        inProgress: newTasks.summary.inProgress,
        completed: newTasks.summary.completed,
        deferred: newTasks.summary.deferred
      });
    }
    if (nextProps.errors.length > 0) {
      //   console.log(nextProps.errors);
      this.setState({ errorMessage: nextProps.errors });
    }
  }

  getBadgeClasses = status => {
    let classes = "badge badge-";
    classes +=
      status === "Assigned"
        ? "primary"
        : status === "In Progress"
        ? "warning"
        : status === "Completed"
        ? "success"
        : status === "Deferred"
        ? "danger"
        : "default";
    return classes;
  };

  getTaskAction = task => {
    if (task.status === "Assigned") {
      return (
        <Select
          customerName={
            task.customer_first_name + " " + task.customer_last_name
          }
          task_id={task.task_id}
          task_status_id={2}
          onSubmit={this.props.selectTask}
          handlePreloaderStyle={this.handlePreloaderStyle}
          fetchSummary={this.fetchSummary}
        />
      );
    } else if (task.status === "In Progress") {
      return (
        <React.Fragment>
          <Complete
            customerName={
              task.customer_first_name + " " + task.customer_last_name
            }
            task_id={task.task_id}
            task_status_id={3}
            customerPhone={task.customer_phone}
            customerId={task.customer_id}
            onSubmit={this.props.completeTask}
            handlePreloaderStyle={this.handlePreloaderStyle}
          />
          <Defer
            customerName={
              task.customer_first_name + " " + task.customer_last_name
            }
            task_id={task.task_id}
            task_status_id={4}
            customerPhone={task.customer_phone}
            customerId={task.customer_id}
            onSubmit={this.props.deferTask}
            handlePreloaderStyle={this.handlePreloaderStyle}
            fetchSummary={this.fetchSummary}
          />
        </React.Fragment>
      );
    } else if (task.status === "Completed") {
      return (
        <View
          customerName={
            task.customer_first_name + " " + task.customer_last_name
          }
          taskId={task.task_id}
          gender={task.gender}
          location={task.location}
          age={task.age}
          accessCode={task.access_code}
          splashPage={task.splash_page}
          autoplay={task.autoplay}
          mpesa={task.mpesa}
          comments={task.comments}
          personnelFirstName={task.personnel_first_name}
          personnelLastName={task.personnel_other_name}
          date={task.completed}
        />
      );
    } else if (task.status === "Deferred") {
      return (
        <Select
          customerName={
            task.customer_first_name + " " + task.customer_last_name
          }
          task_id={task.task_id}
          task_status_id={2}
          onSubmit={this.props.selectTask}
          handlePreloaderStyle={this.handlePreloaderStyle}
          fetchSummary={this.fetchSummary}
        />
      );
    } else {
      return null;
    }
  };

  tasksHandler = newTasks => {
    this.setState({
      tasks: newTasks,
      count: newTasks.totalTasks
    });
  };

  errorMessageHandler = newError => {
    this.setState({ errorMessage: newError });
  };

  searchHandler = (phone, status, date) => {
    this.setState({
      phone: phone,
      status: status,
      date: date
    });
    this.fetchTasks(this.state.page, this.state.limit, phone, status, date);
  };

  render() {
    const {
      tasks,
      limit,
      page,
      count,
      errorMessage,
      assigned,
      inProgress,
      deferred,
      completed,
      preloaderStyle
    } = this.state;
    let taskCount = page * limit + 1;
    let personnelTasks;

    let pages;
    let totalPages = 0;

    if (count > 0) {
      totalPages = Math.ceil(count / limit);

      const allPages = Array.from(Array(totalPages).keys());
      if (allPages.length > 0) {
        pages = allPages.map(pg => (
          <option key={pg} value={pg}>
            {pg + 1}
          </option>
        ));
      } else {
        pages = null;
      }
    } else {
      pages = null;
    }

    if (tasks.tasks) {
      personnelTasks = tasks.tasks.map(task => (
        <tr key={task.task_id}>
          <td>{taskCount++}</td>
          <td>{task.customer_first_name}</td>
          <td>{task.customer_last_name}</td>
          <td>{task.customer_phone}</td>
          <td>{task.registration}</td>
          <td>
            {task.personnel_first_name} {task.personnel_last_name}
          </td>
          <td>
            <span className={this.getBadgeClasses(task.status)}>
              {task.status}
            </span>
          </td>
          <td>{Moment(task.assigned).format("YYYY-MM-DD")}</td>
          <td>{this.getTaskAction(task)}</td>
        </tr>
      ));
    } else {
      personnelTasks = null;
    }
    return (
      <React.Fragment>
        <Preloader preloaderStyle={preloaderStyle} />
        <Navbar />
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-8">
              <Error message={errorMessage} />
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title">Tasks</h5>
                  <div className="table-responsive">
                    <table className="table table-bordered table-striped tabe-condensed">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>First Name</th>
                          <th>Last Name</th>
                          <th>Phone</th>
                          <th>Registration</th>
                          <th>Assigned to</th>
                          <th>Status</th>
                          <th>Created</th>
                          <th colSpan="2">Actions</th>
                        </tr>
                      </thead>
                      <tbody>{personnelTasks}</tbody>
                    </table>
                  </div>
                </div>

                <div className="card-footer">
                  <div className="d-flex flex-row-reverse">
                    <form noValidate onSubmit={this.incrementPage}>
                      <button className="btn btn-rounded" type="submit">
                        <i className="fas fa-chevron-right" />
                      </button>
                    </form>
                    <form noValidate onSubmit={this.decrementPage}>
                      <button className="btn btn-rounded mx-2" type="submit">
                        <i className="fas fa-chevron-left" />
                      </button>
                    </form>
                    <span className="font-weight-normal">of {totalPages}</span>
                    <select
                      name="page"
                      id="page"
                      className="form-control mr-2"
                      value={page}
                      onChange={this.onChangePage}
                    >
                      {pages}
                    </select> 
                    <span className="font-weight-normal mr-2">Page</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <Aside
                searchHandler={this.searchHandler}
                errorMessageHandler={this.errorMessageHandler}
                assigned={assigned}
                inProgress={inProgress}
                deferred={deferred}
                completed={completed}
              />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

Tasks.propTypes = {
  fetchAssignedTasks: PropTypes.func.isRequired,
  selectTask: PropTypes.func.isRequired,
  completeTask: PropTypes.func.isRequired,
  deferTask: PropTypes.func.isRequired,
  tasks: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  tasks: state.tasks.tasksAssigned,
  errors: state.errors,
  auth: state.auth
});

export default connect(
  mapStateToProps,
  { fetchAssignedTasks, selectTask, completeTask, deferTask }
)(Tasks);
