import {
    FETCH_COMMISSIONS_EXPORT,
    GET_ERRORS
} from './types';

import {
    axios,
    createError
} from "../utilities";

export const fetchCommissionsExport = (sdate, edate, phone, agent_phone) => dispatch => {
    let url = `commissions/export?search=search`;
    if (phone) {
        url += `&phone=${phone}`
    }
    if (agent_phone) {
        url += `&agent_phone=${agent_phone}`
    }
    if (sdate) {
        url += `&sdate=${sdate}`
    }
    if (edate) {
        url += `&edate=${edate}`
    }
    axios
        .get(url)
        .then(commissions => {
            dispatch({
                type: FETCH_COMMISSIONS_EXPORT,
                payload: commissions.data
            })
        })
        .catch(err => {
            dispatch(createError(err, GET_ERRORS));
        })
}