import {
    combineReducers
} from 'redux';
import authReducer from './authReducer';
import agentAuthReducer from './agentAuthReducer';
import errorReducer from './errorReducer';
import tasksReducer from './tasksReducer';
import transactionsReducer from './transactionsReducer';
import agentsReducer from './agentsReducer'
import paymentsReducer from './paymentsReducer'
import personnelReducer from './personnelReducer'
import commissionsReducer from './commissionsReducer'
import announcementsReducer from './announcementsReducer';
import smsReducer from './smsReducer';
import campaignReducer from './campaignReducer';
import customersReducer from './customersReducer';
import zabbixReducer from './zabbixReducer';
import disconnectReducer from './disconnectReducer';

//pass in all the availlable reducers
export default combineReducers({
    agentAuth: agentAuthReducer,
    auth: authReducer,
    errors: errorReducer,
    tasks: tasksReducer,
    transactions: transactionsReducer,
    commissions: agentsReducer,
    // customers: agentsReducer,
    payments: paymentsReducer,
    personnel: personnelReducer,
    campaign: campaignReducer,
    commissionsExport: commissionsReducer,
    announcements: announcementsReducer,
    sms: smsReducer,
    customers: customersReducer,
    zabbix: zabbixReducer,
    disconnect: disconnectReducer
});