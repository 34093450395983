import React, { Component } from "react";
import PropTypes from "prop-types";

import Update from "./Update";
// import Preloader from "../common/Preloader";

class Display extends Component {
  // state = {
  //     preloaderStyle: "d-none",
  // }
  render() {
    const { personnel, errors } = this.props;
    let { personnelCount } = this.props;

    let allPersonnel;

    if (personnel.length > 0) {
      allPersonnel = personnel.map((personnel) => (
        <tr key={personnel.personnel_id}>
          <td>{personnelCount++}</td>
          <td>{personnel.personnel_fname}</td>
          <td>{personnel.personnel_onames}</td>
          <td>{personnel.personnel_phone}</td>
          <td>
            {personnel.personnel_status === 1 ? (
              <span className="badge badge-success">Active</span>
            ) : (
              <span className="badge badge-secondary">Inactive</span>
            )}
          </td>
          <td>
            {personnel.personnel_type_id === 1 ? (
              <span className="badge badge-primary">Admin</span>
            ) : personnel.personnel_type_id === 2 ? (
              <span className="badge badge-warning">Telesales</span>
            ) : personnel.personnel_type_id === 5 ? (
              <span className="badge badge-warning">NOC</span>
            ) : (
              <span className="badge badge-danger">SMS</span>
            )}
          </td>
          <td>
            <Update
              key={personnel.personnel_id}
              personnel_id={personnel.personnel_id}
              personnel_email={personnel.personnel_email}
              personnel_onames={personnel.personnel_onames}
              personnel_fname={personnel.personnel_fname}
              personnel_phone={personnel.personnel_phone}
              personnel_status={personnel.personnel_status}
              personnel_type_id={personnel.personnel_type_id}
              updatePersonnel={this.props.updatePersonnel}
              errors={errors}
              handlePreloaderStyle={this.props.handlePreloaderStyle}
            />
          </td>
        </tr>
      ));
    } else {
      allPersonnel = null;
    }
    return (
      <div className="table-responsive">
        <table className="table table-bordered table-striped tabe-condensed">
          <thead>
            <tr>
              <th>#</th>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Phone</th>
              <th>Status</th>
              <th>Type</th>
              <th colSpan="1">Actions</th>
            </tr>
          </thead>
          <tbody>{allPersonnel}</tbody>
        </table>
      </div>
    );
  }
}

Display.propTypes = {
  updatePersonnel: PropTypes.func.isRequired,
  personnel: PropTypes.array.isRequired,
  personnelCount: PropTypes.number.isRequired,
  handlePreloaderStyle: PropTypes.func.isRequired,
};

export default Display;
